import { faEdit, faPaperclip, faScrewdriver } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React, { useState, useEffect } from "react"
import { useRef } from "react"
import { Button, FormControl, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import request from "../../../request"
import BotonConsultar from "../../compartidos/BotonConsultar"
import HeaderVista from "../../compartidos/HeaderVista"
import Loader from "../../compartidos/Loader"
import Notify from "../../compartidos/Notify"
import Paginacion from "../../compartidos/Paginacion"
import useData from "../../hooks/useData"
import conexiones from "./Panel/conexiones"
import Datos from "./Panel/Datos"
import Inputs from "./Panel/Inputs"
import PropsQuery from "./Panel/PropsQuery"

export default function Querys (props) {
    const [data, setData] = useState([])
    const [dataPage, setDataPage] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(1)
    const [edit, setEdit] = useState('')
    const [item, setItem] = useData({
        id: '',
        link: '',
        titulo: '',
        conexion: '',
        tipo: 'NORM',
        fields: '',
        tablas: '',
        where: '',
        orderby: '',
        groupby: '',
        limit: '',
        free: ''
    })
    const [params, setParams] = useState({})

    const titulo = useRef(null)

    const rows = 50

    const getData = () => {
        const t = titulo.current ? titulo.current.value : ''

        setLoading(true)
        request.Get(props.ipserv + 'getquerys/', { titulo: t }, r => {
            setLoading(false)

            if (!r.success) {
                Notify({ type: 'error' })
                return
            }

            const d = r.data.reverse()

            d.forEach(item => {
                const i = conexiones.findIndex(c => c.value === item.AutQCon)
                item.conexion = i >= 0 ? conexiones[i].label : item.AutQCon
            })

            setData(d)
            setDataPage(d.slice(0, rows))
        })
    }

    const showModal = (item, component) => {
        if (component === 'props') {
            const json = JSON.parse(item.AutQParams || '{}')

            setParams({
                fch: json.fch === undefined || json.fch,
                soloFchDsd: json.soloFchDsd ? true : false,
                sucCheck: json.sucs ? true : false,
                sucs: json.sucs && json.sucs.length > 0 ? json.sucs : false,
                excel: json.excel === undefined || json.excel,
                columnas: json.columnas || [],
                col: json.columnas && json.columnas.length > 0,
                filtrosCheck: json.filtros && json.filtros.length > 0,
                filtros: json.filtros && json.filtros.length > 0 ? json.filtros : [],
                info: json.info && json.info.length > 0 ? json.info : [],
                infoCheck: json.info && json.info.length > 0
            })
        }

        setItem({
            id: item.AutQId,
            link: item.AutQComp,
            titulo: item.AutQTitle,
            conexion: item.AutQCon,
            tipo: item.AutQTip,
            fields: item.AutQIFields || '',
            tablas: item.AutQITables || '',
            where: item.AutQIWhere || '',
            orderby: item.AutQIOrderBy || '',
            groupby: item.AutQIGroupBy || '',
            limit: item.AutQILimit || '',
            free: item.AutQIFreeTipyng || '',
        })
        setShow(true)
        setEdit(component)
    }

    const guardarParams = params => {
        const p = JSON.stringify(params)

        setLoadingEdit(true)

        request.Post(props.ipserv + 'editarparamsquery/', { params: p, id: item.id }, r => {
            setShow(false)
            setLoadingEdit(false)

            if (r.success) {
                const i = data.findIndex(d => d.AutQId === item.id)
                const j = dataPage.findIndex(d => d.AutQId === item.id)

                const obj = {
                    ...data[i],
                    AutQParams: params
                }

                data.splice(i, 1, obj)
                dataPage.splice(j, 1, obj)

                Notify({ type: 'exito' })
            } else {
                Notify({ type: 'fail' })
            }
        })
    }

    const guardar = () => {
        if (item.tipo === 'NORM' && (!item.fields || !item.tablas) || item.tipo === 'DROP' && !item.free) {
            Notify({ type: 'requeridos' })
            return
        }
        
        if (item.tipo === 'NORM') {
            item.free = ''
        } else {
            item.fields = ''
            item.tablas = ''
            item.where = ''
            item.orderby = ''
            item.groupby = ''
            item.limit = ''
        }

        if (!item.link || !item.conexion || !item.titulo) {
            Notify({ type: 'completar' })
            return
        }

        setLoadingEdit(true)

        request.Post(props.ipserv + 'editarquery/', item, r => {
            setShow(false)
            setLoadingEdit(false)

            if (r.success) {
                const i = data.findIndex(d => d.AutQId === item.id)
                const j = dataPage.findIndex(d => d.AutQId === item.id)

                const obj = {
                    ...data[i],
                    AutQId: item.id,
                    AutQComp: item.link,
                    AutQCon: item.conexion,
                    AutQTitle: item.titulo,
                    AutQTip: item.tipo,
                    AutQIFields: item.fields,
                    AutQITables: item.tablas,
                    AutQIGroupBy: item.groupby,
                    AutQIOrderBy: item.orderby,
                    AutQIWhere: item.where,
                    AutQIFreeTipyng: item.free
                }

                data.splice(i, 1, obj)
                dataPage.splice(j, 1, obj)

                Notify({ type: 'exito' })
            } else {
                Notify({ type: 'fail' })
            }
        })
    }

    const onChangePage = (list, p) => {
        setDataPage(list)
        setPage(p)
    }

    useEffect(() => {
        if (props.ipserv.length > 0) getData()
    }, [props.ipserv])

    return <div className="bg-white my-4 border shadow rounded mx-4 px-3">
        <HeaderVista titulo={'Querys'} />
        
        <div className="d-flex half-width">
            <FormControl
                type="text"
                placeholder="Titulo"
                className="small-width"
                input="true"
                ref={titulo}
            />

            <BotonConsultar
                loading={loading}
                disabled={loading}
                search={getData}
            />
        </div>
        
        <Loader loading={loading} >
            <div className='full-width d-flex justify-content-center my-2'>
                <Paginacion
                    page={page}
                    rows={rows}
                    data={data}
                    onChangePage={onChangePage}
                />
            </div>

            <Table
                responsive
                striped
                bordered
                hover
            >
                <thead>
                    <tr>
                        <th>AutQId</th>
                        <th>Titulo</th>
                        <th>Link</th>
                        <th>Conexion</th>
                        <th>Tipo</th>
                        <th>Fecha</th>
                        <th>Usuario</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataPage.map(item => <tr key={item.AutQId}>
                        <td>{item.AutQId}</td>
                        <td>{item.AutQTitle}</td>
                        <td>{item.AutQComp}</td>
                        <td>{item.conexion}</td>
                        <td>{item.AutQTip}</td>
                        <td>{moment(item.AutQFecha).format('DD/MM/YYYY')}</td>
                        <td>{item.Usuario}</td>
                        <td style={{ fontSize: 20 }} className="d-flex justify-content-center">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        Editar Query
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon size="1x" className="mx-2 cursor-pointer" icon={faEdit} color="green" onClick={e => showModal(item, 'query')} />
                            </OverlayTrigger>   
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        Editar Titulo/Link/Conexion
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon size="1x" className="mx-2 cursor-pointer" icon={faPaperclip} color="blue" onClick={e => showModal(item, 'datos')} />
                            </OverlayTrigger>   
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        Editar Parametros
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon size="1x" className="mx-2 cursor-pointer" icon={faScrewdriver} color="black" onClick={e => showModal(item, 'props')} />
                            </OverlayTrigger>   
                        </td>
                    </tr>)}
                </tbody>
            </Table>
            
            <div className='full-width d-flex justify-content-center my-2'>
                <Paginacion
                    page={page}
                    rows={rows}
                    data={data}
                    onChangePage={onChangePage}
                />
            </div>
        </Loader>

        <Modal size="lg" show={show} onHide={() => setShow(false)} centered>
            <Loader loading={loadingEdit}>
                <div className="px-4 pb-4">
                    {edit === 'query' && <>
                        <Inputs data={item} setData={setItem} />

                        <Button variant="success" onClick={guardar}>
                            Guardar
                        </Button>
                    </>}

                    {edit === 'datos' && <>
                        <Datos ipserv={props.ipserv} data={item} setData={setItem} />

                        <Button variant="success" onClick={guardar}>
                            Guardar
                        </Button>
                    </>}

                    {edit === 'props' && <PropsQuery params={params} guardar={guardarParams} />}
                </div>
            </Loader>
        </Modal>
    </div>
}