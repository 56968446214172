import React, { useEffect, useState } from 'react'
import { Image, Dropdown, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import OffCanvas from 'react-aria-offcanvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faWindowClose, faFolderOpen, faRedoAlt, faStar, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
//import Logo from '../../resources/horizontal-blanco.png';

import '../../compartidos/clases_utiles.css'
import ItemMenu from './ItemMenu'
import request from '../../../request'
import Notify from '../../compartidos/Notify'
import Loader from '../../compartidos/Loader'
import SelectIconos from '../accesos/SelectIconos'

export default function NuevoMenu(props) {
    // keys de menus traidos desde base de datos
    const keysMenus = ['menuneg', 'menuemp', 'menugeneral', 'submenu', 'menuitem']

    const [menuneg, setMenuNeg] = useState([])
    const [menuemp, setMenuEmp] = useState([])
    const [menugeneral, setMenuGeneral] = useState([])
    const [submenu, setSubMenu] = useState([])
    const [menuitem, setMenuItem] = useState([])
    const [dataDir, setDataDir] = useState([])
    const [menuLabelCompuesto, setMenuLabelCompuesto] = useState({})
    const [isOpen, setIsOpen] = useState({})
    const [forceRenderLabel, setForceRenderLabel] = useState(true)
    const [dataUsr, setDataUsr] = useState(null)
    const [actualizando, setActualizando] = useState(false)
    const [renderMenu, setRenderMenu] = useState(1)

    const [show, setShow] = useState(false)
    const [IMenuId, setIMenuId] = useState('')

    const onClose = props.onClose
    const closesession = props.closesession

    useEffect(() => {
        const { menuneg, menuemp, menugeneral, submenu, menuitem } = getMenus()
        let dataDirAux = []

        dataDirAux = menugeneral.filter(item => item.PrfTipMen && item.PrfTipMen.includes("DIR"))

        setMenuNeg(menuneg)
        setMenuEmp(menuemp)
        setMenuGeneral(menugeneral)
        setSubMenu(submenu)
        setMenuItem(menuitem)
        setDataDir(dataDirAux)

        let menuAux = {}
        let isOpenAux = {}

        generarArrayMenu().forEach(m => {
            const key = getKey(m.label)

            menuAux[key] = m.label
            isOpenAux[key] = false
        })

        const data = localStorage.usuario
            ? JSON.parse(localStorage.usuario)[0]
            : null

        setDataUsr(data)
        setMenuLabelCompuesto(menuAux)
        setIsOpen(isOpenAux)

        setActualizando(false)
    }, [props, renderMenu])

    const logNavegacion = (link, label) => {
        request.Post(props.ipserv + 'lognav/', { link, label }, () => { })
    }

    const getMenus = () => {
        let objeto = {}

        keysMenus.forEach(key => {
            objeto[key] = localStorage.getItem(key)
                ? JSON.parse(localStorage.getItem(key))
                : []
        })

        return objeto
    }

    /* Genera un array de objetos donde cada item sigue la siguiente forma
    return [{ arraay
        label:          string,
        labelCompuesto: string,      Si el menu guarda un solo menu entonces se agrega al label en vez de agregarse como menu nuevo
        menus:          array,
        items:          array
        [{
            label:      string,
            link:       string
        }],
        ...
    }]
    */
    const generarArrayMenu = () => {
        let menu = []

        menuneg.forEach((mNeg, indexNeg) => {
            const menuEmp = menuemp.filter(mEmp => mNeg.MenuNeg === mEmp.MenuNeg)

            if (menuEmp.length) {
                if (menuEmp.length === 1) {
                    menu.push({
                        label: mNeg.DmnNom,
                        labelCompuesto: mNeg.DmnNom + '/' + menuEmp[0].DmnNom,
                        menus: [],
                        items: []
                    })

                    const menuGeneral = menugeneral.filter(mGral => menuEmp[0].DmnAbr === mGral.DmnAbr)

                    if (menuGeneral.length === 1) {
                        menu[indexNeg].labelCompuesto = menu[indexNeg].labelCompuesto + '/' + menuGeneral[0].MenuNom

                        const subMenu = submenu.filter(sm => menuGeneral[0].MenuId === sm.MenuId)
                        const menuItem = menuitem.filter(mi => menuitem[0].MenuId === mi.MenuId && !mi.SubMenuNom)

                        menu[indexNeg].items = menuItem.map(mi => {
                            return {
                                link: mi.IMenuLinkTo,
                                label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                IMenuId: mi.IMenuId
                            }
                        })

                        if (subMenu.length === 1 && menuItem.length === 0) {
                            const subMenuItem = menuitem.filter(mi => mi.MenuId == subMenu[0].MenuId &&
                                mi.SubMenuNom == subMenu[0].SubMenuNom &&
                                mi.MenuNom == subMenu[0].MenuNom)

                            if (menuItem.length === 0) {
                                menu[indexNeg].labelCompuesto = menu[indexNeg].labelCompuesto + '/' + subMenu[0].SubMenuNom
                                menu[indexNeg].items = subMenuItem.map(sMI => {
                                    return {
                                        link: sMI.IMenuLinkTo,
                                        label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                        IMenuId: sMI.IMenuId
                                    }
                                })
                            } else {
                                menu[indexNeg].menus.push({
                                    label: subMenu[0].SubMenuNom,
                                    labelCompuesto: subMenu[0].SubMenuNom,
                                    menus: [],
                                    items: subMenuItem.map(sMI => {
                                        return {
                                            link: sMI.IMenuLinkTo,
                                            label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                            IMenuId: sMI.IMenuId
                                        }
                                    })
                                })
                            }
                        } else {
                            subMenu.forEach(sm => {
                                const menuItem2 = menuitem.filter(mi => mi.MenuId == sm.MenuId &&
                                    mi.SubMenuNom == sm.SubMenuNom &&
                                    mi.MenuNom == sm.MenuNom)

                                menu[indexNeg].menus.push({
                                    label: sm.SubMenuNom,
                                    labelCompuesto: '',
                                    menus: [],
                                    items: menuItem2.map(mi => {
                                        return {
                                            link: mi.IMenuLinkTo,
                                            label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                            IMenuId: mi.IMenuId
                                        }
                                    })
                                })
                            })
                        }
                    } else {
                        menuGeneral.forEach((mG, indexMG) => {
                            menu[indexNeg].menus.push({
                                label: mG.MenuNom,
                                labelCompuesto: mG.MenuNom,
                                menus: [],
                                items: []
                            })

                            const subMenu = submenu.filter(sm => mG.MenuId === sm.MenuId)
                            const menuItem = menuitem.filter(mi => mG.MenuId === mi.MenuId && !mi.SubMenuNom)

                            menu[indexNeg].menus[indexMG].items = menuItem.map(mi => {
                                return {
                                    link: mi.IMenuLinkTo,
                                    label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                    IMenuId: mi.IMenuId
                                }
                            })

                            if (subMenu.length === 1) {
                                const subMenuItem = menuitem.filter(mi => mi.MenuId == subMenu[0].MenuId &&
                                    mi.SubMenuNom == subMenu[0].SubMenuNom &&
                                    mi.MenuNom == subMenu[0].MenuNom)

                                if (menuItem.length === 0) {
                                    menu[indexNeg].menus[indexMG].labelCompuesto = menu[indexNeg].menus[indexMG].labelCompuesto + '/' + subMenu[0].SubMenuNom
                                    menu[indexNeg].menus[indexMG].items = subMenuItem.map(sMI => {
                                        return {
                                            link: sMI.IMenuLinkTo,
                                            label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                            IMenuId: sMI.IMenuId
                                        }
                                    })
                                } else {
                                    menu[indexNeg].menus[indexMG].menus.push({
                                        label: subMenu[0].SubMenuNom,
                                        labelCompuesto: subMenu[0].SubMenuNom,
                                        menus: [],
                                        items: subMenuItem.map(sMI => {
                                            return {
                                                link: sMI.IMenuLinkTo,
                                                label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                                IMenuId: sMI.IMenuId
                                            }
                                        })
                                    })
                                }
                            } else {
                                subMenu.forEach(sm => {
                                    const menuItem2 = menuitem.filter(mi => mi.MenuId == sm.MenuId &&
                                        mi.SubMenuNom == sm.SubMenuNom &&
                                        mi.MenuNom == sm.MenuNom)

                                    menu[indexNeg].menus[indexMG].menus.push({
                                        label: sm.SubMenuNom,
                                        labelCompuesto: sm.SubMenuNom,
                                        menus: [],
                                        items: menuItem2.map(mi => {
                                            return {
                                                link: mi.IMenuLinkTo,
                                                label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                                IMenuId: mi.IMenuId
                                            }
                                        })
                                    })
                                })
                            }
                        })
                    }
                } else {
                    menu.push({
                        label: mNeg.DmnNom,
                        labelCompuesto: mNeg.DmnNom,
                        menus: [],
                        items: []
                    })

                    menuEmp.forEach((me, indexME) => {
                        menu[indexNeg].menus.push({
                            label: me.DmnNom,
                            labelCompuesto: me.DmnNom,
                            menus: [],
                            items: []
                        })

                        const menuGeneral = menugeneral.filter(mGral => me.DmnAbr === mGral.DmnAbr)

                        if (menuGeneral.length === 1) {
                            menu[indexNeg].menus[indexME].labelCompuesto = menu[indexNeg].menus[indexME].labelCompuesto + '/' + menuGeneral[0].MenuNom

                            const subMenu = submenu.filter(sm => menuGeneral[0].MenuId === sm.MenuId)
                            const menuItem = menuitem.filter(mi => menuitem[0].MenuId === mi.MenuId && !mi.SubMenuNom)

                            menu[indexNeg].menus[indexME].items = menuItem.map(mi => {
                                return {
                                    link: mi.IMenuLinkTo,
                                    label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                    IMenuId: mi.IMenuId
                                }
                            })

                            if (subMenu.length === 1) {
                                const subMenuItem = menuitem.filter(mi => mi.MenuId == subMenu[0].MenuId &&
                                    mi.SubMenuNom == subMenu[0].SubMenuNom &&
                                    mi.MenuNom == subMenu[0].MenuNom)

                                if (menuItem.length === 0) {
                                    menu[indexNeg].menus[indexME].labelCompuesto = menu[indexNeg].menus[indexME].labelCompuesto + '/' + subMenu[0].SubMenuNom
                                    menu[indexNeg].menus[indexME].items = subMenuItem.map(sMI => {
                                        return {
                                            link: sMI.IMenuLinkTo,
                                            label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                            IMenuId: sMI.IMenuId
                                        }
                                    })
                                } else {
                                    menu[indexNeg].menus[indexME].menus.push({
                                        label: subMenu[0].SubMenuNom,
                                        labelCompuesto: subMenu[0].SubMenuNom,
                                        menus: [],
                                        items: subMenuItem.map(sMI => {
                                            return {
                                                link: sMI.IMenuLinkTo,
                                                label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                                IMenuId: sMI.IMenuId
                                            }
                                        })
                                    })
                                }
                            } else {
                                subMenu.forEach(sm => {
                                    const menuItem2 = menuitem.filter(mi => mi.MenuId == sm.MenuId &&
                                        mi.SubMenuNom == sm.SubMenuNom &&
                                        mi.MenuNom == sm.MenuNom)

                                    menu[indexNeg].menus[indexME].menus.push({
                                        label: sm.SubMenuNom,
                                        labelCompuesto: sm.SubMenuNom,
                                        menus: [],
                                        items: menuItem2.map(mi => {
                                            return {
                                                link: mi.IMenuLinkTo,
                                                label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                                IMenuId: mi.IMenuId
                                            }
                                        })
                                    })
                                })
                            }
                        } else {
                            menuGeneral.forEach((mG, indexMG) => {
                                menu[indexNeg].menus[indexME].menus.push({
                                    label: mG.MenuNom,
                                    labelCompuesto: mG.MenuNom,
                                    menus: [],
                                    items: []
                                })

                                const subMenu = submenu.filter(sm => mG.MenuId === sm.MenuId)
                                const menuItem = menuitem.filter(mi => mG.MenuId === mi.MenuId)

                                menu[indexNeg].menus[indexME].menus[indexMG].items = menuItem.map(mi => {
                                    return {
                                        link: mi.IMenuLinkTo,
                                        label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                        IMenuId: mi.IMenuId
                                    }
                                })

                                if (subMenu.length === 1) {
                                    const subMenuItem = menuitem.filter(mi => mi.MenuId == subMenu[0].MenuId &&
                                        mi.SubMenuNom == subMenu[0].SubMenuNom &&
                                        mi.MenuNom == subMenu[0].MenuNom)

                                    if (menuItem.length === 0) {
                                        menu[indexNeg].menus[indexME].menus[indexMG].labelCompuesto = menu[indexNeg].menus[indexME].menus[indexMG].labelCompuesto + '/' + subMenu[0].SubMenuNom
                                        menu[indexNeg].menus[indexME].menus[indexMG].items = subMenuItem.map(sMI => {
                                            return {
                                                link: sMI.IMenuLinkTo,
                                                label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                                IMenuId: sMI.IMenuId
                                            }
                                        })
                                    } else {
                                        menu[indexNeg].menus[indexME].menus[indexMG].menus.push({
                                            label: subMenu[0].SubMenuNom,
                                            labelCompuesto: subMenu[0].SubMenuNom,
                                            menus: [],
                                            items: subMenuItem.map(sMI => {
                                                return {
                                                    link: sMI.IMenuLinkTo,
                                                    label: sMI.IMenuId + ' - ' + sMI.IMenuNom,
                                                    IMenuId: sMI.IMenuId
                                                }
                                            })
                                        })
                                    }
                                } else {
                                    subMenu.forEach(sm => {
                                        const menuItem2 = menuitem.filter(mi => mi.MenuId == sm.MenuId &&
                                            mi.SubMenuNom == sm.SubMenuNom &&
                                            mi.MenuNom == sm.MenuNom)

                                        menu[indexNeg].menus[indexME].menus[indexMG].menus.push({
                                            label: sm.SubMenuNom,
                                            labelCompuesto: sm.SubMenuNom,
                                            menus: [],
                                            items: menuItem2.map(mi => {
                                                return {
                                                    link: mi.IMenuLinkTo,
                                                    label: mi.IMenuId + ' - ' + mi.IMenuNom,
                                                    IMenuId: mi.IMenuId
                                                }
                                            })
                                        })
                                    })
                                }
                            })
                        }
                    })
                }


            }
        })

        return menu
    }

    const showModal = item => {
        setIMenuId(item.IMenuId)
        setShow(true)
    }

    /*
        Setea los label del menu principal cuando este es abierto o cerrado, tambien setea forceRnderLabel para forzar el renderizado
        e:          boolean     Indica si el menu fue abierto o cerrado
        f:          boolean     Se envia este parametro para saber si se esta tocando el menu principapl
        m:          object      Objecto con datos del menu
    */
    const onToggle = (e, f, m) => {
        if (f) {
            const key = getKey(m.label)

            if (menuLabelCompuesto[key]) {
                let menu = menuLabelCompuesto
                let io = isOpen

                menu[key] = m.label
                io[key] = e

                setMenuLabelCompuesto(menu)
                setIsOpen(io)

                setImmediate(() => {
                    const a = !forceRenderLabel
                    setForceRenderLabel(a)
                })
            }
        }
    }

    // Retorna la key del label compuesto del mnenu
    // k: string        Label del menu
    const getKey = k => {
        return k.replace(/\s+/g, '')
    }

    const dropdownToggle = (first, label) => {
        return <Dropdown.Toggle

            className={
                'full-width mb-1 pt-1 pb-1 full-height text-left ' +
                (first ? 'dropdown-principal text-caption pl-4' : 'dropdown-submenu') +
                (isOpen[getKey(label)] ? ' bg-purple-gradient-reversee dropdown-click text-white' : '')
            }
        >
            {first &&
                <div className="mx-1 d-flex align-items-center">

                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    </svg>

                </div>

            }

            {menuLabelCompuesto[getKey(label)] || label} <FontAwesomeIcon className='iconFlecha' icon={faAngleDoubleRight} size="md"
                style={{ right: 5 }}
            />
        </Dropdown.Toggle>
    }

    /*
        Genera recursivamente el menu completo
        menus:      array       Array de objetos que representan los menus e items a mostrar, se lo consigue a traves de la funcion generarArrayMenu()
        first:      boolean     Se envia este parametro para saber si se esta mostrando el menu principapl
    */
    const generarMenu = (menus, first) => {
        return menus.map((m, i) =>
            <Dropdown
                drop={first ? 'right' : (m.items.length > 0 ? 'up' : 'down')}
                className="dropdown-home mt-1 mb-1 pr-3"
                onToggle={e => onToggle(e, first, m)}
                style={first ? { height: 38 } : {}}
                key={m.label + i}
            >
                {forceRenderLabel
                    // De esta forma se fuerza el renderizado cada vez que se hace click y asi cambia el nombre del menu
                    ? dropdownToggle(first, m.label)
                    : dropdownToggle(first, m.label)
                }

                <Dropdown.Menu style={m.items.length > 0 ? { maxHeight: 260, overflowY: 'auto' } : {}}>
                    { // Se vuelve a llamar a la misma funcion pero con los submenus
                        generarMenu(m.menus, false)
                    }

                    {m.items.map((item, i) => {
                        const link = item.link.substr(0, 4) === 'http'

                        // Items del menu
                        return <div className='d-flex'>
                            <Dropdown.Item
                                as="div"
                                key={item.label + i}
                                onSelect={onClose}
                            >
                                {link
                                    ? <a
                                        href={item.link}
                                        onClick={() => logNavegacion(item.link, item.label)}
                                        target="_blank"
                                        className="dropdown-item"
                                    >
                                        <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                                        {item.label}
                                    </a>
                                    : <Link
                                        to={item.link}
                                        onClick={() => logNavegacion(item.link, item.label)}
                                        className="dropdown-item"
                                    >
                                        <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                                        {item.label}
                                    </Link>
                                }
                            </Dropdown.Item>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip>
                                        Añadir a Accesos Directos
                                    </Tooltip>
                                }
                            >
                                <div className='mx-2 cursor-pointer d-flex align-items-center' onClick={e => showModal(item)}>
                                    <FontAwesomeIcon
                                        icon={faStar}
                                        color="rgb(26, 115, 232)"
                                    />
                                </div>
                            </OverlayTrigger>
                        </div>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    // Retorna nombre y apellido del usuario logueado separados por coma
    const getNomApelUsr = data => {
        return `${data.UsrNom[0].toUpperCase() + data.UsrNom.slice(1).toLowerCase()}, ${data.UsrApel[0].toUpperCase() + data.UsrApel.slice(1).toLowerCase()}`
    }

    // Evento para cerrar el menu al dar click fuera de el
    document.addEventListener('click', e => {
        const menu = document.getElementById('content-menu')
        const root = document.getElementById('root')

        if (!menu.contains(e.target) && !root.contains(e.target)) {
            onClose()
        }
    })

    const actualizarMenu = () => {
        setActualizando(true)

        const user = JSON.parse(localStorage.getItem('usuario'))
        request.Post(props.ipserv + "auth/", { user }, r => {
            if (r.success) {
                localStorage.setItem('menuneg', JSON.stringify(r.menuneg));
                localStorage.setItem('menuemp', JSON.stringify(r.menuemp));
                localStorage.setItem('menugeneral', JSON.stringify(r.datamenu));
                localStorage.setItem('menuitem', JSON.stringify(r.itemmenu));
                localStorage.setItem('submenu', JSON.stringify(r.submenu));

                setRenderMenu(r => r + 1)
            } else {
                Notify({ type: 'error' })
            }
        })
    }

    const guardarAcceso = icon => {
        const id = IMenuId

        setShow(false)

        request.Post(props.ipserv + 'guardaracceso/', { id, icon }, r => {
            if (r.success) {
                Notify({ type: 'exito' })
            } else {
                r.Error
                    ? Notify(r.Error)
                    : Notify({ type: 'fail' })
            }
        })
    }

    return <>
        <OffCanvas
            width="320px"
            isOpen={props.show}
            onClose={onClose}
            mainContainerSelector="#root"
            className="window-height window-width"
            style={{
                content: {
                    overflowY: 'hidden',
                },
            }}
        >
            <div
                id="content-menu"
                className="small-width triped bordered hovere window-height relative-position"
            >
                <Link className="full-width d-flex justify-content-center pt-4 mb-3" to="/" onClick={onClose}>
                    <Image width="200px" src={"https://i.imgur.com/5mv1kAN.png"} fluid />
                </Link>
                {props.ipserv.length !== 0
                    ? <div>
                        {localStorage.getItem('token')
                            ? <>
                                <div className="text-white mt-4 mb-1 fw-medium pl-3">
                                    Usuario
                                </div>
                                {dataUsr &&
                                    <div className="full-width pl-3 text-white">
                                        <div className="fw-bold d-flex align-items-center">
                                            <div className='circulo-mini-positive mr-2' />
                                            {getNomApelUsr(dataUsr)} - {dataUsr.UsrNick}
                                        </div>
                                        <div className="text-caption">
                                            Conexion: {dataUsr.ConexionLabel}
                                        </div>
                                    </div>
                                }
                                {/*<ItemMenu
                                    onClose={onClose}
                                    label='PERFIL'
                                    to='/Perfil'
                                />*/}

                                <div className="text-white mt-3 mb-2 fw-medium pl-3 full-width d-flex">
                                    Unidades de Negocio
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                Actualizar Menús
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faRedoAlt}
                                            style={{ fontSize: '1.25rem' }}
                                            className='ml-auto mr-4 opacity-8 text-white cursor-pointer'
                                            onClick={actualizarMenu}
                                        />
                                    </OverlayTrigger>
                                </div>
                                <ItemMenu
                                    onClose={onClose}
                                    logNav={logNavegacion}
                                    label='HOME'
                                    to='/'
                                />

                                {actualizando
                                    ? <div className='full-width d-flex flex-column text-center align-items-center mt-4'>
                                        <Spinner animation="border" variant="light" size="xl" />
                                        <span className='text-caption mt-4 text-white'>Espere unos instante mientras se actualiza la lista de menús</span>
                                    </div>
                                    : generarMenu(generarArrayMenu(), true)
                                }

                                <div
                                    className="small-width fixed-bottom d-flex align-items-center py-2 cursor-pointer text-white ml-4"
                                    onClick={closesession}
                                >
                                    <FontAwesomeIcon
                                        className="mx-2"
                                        icon={faWindowClose}
                                    />
                                    Cerrar Sesión
                                </div>
                            </>
                            : <Link
                                to="/Login"
                                className="d-flex align-items-center mt-4 py-2 cursor-pointer text-grey"
                                onClick={onClose}
                            >
                                <FontAwesomeIcon
                                    className="mx-2"
                                    icon={faUser}
                                />
                                Ingresar
                            </Link>
                        }
                    </div>
                    : <div className="full-width full-height text-center mt-5 text-caption fs-13">
                        Aguarde mientras buscamos un Servidor Activo...
                        <div className="mx-auto mt-3" style={{ width: "2rem" }}>
                            <Spinner animation="border" />
                        </div>
                    </div>
                }
            </div>
        </OffCanvas>

        <SelectIconos
            show={show}
            setShow={setShow}
            selectIcon={guardarAcceso}
            ipserv={props.ipserv}
        />
    </>
}