import React from 'react'
import HeaderVista from "../../compartidos/HeaderVista";

export default function RepTabR01() {
    return (
        <>
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'CONCILIACION BANCARIA'} />

                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="Conciliacion Bancaria"
                            width="1200"
                            height="760"
                            src="https://app.powerbi.com/view?r=eyJrIjoiNzZlMjk4OGMtZjBiMS00ZDA3LTk5YjYtZjM0ODQ5ZWIyZDJiIiwidCI6ImE5YmM2NDViLThlNjMtNDZmNy1iM2YyLWM5ODE5OWJiMjRjNSJ9"
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
