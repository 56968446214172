import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";

//MenuPrincipal
import MenuPrincipal from "./components/webcomponents/principalmenu/PrincipalMenu";
//import PrimaryView from "./components/webcomponents/primaryview/primaryview";
import PanelMenusSis from "./components/compartidos/PanelMenusSis";

import Querys from "./components/sistema/Core/Querys";
// import AccesosDirectos from "./components/webcomponents/accesos/AccesosDirectos";
import PanelQuery from "./components/sistema/Core/PanelQuery";


//Reportes
import Reportes from "./components/reportes/Reportes";

//Login
import Login from "./components/user/login/Login";

//Estructural Functions
import EchoSrv from "./components/user/echosrv/EchoSrv";

//Panel
import Panel from "./components/sistema/Panel/Panel";

//Request
import request from "./request";

import Notify from "./components/compartidos/Notify";
import DinamicRoute from "./components/sistema/Core/DinamicRoute";

//pomi
import Pomi from "./components/compartidos/pomi/Pomi"
import PomiDist from "./components/compartidos/pomi/PomiDist"
import PomiAdmin from "./components/compartidos/pomi/PomiAdmin"

//Comercial
//Centro de Informacion - Etapa 1
import CIU1 from "./components/reports/Comercial/RepCIU1";
//Centro de Informacion - Etapa 2 - N1
import CIU2_SSA from "./components/reports/Comercial/RepCIU2_SSA";
import CIU2_GEO from "./components/reports/Comercial/RepCIU2_GEO";
import CIU2_ELS from "./components/reports/Comercial/RepCIU2_ELS";
import CIU2_HIP from "./components/reports/Comercial/RepCIU2_HIP";
import CIU2_SSAHIP from "./components/reports/Comercial/RepCIU2_SSAHIP";
//Centro de Informacion - Etapa 2 - N2
import CIU2_SSA_SALTA from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_SALTA";
import CIU2_SSA_SALTA_EAA from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_SALTA_EAA";
import CIU2_SSA_SALTA_OEA from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_SALTA_OEA";
import CIU2_SSA_SALTA_EAT from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_SALTA_EAT";
import CIU2_SSA_SALTA_AZ from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_SALTA_AZ";
import CIU2_SSA_SALTA_AZ_JH from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_SALTA_AZ_JH";
import CIU2_SSA_SALTA_ATH from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_SALTA_ATH";
import CIU2_SSA_JUJUY from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_JUJUY";
import CIU2_SSA_JUJUY_YEA from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_JUJUY_YEA";
import CIU2_SSA_JUJUY_Y from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_JUJUY_Y";
import CIU2_SSA_JUJUY_Y_COR from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_JUJUY_Y_COR";
import CIU2_SSA_JUJUY_CDY from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_JUJUY_CDY";
import CIU2_SSA_JUJUY_EAP from "./components/reports/Comercial/Nivel2/RepCIU2_SSA_JUJUY_EAP";

import CIU2_GEO_SMT from "./components/reports/Comercial/Nivel2/RepCIU2_GEO_SMT";
import CIU2_GEO_Y from "./components/reports/Comercial/Nivel2/RepCIU2_GEO_Y";
import CIU2_GEO_CAT from "./components/reports/Comercial/Nivel2/RepCIU2_GEO_CAT";

import CIU2_ELS_COR from "./components/reports/Comercial/Nivel2/RepCIU2_ELS_COR";
import CIU2_ELS_EMP from "./components/reports/Comercial/Nivel2/RepCIU2_ELS_EMP";

import CIU2_HIP_EAK from "./components/reports/Comercial/Nivel2/RepCIU2_HIP_EAK";
import CIU2_HIP_EAK_MAY from "./components/reports/Comercial/Nivel2/RepCIU2_HIP_EAK_MAY";
import CIU2_HIP_K from "./components/reports/Comercial/Nivel2/RepCIU2_HIP_K";
//Centro de Informacion - Tableros de Control
import CIU_CTRL_SSA from "./components/reports/Comercial/Controller/RepCIU_CTRL_SSA";
import CIU_CTRL_GEO from "./components/reports/Comercial/Controller/RepCIU_CTRL_GEO";
import CIU_CTRL_ELS from "./components/reports/Comercial/Controller/RepCIU_CTRL_ELS";
import CIU_CTRL_HIP from "./components/reports/Comercial/Controller/RepCIU_CTRL_HIP";
import CIU_CTRL from "./components/reports/Comercial/Controller/RepCIU_CTRL";

// Centro de Informacion - Marcas
import CIU_MARCA_ELSOL from "./components/reports/Comercial/Marcas/RepCIU_MarcaElSolMateriales";
import CIU_MARCA_SUPERMAT from "./components/reports/Comercial/Marcas/RepCIU_MarcaSupermat";
import CIU_MARCA_AMPERE from "./components/reports/Comercial/Marcas/RepCIU_MarcaAmpere";
import CIU_MARCA_ELAMIGO from "./components/reports/Comercial/Marcas/RepCIU_MarcaElAmigo";

import TM_ELSOL from "./components/reports/Comercial/Marcas/TM_ElSol";
import TM_SUPERMAT from "./components/reports/Comercial/Marcas/TM_Supermat";
import TM_AMPERE from "./components/reports/Comercial/Marcas/TM_Ampere";
import TM_ELAMIGO from "./components/reports/Comercial/Marcas/TM_ElAmigo";

// Centro de Informacion - Equipos
import CIU_SAC from "./components/reports/Comercial/Nivel2/RepCIU_SAC";

//Compras
import COMP01 from "./components/reports/Compras/RepTabComp01";

//Conciliacion Bancaria
import R001 from "./components/reports/Conciliacion Bancaria/RepTabR01";

//Conciliacion Bancaria
import GEO_QUIEBRE from "./components/reports/Geomaco/RepGeoQuiebres";
import GEO_SERVICIOS from "./components/reports/Geomaco/RepGeoServicios";

//Control de Gestion
import CG_SSA from "./components/reports/CtrlGestion/RepCG_SSA";
import CG_GEO from "./components/reports/CtrlGestion/RepCG_GEO";
import CG_ELS from "./components/reports/CtrlGestion/RepCG_ELS";
import CG_HIP from "./components/reports/CtrlGestion/RepCG_HIP";

//Finanzas
import RepFi_GEO from "./components/reports/Finanzas/RepFi_GEO";
import RepFi_HIP from "./components/reports/Finanzas/RepFi_HIP";
import RepFi_SSA from "./components/reports/Finanzas/RepFi_SSA";

function App() {
  const [ipserv, setipserv] = useState([]);

  const [datasat, setDataSat] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true)

  const getData = () => request.Post(ipserv + 'getAutQ/', {}, r => {
    setDataSat(r.rows)
    setLoadingPage(false)
  }, 'json', e => {
    console.log(e)
    Notify({ type: 'fail' })
    setLoadingPage(false)
  })

  useEffect(() => {
    if (ipserv.length > 0) getData() // setTimeout(getData, 10000)
  }, [ipserv])

  const emp = [
    'geo',
    'ssa',
    'els',
    'elssuc',
    'geoasv',
    'geoasj',
    'geob',
    'geoc',
    'geojuy',
    'geocat',
    'ssaeaa',
    'ssaasa',
    'ssaeaaj',
    'hipvj',
    'hip',
    'asaSIS',
    'dsc',

    //Historico SSA
    'Hssa',
    'Heaa',
    'Hyea',
    'Hy',
    'Hoea',
    'Heap',
    'Heat',
    'Hasa',
    'Haz',
    'Hcdy'
  ]

  const centrales = [
    'geo',
    'ssa',
    'els',
    'hip',
    'Hssa',
    'ssaasa'
  ]

  return (
    <div id="root">
      <Router history={history}>
        <MenuPrincipal ipserv={ipserv} setipserv={setipserv} />

        <Switch>
          {
            emp.map(e =>
              <Route key={e} strict path={"/PanelMenusSis" + e.toUpperCase()}>

                <PanelMenusSis ipserv={ipserv} emp={e} />
              </Route>
            )
          }

          <Route strict path="/EchoSrv">
            <EchoSrv ipserv={ipserv} />
          </Route>

          <Route strict path="/Login">
            <Login ipserv={ipserv} />
          </Route>

          <Route strict path="/Panel">
            <Panel ipserv={ipserv} />
          </Route>

          <Route strict path="/PanelQuery">
            <PanelQuery
              ipserv={ipserv}
            />
          </Route>

          <Route strict path="/Querys">
            <Querys
              ipserv={ipserv}
            />
          </Route>

          <Route strict path="/Pomi">
            <Pomi
              ipserv={ipserv}
            />
          </Route>

          <Route strict path="/PomiDist">
            <PomiDist
              ipserv={ipserv}
            />
          </Route>

          <Route strict path="/PomiAdmin">
            <PomiAdmin
              ipserv={ipserv}
            />
          </Route>

          {
            emp.map(e =>
              <Route key={e} strict path={"/PanelMenusSis" + e.toUpperCase()}>
                <PanelMenusSis ipserv={ipserv} emp={e} />
              </Route>
            )
          }

          <Route strict path="/CIU1">
            <CIU1 ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA">
            <CIU2_SSA ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_HIP">
            <CIU2_HIP ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_GEO">
            <CIU2_GEO ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_ELS">
            <CIU2_ELS ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_CTRL">
            <CIU_CTRL ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_CTRL_SSA">
            <CIU_CTRL_SSA ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_CTRL_HIP">
            <CIU_CTRL_HIP ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_CTRL_GEO">
            <CIU_CTRL_GEO ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_CTRL_ELS">
            <CIU_CTRL_ELS ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSAHIP">
            <CIU2_SSAHIP ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_SAC">
            <CIU_SAC ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_ELS_COR">
            <CIU2_ELS_COR ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_ELS_EMP">
            <CIU2_ELS_EMP ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_SALTA">
            <CIU2_SSA_SALTA ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_JUJUY">
            <CIU2_SSA_JUJUY ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_SALTA_EAA">
            <CIU2_SSA_SALTA_EAA ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_JUJUY_YEA">
            <CIU2_SSA_JUJUY_YEA ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_HIP_EAK">
            <CIU2_HIP_EAK ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_GEO_SMT">
            <CIU2_GEO_SMT ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_GEO_Y">
            <CIU2_GEO_Y ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_GEO_CAT">
            <CIU2_GEO_CAT ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_SALTA_OEA">
            <CIU2_SSA_SALTA_OEA ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_SALTA_EAT">
            <CIU2_SSA_SALTA_EAT ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_SALTA_AZ">
            <CIU2_SSA_SALTA_AZ ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_SALTA_AZ_JH">
            <CIU2_SSA_SALTA_AZ_JH ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_SALTA_ATH">
            <CIU2_SSA_SALTA_ATH ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_JUJUY_Y">
            <CIU2_SSA_JUJUY_Y ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_JUJUY_Y_COR">
            <CIU2_SSA_JUJUY_Y_COR ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_JUJUY_CDY">
            <CIU2_SSA_JUJUY_CDY ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_SSA_JUJUY_EAP">
            <CIU2_SSA_JUJUY_EAP ipserv={ipserv} />
          </Route>



          <Route strict path="/CIU2_HIP_EAK_MAY">
            <CIU2_HIP_EAK_MAY ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU2_HIP_K">
            <CIU2_HIP_K ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_MARCA_ELSOL">
            <CIU_MARCA_ELSOL ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_MARCA_AMPERE">
            <CIU_MARCA_AMPERE ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_MARCA_SUPERMAT">
            <CIU_MARCA_SUPERMAT ipserv={ipserv} />
          </Route>

          <Route strict path="/CIU_MARCA_ELAMIGO">
            <CIU_MARCA_ELAMIGO ipserv={ipserv} />
          </Route>

          <Route strict path="/TM_ELSOL">
            <TM_ELSOL ipserv={ipserv} />
          </Route>

          <Route strict path="/TM_AMPERE">
            <TM_AMPERE ipserv={ipserv} />
          </Route>

          <Route strict path="/TM_SUPERMAT">
            <TM_SUPERMAT ipserv={ipserv} />
          </Route>

          <Route strict path="/TM_ELAMIGO">
            <TM_ELAMIGO ipserv={ipserv} />
          </Route>

          <Route strict path="/COMP01">
            <COMP01 ipserv={ipserv} />
          </Route>

          <Route strict path="/GEO_QUIEBRE">
            <GEO_QUIEBRE ipserv={ipserv} />
          </Route>

          <Route strict path="/GEO_SERVICIOS">
            <GEO_SERVICIOS ipserv={ipserv} />
          </Route>

          <Route strict path="/R001">
            <R001 ipserv={ipserv} />
          </Route>

          <Route strict path="/CG_SSA">
            <CG_SSA ipserv={ipserv} />
          </Route>

          <Route strict path="/CG_HIP">
            <CG_HIP ipserv={ipserv} />
          </Route>

          <Route strict path="/CG_GEO">
            <CG_GEO ipserv={ipserv} />
          </Route>

          <Route strict path="/CG_ELS">
            <CG_ELS ipserv={ipserv} />
          </Route>

          <Route strict path="/RepFi_GEO">
            <RepFi_GEO ipserv={ipserv} />
          </Route>

          <Route strict path="/RepFi_SSA">
            <RepFi_SSA ipserv={ipserv} />
          </Route>

          <Route strict path="/RepFi_HIP">
            <RepFi_HIP ipserv={ipserv} />
          </Route>

          <Route strict path="/Reportes">
            <Reportes ipserv={ipserv} />
          </Route>

          {datasat.map(rows =>
            <Route key={rows.AutQComp} strict path={"/" + rows.AutQComp}  >

              <DinamicRoute key={rows.AutQId} params={rows.AutQParams ? JSON.parse(rows.AutQParams) : {}} Title={rows.AutQTitle} QueId={rows.AutQId} ipserv={ipserv} />
            </Route>
          )}

          <Route strict path="/">
            {ipserv && ipserv.length > 0 && localStorage.length > 0 ? '' : <Login ipserv={ipserv} />}

          </Route>
        </Switch>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
