import React from 'react'
import HeaderVista from "../compartidos/HeaderVista";

export default function Reportes() {
    return (
        <>
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'Centro de Información Unificado - Etapa 1'} />

                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="SUPERMAT"
                            width="1140"
                            height="541.25"
                            src="https://app.powerbi.com/reportEmbed?reportId=e9fe068f-0f51-4a48-8674-52bb74f83dd2&autoAuth=true&embeddedDemo=true"
                            frameborder="0"
                            allowFullScreen="true">

                        </iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
