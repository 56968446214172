import React from 'react'
import HeaderVista from "../../compartidos/HeaderVista";

export default function RepCG_HIP() {
    return (
        <>
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'Centro de Información Unificado - Etapa 2 - HIPERCAT'} />

                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="Control de Gestion - GEOMACO"
                            width="1280"
                            height="4500"
                            src="https://app.powerbi.com/view?r=eyJrIjoiMDNhOGVlYTgtOGYwZS00NzI0LWEyYmMtYjRlODQyY2JhN2EyIiwidCI6ImE5YmM2NDViLThlNjMtNDZmNy1iM2YyLWM5ODE5OWJiMjRjNSJ9"
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
