import { faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Notis from '../../../services/notificaciones'
import history from '../../../history'

export default function Notificaciones (props) {
    const [notis, setNotis] = useState(Notis.getNotificaciones())

    const formatFecha = noti => moment(noti.Fecha).format('DD/MM/YYYY') + ' ' + noti.Hora + ' hs'

    const abrir = noti => {
        if (props.abrirNoti) props.abrirNoti(noti)
        if (!noti.Link) return

        const l = noti.Link
        const q = noti.LinkQuery

        history.push(l + q)

        Notis.deleteNoti(noti.Id).then(r => !r && console.log('----- ERROR AL ACTUALIZAR LA NOTIFICACION -----'))
    }

    useEffect(() => Notis.listen('NOTIS', setNotis), [])

    return notis.length === 0
        ? <div className='text-caption full-width text-center pt-2'>
            No hay notificaciones nuevas
        </div>
        : <div className='px-2 d-flex flex-column' style={{ width: 450, maxHeight: 500, overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'break-spaces' }}>
            {notis.map(n =>
                <div
                    className='full-width d-flex pt-2 pb-3 border-top border-bottom cursor-pointer select-active fs-13 relative-position'
                    onClick={() => abrir(n)}
                >
                    <div className='d-flex justify-content-center align-items-center col-1'>
                        <FontAwesomeIcon icon={faComment} size="2x" color="grey" />
                    </div>

                    <div className='col-11'>
                        <div className='fw-bold'>
                            {n.text}
                        </div>

                        <div className={'fw-medium text-caption ' + (n.recortarBody && 'ellipsis-2-lines')}>
                            {n.body}
                        </div>
                    </div>

                    <div className='absolute-bottom-right text-caption' style={{ marginRight: 10 }}>
                        {formatFecha(n)}
                    </div>
                </div>
            )}
        </div>
}