import React, { useState, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import PanelUsuarios from './PanelUsuarios'
import PanelPerfiles from './PanelPerfiles'
import PanelMenus from './PanelMenus'

export default function Panel (props) {
    const [tab, setTab] = useState('usuarios')
    const [perfil, setPerfil] = useState(null)

    const irPerfil = p => {
        setPerfil(p)
        setTab('perfiles')
    }

    useEffect(() => {
        if (tab !== 'perfiles') setPerfil(null)
    }, [tab])

    return <div className='bg-white full-height'>
        <Tabs 
            activeKey={tab}
            onSelect={setTab}
            transition={false}
            mountOnEnter={true}
            unmountOnExit={true}
        >
            <Tab eventKey="usuarios" title="Usuarios">
                <PanelUsuarios
                    ipserv={props.ipserv}
                    irPerfil={irPerfil}
                />
            </Tab>
            <Tab eventKey="perfiles" title="Perfiles">
                <PanelPerfiles
                    ipserv={props.ipserv}
                    perfil={perfil}
                />
            </Tab>
            <Tab eventKey="menus" title="Menus">
                <PanelMenus
                    ipserv={props.ipserv}
                />
            </Tab>
        </Tabs>
    </div>
}
