import React, { useState } from 'react'
import HeaderVista from "../../../compartidos/HeaderVista";
import Button from 'react-bootstrap/Button'
import ModalComponent from '../../../webcomponents/modal/modal';

export default function RepCIU2_SSA_SALTA(props) {

    //--titulo del modal--
    const [titulo, setTitulo] = useState('')

    //--Modal
    const [show, setShow] = useState(false);

    //--Cerrar Modal
    const handleClose = () => {
        setShow(false)
    }

    //Abrir Modal
    const handleShow = (props1) => {
        setTitulo(props1)
        setShow(true);
    }

    var title = ''

    return (
        <>
            {
                show ?
                    <ModalComponent
                        ipserv={props.ipserv}
                        show={show}
                        titulo={titulo}
                        handleClose={handleClose}
                        suc=" 'EAA', 'EAT', 'AZ', 'ATH', 'OEA' "
                        equipo=" '', 'CANJE','CORRALON','CORRALONES YEA A','CORRALONES YEA B','DIGITAL','E-COMMERCE','EMPRESA','INDUSTRIA','MAYORISTA','MAYORISTA A','MAYORISTA B','MINORISTA','RESTO','RETAIL','RETAIL MAYORISTA','SAC','SUPERMAT-JOHNSON' "
                    >
                    </ModalComponent>
                    :
                    null
            }
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'SUPERMAT - PROVINCIA DE SALTA'} />
                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="Centro de Información Unificado"
                            width="1280"
                            height="760"
                            src="https://app.powerbi.com/view?r=eyJrIjoiMzA5NzMyZDYtODA3ZC00ZTAwLThkNTctZjVjMDgzMGJjZmFjIiwidCI6ImE5YmM2NDViLThlNjMtNDZmNy1iM2YyLWM5ODE5OWJiMjRjNSJ9"
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div>
                <Button onClick={() => handleShow(title = 'Información de ventas')} variant='light' style={{ color: 'red' }}>descargar informacion de Ventas</Button>
                <Button onClick={() => handleShow(title = 'sabana de ventas')} variant='light' style={{ color: 'red', float: 'right' }}>descargar sabana de ventas</Button>
            </div>
        </>
    );
}
