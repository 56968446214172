import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMailBulk, faPlus } from '@fortawesome/free-solid-svg-icons'

import Notify from '../../../components/compartidos/Notify';
import Loader from '../../../components/compartidos/Loader';
import request from '../../../request';
import moment from 'moment'

export default function EditPomi({ props }) {

    const [show, setShow] = useState(false);
    const [showMail, setShowMail] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [productCounter, setProductCounter] = useState(1);

    const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');

    const [params, setParams] = useState({
        PomiId: props.PomiId ? props.PomiId : '',
        PomiMarcaTemporal: currentTimestamp,

        PomiMailDelVendedor: props.PomiMailDelVendedor ? props.PomiMailDelVendedor : '',
        PomiMailEncargadoDeDeposito: props.PomiMailEncargadoDeDeposito ? props.PomiMailEncargadoDeDeposito : '',
        PomiFechaFactura: props.PomiFechaFactura ? moment(props.PomiFechaFactura).format('YYYY-MM-DD') : '',
        PomiNumDeFactura: props.PomiNumDeFactura ? props.PomiNumDeFactura : '',
        PomiNombreYApellidoDeCliente: props.PomiNombreYApellidoDeCliente ? props.PomiNombreYApellidoDeCliente : '',

        PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
        PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',
        PomiObservaciones: props.PomiObservaciones ? props.PomiObservaciones : '',
        Tabla: props.Tabla ? props.Tabla : '',
        Title: props.Title ? props.Title : '',
        Tip: props.Tip ? props.Tip : '',
    });

    const [selectedSucursal, setSelectedSucursal] = useState({
        PomiMailEncargadoDeDeposito: props.PomiMailEncargadoDeDeposito ? props.PomiMailEncargadoDeDeposito : '',
    });

    const {
        PomiId,
        // PomiNumDePedido,
        PomiMailDelVendedor,
        PomiMailEncargadoDeDeposito,
        PomiFechaFactura,
        PomiNumDeFactura,
        PomiNombreYApellidoDeCliente,
        PomiSucDondeSeEncuentraDisponibleLaMer,
        PomiSucDeSolicitanteDeMercaderia,
        PomiObservaciones,

        Tabla,
        Title,
        Tip
    } = params;


    const saveparam = (data) => {
        return new Promise((resolve, reject) => {
            setLoading(true); // Activa el indicador de carga
            request.Post(props.ipserv + 'savepomi/', { params: data }, async (r) => {
                if (r.success) {
                    Notify("Reporte Guardado");
                    setShow(false);
                    props.getParam();
                    console.log(r.success);
                    console.log(params)
                    console.log(data)
                    resolve();
                } else {
                    const errorMessage = r.error || "Se produjo un error guardando los cambios";
                    Notify(errorMessage);
                    setLoading(false); // Desactiva el indicador de carga
                }
            });
        });
    };


    const cleanparams = () => {
        setParams({
            PomiId: '',
            PomiMarcaTemporal: '',
            PomiNumDePedido: '',
            PomiMailDelVendedor: '',
            PomiMailEncargadoDeDeposito: '',
            PomiFechaFactura: '',
            PomiNumDeFactura: '',
            PomiNombreYApellidoDeCliente: '',
            PomiSucDeSolicitanteDeMercaderia: '',

            PomiSucDeSolicitant: '',
            PomiSucDondeSeEncuentraDisponibleLaMer: '',
            PomiObservaciones: '',

        });
    }

    const onSubmitEditegresos = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const dataToSend = { ...params, products: products };
            await saveparam(dataToSend);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddProduct = () => {
        setProducts([...products, { PomiCodigoDeProducto: '', PomiDescripcion: '', PomiCantidad: '', PomiUnidadDeMedida: '' }]);
    };

    const handleRemoveProduct = (index) => {
        const newProducts = [...products];
        newProducts.splice(index, 1);
        setProducts(newProducts);
    };

    const handleProductChange = (index, field, value) => {
        const newProducts = [...products];
        newProducts[index][field] = value;
        setProducts(newProducts);
        setProductCounter(productCounter + 1);
    };


    const onChangeparams = e => {
        e.preventDefault();
        const selectedValue = e.target.value;

        setSelectedSucursal(prevState => ({
            ...prevState,
            PomiMailEncargadoDeDeposito: '',
        }));

        setParams({
            ...params,
            [e.target.name]: selectedValue,
        });

        if (e.target.name === 'PomiSucDeSolicitanteDeMercaderia') {
            const selectedSucursalInfo = props.sucs.find(suc => suc.DES === selectedValue);

            if (selectedSucursalInfo) {
                setSelectedSucursal(prevState => ({
                    ...prevState,
                    PomiMailEncargadoDeDeposito: selectedSucursalInfo.MAIL || '',
                    PomiSucDeSolicitanteDeMercaderia: selectedSucursalInfo.DES || '',
                }));

                setParams({
                    ...params,
                    PomiMailEncargadoDeDeposito: selectedSucursalInfo.MAIL || '',
                    PomiSucDeSolicitanteDeMercaderia: selectedSucursalInfo.DES || '',
                });
            } else {

                setSelectedSucursal(prevState => ({
                    ...prevState,
                    PomiMailEncargadoDeDeposito: '',
                    PomiSucDeSolicitanteDeMercaderia: '',
                }));

                setParams({
                    ...params,
                    PomiMailEncargadoDeDeposito: '',
                    PomiSucDeSolicitanteDeMercaderia: '',
                });
            }
        }
    };

    useEffect(() => {

    }, [show]);

    useEffect(() => {

    }, [params]);

    useEffect(() => {
        setParams({
            ...params,
            products: products
        });
    }, [products]);

    useEffect(() => {

        setParams({
            PomiId: props.PomiId ? props.PomiId : '',
            PomiNumDePedido: props.PomiNumDePedido ? props.PomiNumDePedido : '',

            PomiMarcaTemporal: currentTimestamp,
            PomiMailDelVendedor: props.PomiMailDelVendedor ? props.PomiMailDelVendedor : '',
            PomiMailEncargadoDeDeposito: props.PomiMailEncargadoDeDeposito ? props.PomiMailEncargadoDeDeposito : '',
            PomiFechaFactura: props.PomiFechaFactura ? moment(props.EquipVendFchDsd).format('YYYY-MM-DD') : '',
            PomiNumDeFactura: props.PomiNumDeFactura ? props.PomiNumDeFactura : '',
            PomiNombreYApellidoDeCliente: props.PomiNombreYApellidoDeCliente ? props.PomiNombreYApellidoDeCliente : '',
            PomiCodigoDeProducto: props.PomiCodigoDeProducto ? props.PomiCodigoDeProducto : '',
            PomiDescripcion: props.PomiDescripcion ? props.PomiDescripcion : '',
            PomiCantidad: props.PomiCantidad ? props.PomiCantidad : '',
            PomiUnidadDeMedida: props.PomiUnidadDeMedida ? props.PomiUnidadDeMedida : '',
            PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
            PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',
            PomiObservaciones: props.PomiObservaciones ? props.PomiObservaciones : '',

            Tabla: props.Tabla ? props.Tabla : '',
            Title: props.Title ? props.Title : '',
            Tip: props.Tip ? props.Tip : '',
        })
    }, [props]);

    return (
        <React.Fragment>
            <Button
                className='mr-3'
                variant="primary"
                onClick={handleShow}
                style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                {Title == "Editar Registro" ?
                    <FontAwesomeIcon
                        icon={faEdit} className="mr-2" />
                    : <>
                        <FontAwesomeIcon
                            icon={faPlus}
                            className="mr-2" />
                        <span>Nuevo Registro</span></>}
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" centered size="lg">

                <Modal.Dialog style={{ maxWidth: '1000px', overflowY: 'auto', className: 'flex' }}>

                    <Form onSubmit={onSubmitEditegresos}>

                        <Modal.Header closeButton>
                            <Modal.Title> {Title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Row>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>NOMBRE Y APELLIDO DEL CLIENTE</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiNombreYApellidoDeCliente"
                                            value={PomiNombreYApellidoDeCliente.toUpperCase()}
                                            required type="text"
                                            placeholder="INGRESAR NOMBRE Y APELLIDO"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>MAIL VENDEDOR</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiMailDelVendedor"
                                            value={PomiMailDelVendedor.toLowerCase()}
                                            required type="email"
                                            placeholder="mail_vendedor@uniber.com.ar" />
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>NUMERO FACTURA</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiNumDeFactura"
                                            value={PomiNumDeFactura.toUpperCase()}
                                            required type="text"
                                            placeholder="A 001-0002"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>FECHA FACTURA</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiFechaFactura"
                                            value={PomiFechaFactura}
                                            required type="date"
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>SUCURSAL SOLICITANTE DE MECADERIA</Form.Label>

                                        <Form.Control
                                            as="select"
                                            defaultValue={PomiSucDeSolicitanteDeMercaderia}
                                            name="PomiSucDeSolicitanteDeMercaderia"
                                            onChange={onChangeparams}
                                        >
                                            <option value=''>SELECCIONE UNA SUCURSAL</option>
                                            {props.sucs.map(c => <option key={c.DES} >{c.DES}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label className="mt-2">OBSERVACIONES</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiObservaciones"
                                            value={PomiObservaciones.toUpperCase()}
                                            required type="text"
                                            placeholder="INGRESAR UNA OBSERVACION"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {products.map((product, index) => (
                                <>
                                    <Form.Text className="bg-primary mt-3 border shadow rounded">

                                        <h6 className="mt-3 text-center text-light">
                                            ARTICULO Nº{index + 1}
                                        </h6>


                                    </Form.Text>
                                    <Row key={index}>

                                        <Col xs={6} md={6}>

                                            <Form.Group controlId={`codigo-${index}`}>
                                                <Form.Label>CODIGO</Form.Label>
                                                <Form.Control
                                                    value={product.codigo}
                                                    onChange={(e) => handleProductChange(index, 'codigo', e.target.value)}
                                                    required
                                                    type="text"
                                                    placeholder="CEM.LOM.013" />
                                            </Form.Group>


                                        </Col>


                                        <Col xs={6} md={6}>
                                            <Form.Group controlId={`cantidad-${index}`}>
                                                <Form.Label>CANTIDAD</Form.Label>
                                                <Form.Control
                                                    value={product.cantidad}
                                                    onChange={(e) => handleProductChange(index, 'cantidad', e.target.value)}
                                                    required
                                                    type="number"
                                                    placeholder="CANTIDADES" />
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <Form.Group controlId={`unidad-${index}`}>
                                                <Form.Label>UNIDAD DE MEDIDA</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.unidad}
                                                    onChange={(e) => handleProductChange(index, 'unidad', e.target.value)}
                                                    required
                                                    type="text"
                                                    placeholder="UNIDADES"
                                                >
                                                    <option value=''>SELECCIONE UN TIPO DE UNIDAD</option>
                                                    {props.unidad.map(c => <option key={c.DES}>{c.DES}</option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Col xs={6} md={6}>
                                        <Button
                                            onClick={() => handleRemoveProduct(index)}
                                            variant="danger"
                                            className='mb-3'
                                        >Eliminar
                                        </Button>
                                    </Col>
                                </>

                            ))}
                            <Col xs={6} md={6}>
                                <Button
                                    onClick={handleAddProduct}
                                    variant="success">
                                    Agregar Producto
                                </Button>
                            </Col>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit" style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Form>

                    <Loader loading={loading} />

                </Modal.Dialog >
            </Modal >

        </React.Fragment>
    );
}