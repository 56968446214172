import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Image, Container, Form, Button, Card, Alert, Row, Spinner, Col, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Notify from '../../compartidos/Notify'
import moment from 'moment'
import './login.css';
import '../../compartidos/clases_utiles.css'
import keyEnterConfirm from '../../compartidos/keyEnterConfirm'
import Notis from '../../../services/notificaciones'

//Iconographic
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk, faUser } from '@fortawesome/free-solid-svg-icons'
import request from '../../../request'
import focusElement from '../../compartidos/focusElement'


export default function Login(props) {
    let history = useHistory();

    const [autenticado, setAutenticado] = useState();

    const [usuario, setUsuario] = useState({
        email: '',
        password: ''
    });

    const [loginerr, setLoginerr] = useState(false);
    const { email, password } = usuario;

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState(false)

    const [show, setShow] = useState(false)

    const [emailRec, setEmailRec] = useState('')

    const [sending, setSending] = useState(false)

    const onChangeUsuario = e => {
        setUsuario({
            ...usuario,
            [e.target.name]: e.target.value
        });
    };

    const validarUsuario = () => {
        return usuario.email && usuario.password
    }

    const sendMail = () => {
        setSending(true)

        request.Post(props.ipserv + "recpass/", { email: emailRec }, r => {
            if (r.success) {
                setSending(false)
                setShow(false)
                Notify('Sus datos de inicio de sesión han sido enviados a su correo electronico.')
            } else if (r.Error) {
                setSending(false)
                Notify(r.Error)
            } else {
                setSending(false)
                Notify({ type: 'error' })
            }
        })
    }

    const LoginUsr = async (usuario) => {

        if (!validarUsuario()) {
            setError(true)
            return null
        }

        setLoading(true)

        request.Post(props.ipserv + "auth/", usuario, r => {
            setLoading(false)
            if (r.success) {
                let datausr = r.datausr

                // Guardo la hora y fecha de conexion del usuario
                let conexion = moment().format('DD/MM/YYYY HH:mm')

                datausr[0].Conexion = conexion
                datausr[0].ConexionLabel = `${conexion.split(' ')[1]} hs - ${conexion.split(' ')[0]}`

                localStorage.setItem('menuneg', JSON.stringify(r.menuneg));
                localStorage.setItem('menuemp', JSON.stringify(r.menuemp));
                localStorage.setItem('usuario', JSON.stringify(datausr));
                localStorage.setItem('menugeneral', JSON.stringify(r.datamenu));
                localStorage.setItem('menuitem', JSON.stringify(r.itemmenu));
                localStorage.setItem('submenu', JSON.stringify(r.submenu));
                localStorage.setItem('token', r.tokenUsrFenix);
                window.dispatchEvent(new Event('storage'))
                setAutenticado(true);
                history.push('/');
                // alert('Bienvenido');

                Notis.buscarNotis()

                const apellido = datausr[0].UsrApel[0].toUpperCase() + datausr[0].UsrApel.slice(1).toLowerCase()
                const nombre = datausr[0].UsrNom[0].toUpperCase() + datausr[0].UsrNom.slice(1).toLowerCase()

                Notify(`Bienvenido ${nombre}, ${apellido}`)
            } else {
                //setAutenticado(false);
                setLoginerr(true);

                if (r.authFail) {
                    Notify('Los datos ingresados son incorrectos')
                } else {
                    setUsuario({
                        email: '',
                        password: ''
                    });

                    Notify({ type: 'error' });
                    console.log(r.error)
                }
            }
        })
    }

    useEffect(() => {
        focusElement('#form-email-login')
    }, [props])

    return (
        <Container className="principalback bg-white text-center col d-flex justify-content-center px-0">
            {
                autenticado === true ? (
                    <Image src="https://i.imgur.com/pfzBBQn.png" fluid width="10%" />
                ) : (
                    <Row className="full-width full-height mx-0 px-0 container-login-row">
                        <Col
                            lg={6}
                            md={6}
                            className="px-0 mx-0 login-container mostrar-desde-izquierda d-flex align-items-center justify-content-center"
                        >
                            <div className="d-flex flex-column">
                                <div className="full-width d-flex justify-content-center mb-3">
                                    <Image src="https://i.imgur.com/RxQ96mj.png" fluid width="50%" />
                                </div>

                                <div className="h5 fw-medium">
                                    Motivados y Comprometidos en Construir Sueños
                                </div>

                                <Form className="relative-position mt-3 half-width mx-auto">
                                    <Form.Group controlId="formLoginEmail" className='pt-1 text-left'>
                                        <Form.Label className={`pl-2 ${error && 'text-negative temblor'}`}>
                                            <FontAwesomeIcon icon={faMailBulk} /> Email
                                        </Form.Label>
                                        <Form.Control
                                            id="form-email-login"
                                            type="email"
                                            required
                                            name="email"
                                            placeholder="Ingrese su email"
                                            onChange={onChangeUsuario}
                                            value={email}
                                            maxLength='50'
                                            pattern='(?=^.{5,50}$)([A-Za-z0-9_.-]+@[A-Za-z0-9_.-]+\.[A-Za-z]{2,5})'
                                            onKeyUp={e => keyEnterConfirm(e, () => LoginUsr(usuario))}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formLoginPassword" className='pt-3 text-left'>
                                        <Form.Label className={`pl-2 ${error && 'text-negative temblor'}`}>
                                            <FontAwesomeIcon icon={faUser} /> Password
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            required
                                            name="password"
                                            placeholder="Ingrese su contraseña"
                                            onChange={onChangeUsuario}
                                            value={password}
                                            minLength='3'
                                            maxLength='20'
                                            pattern='[a-zA-Z0-9!?-_]{3,20}'
                                            onKeyUp={e => keyEnterConfirm(e, () => LoginUsr(usuario))}
                                        />
                                    </Form.Group>
                                    {loading
                                        ? <Spinner animation="border" />
                                        : <>
                                            <div className="text-left mb-1 fs-13 text-primary text-underline">
                                                <i className='cursor-pointer' onClick={() => setShow(true)}>Recuperar contraseña</i>
                                            </div>
                                            <Button
                                                variant="default"
                                                block
                                                className='mr-3 font-weight-bold text-white bg-primary'
                                                onClick={() => LoginUsr(usuario)}
                                            >
                                                Acceder
                                            </Button>
                                        </>
                                    }
                                    {error &&
                                        <div className="absolute text-negative text-mini text-center full-width" style={{ bottom: -20 }}>
                                            Debes completar todos los campos para ingresar
                                        </div>
                                    }
                                </Form>
                            </div>
                        </Col>
                        <Col
                            lg={6}
                            md={6}
                            className="px-0 mx-0 d-flex justify-content-center align-items-center mostrar-desde-derecha"
                        >
                            <Image src="https://i.imgur.com/H7YmJs9.png" fluid width="50%" />
                        </Col>
                    </Row>
                )
            }

            <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Recuperar Contraseña</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Escriba el email que usa en el sitio y se le enviara su contraseña en unos instantes.
                    <Form.Group className='mt-4 text-left'>
                        <Form.Control
                            type="email"
                            required
                            placeholder="Ingrese su email"
                            onChange={e => setEmailRec(e.target.value)}
                            maxLength='50'
                            pattern='(?=^.{5,50}$)([A-Za-z0-9_.-]+@[A-Za-z0-9_.-]+\.[A-Za-z]{2,5})'
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Cerrar
                    </Button>
                    {sending
                        ? <Spinner animation="border" className='mx-3' />
                        : <Button variant="primary" onClick={sendMail}>Enviar</Button>
                    }
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

/* Original
<Card style={{ width: '18rem' }} className="bg-white mt-3 mb-3 border shadow rounded " id="card-login">
    {loginerr ? <Alert variant='danger' className='mt-2'> Email o contraseña incorrecto/s </Alert> : ""}
    <Card.Body >
        <Image src="https://i.ibb.co/GMjxbmS/horizontal-color.png" fluid width="350px" />
        <Row className="mt-3 text-white border-bottom border-warning"></Row>
        <Form className="mt-3">
            <Form.Group controlId="formLoginEmail" className='pt-1 text-left'>
                <Form.Label className='pl-2'>
                    <FontAwesomeIcon icon={faMailBulk} /> Email
                </Form.Label>
                <Form.Control
                    type="email"
                    required
                    name="email"
                    placeholder="Ingrese su email"
                    onChange={onChangeUsuario}
                    value={email}
                    maxLength='50'
                    pattern='(?=^.{5,50}$)([A-Za-z0-9_.-]+@[A-Za-z0-9_.-]+\.[A-Za-z]{2,5})'
                    onKeyUp={keyEnterConfirm}
                />
            </Form.Group>
            <Form.Group controlId="formLoginPassword" className='pt-3 text-left'>
                <Form.Label className='pl-2'>
                    <FontAwesomeIcon icon={faUser} />  Password
                </Form.Label>
                <Form.Control
                    type="password"
                    required
                    name="password"
                    placeholder="Ingrese su password"
                    onChange={onChangeUsuario}
                    value={password}
                    minLength='3'
                    maxLength='20'
                    pattern='[a-zA-Z0-9!?-_]{3,20}'
                    onKeyUp={keyEnterConfirm}
                />
            </Form.Group>
            <Row className="mb-3 text-white border-bottom border-warning"></Row>
            {loading
                ? <Spinner animation="border" />
                : <Button
                    variant="default"
                    style={{ color: "white", background: "purple" }}
                    block
                    className='mr-3 font-weight-bold'
                    onClick={() => LoginUsr(usuario)}
                >
                    Acceder
                </Button>
            }
        </Form>
    </Card.Body>
</Card>
*/
