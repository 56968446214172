import React, { useState, useEffect } from 'react'
import { Navbar, Dropdown, Image, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

import NuevoMenu from './NuevoMenu';

//Iconographic
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import request from '../../../request';
import Notificaciones from './Notificaciones';
import Queue from './Queue';


export default function PrincipalMenu(props) {

    const [closeSession, setCloseSession] = useState(false);
    let history = useHistory();
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const [show, setShow] = useState(false)
    const [showPerfil, setShowPerfil] = useState(false)

    const [sec, setSec] = useState('')

    const [dataUsr, setDataUsr] = useState(null)

    const [token, setToken] = useState(null)

    // Cierra sesion y envia al login
    const closesession = () => {
        localStorage.clear();
        setCloseSession(true);
        //Notis.stopNotis()
        history.push('/Login');
    }

    //EchoSrv
    const [ip, SetUrl] = useState({
        ip: ''
    });

    var dataips = [
        {
            url: "https://fenixdata.uniber.com.ar:31251/"
        }
    ];

    var callecho = 0;


    const EchoSrv = async (url) => {
        if (props.ipserv.length == 0) {
            request.Get(
                dataips[callecho].url + "ipactiva/",
                { ip: dataips[callecho].url },
                r => {
                    console.log("RESPUESTA DE IP:" + dataips[callecho].url)
                    props.setipserv(dataips[callecho].url)

                },
                'json',
                e => {
                    console.log("SIN RESPUESTA : REINTENTAOD CON OTRO SERVER ");
                    callecho++;
                    EchoSrv(url);
                }
            )
        } else {
            console.log("RESPUESTA : " + props.ipserv);
        }
    }

    // Cierra el menu
    const onClose = () => {
        setShow(false)
    }

    // Retorna el boton para ingresar al login
    const ingresar = () => {
        if (!token && props.ipserv.length > 0) {
            return <Link
                to="/Login"
                className="d-flex align-items-center cursor-pointer text-white"
            >
                <FontAwesomeIcon
                    className="mx-2"
                    icon={faUser}
                />
                <i class="bi bi-box-arrow-in-right"></i>
                Ingresar
            </Link>
        }
    }

    // Retorna el tooltip a mostrar cuando se pasa el cursor por las iniciales del usuario en el header, muestra el nombre, apellido y hora de la conexion
    const renderTooltip = (p) => {
        if (!dataUsr) return ''

        const apellido = dataUsr.UsrApel[0].toUpperCase() + dataUsr.UsrApel.slice(1).toLowerCase()
        const nombre = dataUsr.UsrNom[0].toUpperCase() + dataUsr.UsrNom.slice(1).toLowerCase()
        const conexion = dataUsr.ConexionLabel

        return <Tooltip
            {...p}
            className="d-flex flex-column"
        >
            <div>{nombre}, {apellido}</div>
            <div>{conexion}</div>
        </Tooltip>
    }

    // Retorna nombre y apellido del usuario logueado separados por coma
    const getNomApelUsr = data => {
        return `${data.UsrNom[0].toUpperCase() + data.UsrNom.slice(1).toLowerCase()}, ${data.UsrApel[0].toUpperCase() + data.UsrApel.slice(1).toLowerCase()}`
    }

    const checkUserData = () => {
        const data = localStorage.usuario
            ? JSON.parse(localStorage.usuario)[0]
            : null

        setDataUsr(data)
        setToken(localStorage.getItem('token'))
    }

    useEffect(() => {
        EchoSrv();
        /* 
        let a = true
        setInterval(() => {
            a ? Notify('aver que pasa aver que pasa aver que pasa aver que pasa aver que pasa aver que pasa aver que pasa aver que pasa aver que pasa ')
                : Notify('a ver que pasa')

            a = !a
        }, 1500)
        */
    }, [])

    useEffect(() => {
        setCloseSession(false);
    }, [closeSession])

    useEffect(() => {
    }, [props.ipserv])

    useEffect(() => {
        const data = localStorage.usuario
            ? JSON.parse(localStorage.usuario)[0]
            : null
        setDataUsr(data)
        setToken(localStorage.getItem('token'))
    }, [props])

    useEffect(() => {
        window.addEventListener('storage', checkUserData)

        return () => {
            window.removeEventListener('storage', checkUserData)

        }
    }, [])


    return <>
        <Navbar bg="dark" data-bs-theme="dark"
            // id="PrincipalMenu"
            collapseOnSelect
            className="bg-body-tertiary"
            expand={false}
        >
            <div className="d-flex">
                <button className="navbar-toggler" type="button" data-toggle="collapse" onClick={() => setShow(true)}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                {ingresar()}
            </div>
            <NuevoMenu
                show={show}
                ipserv={props.ipserv}
                onClose={onClose}
                closesession={closesession}
            />

            <Navbar.Text>
                {props.ipserv.length === 0
                    ? <h4>Aguarde mientras buscamos un Servidor Activo...</h4>
                    : sec
                }
            </Navbar.Text>
            <Navbar.Brand className="d-flex align-items-center">
                {dataUsr &&
                    <Dropdown className='' drop="down" show={showPerfil} onToggle={setShowPerfil}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip}
                        >
                            <Dropdown.Toggle id="menu-user" className="mx-3 relative-position avatar-usuario text-center d-flex justify-content-center align-items-center bg-access fw-bold">
                                {dataUsr.UsrNom[0].toUpperCase() + dataUsr.UsrApel[0].toUpperCase()}
                            </Dropdown.Toggle>
                        </OverlayTrigger>

                        <Dropdown.Menu>
                            <Notificaciones ipserv={props.ipserv}
                                abrirNoti={() => setShowPerfil(false)}
                            />

                            <div className='linea-horizontal my-2' />

                            <div className="d-flex flex-column text-grey px-2">
                                <div className='fs-13 d-flex align-items-center'>
                                    <div className='circulo-mini-positive mr-1' />
                                    <span className='fw-bold'>
                                        {getNomApelUsr(dataUsr)} - {dataUsr.UsrNick}
                                    </span>
                                </div>
                                <div className='text-caption'>
                                    Conexion: {dataUsr.ConexionLabel}
                                </div>
                            </div>

                            <div className='linea-horizontal my-2' />

                            <Dropdown.Item className='text-center' onClick={closesession}>Cerrar Sesión</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <Link to="/">
                    <Image width="100px" src={"https://i.imgur.com/Lrv3DFv.png"} fluid />
                </Link>
            </Navbar.Brand>
        </Navbar>

        <Queue ipserv={props.ipserv} />
    </>
}