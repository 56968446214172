import React from 'react'
import HeaderVista from "../../compartidos/HeaderVista";

export default function RepTabComp01() {
    return (
        <>
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'Tablero de Compras'} />

                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="Tablero de compras"
                            width="1400"
                            height="760"
                            src=""
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
