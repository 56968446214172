import React from 'react'
import HeaderVista from "../../../compartidos/HeaderVista";

export default function RepCIU_CTRL_GEO() {
    return (
        <>
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'CIU - TABLERO DE CONTROL - GEOMACO'} />
                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="Centro de Información Unificado - GEOMACO"
                            width="1280"
                            height="4200"
                            src="https://app.powerbi.com/view?r=eyJrIjoiMjhkYzE3ZTYtYWU3Yy00ZDc1LWIwZDItOTIwMTM2NjQwYjFkIiwidCI6ImE5YmM2NDViLThlNjMtNDZmNy1iM2YyLWM5ODE5OWJiMjRjNSJ9"
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
