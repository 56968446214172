import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router'
import { Form, Button, Modal, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMailBulk, faPlus } from '@fortawesome/free-solid-svg-icons'

import Notify from '../../../components/compartidos/Notify';
import Loader from '../../../components/compartidos/Loader';
import request from '../../../request';

export default function EditPomi({ props, selectedRows, handleCheckboxChange }) {

    const [show, setShow] = useState(false);
    const [showMail, setShowMail] = useState(false);
    const [loading, setLoading] = useState(false);

    const history = useHistory()

    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);


    const modalRef = useRef(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedSucursal, setSelectedSucursal] = useState({
        PomiMailEncargadoDeDeposito: props.PomiMailEncargadoDeDeposito ? props.PomiMailEncargadoDeDeposito : '',
    });

    const [params, setParams] = useState({

        PomiId: props.PomiId ? props.PomiId : '',

        PomiNumeroDeDist: props.PomiNumeroDeDist ? props.PomiNumeroDeDist : '',
        PomiObservacionesAbastecimiento: props.PomiObservacionesAbastecimiento ? props.PomiObservacionesAbastecimiento : '',
        PomiSeConfirmaLaDistribucion: props.PomiSeConfirmaLaDistribucion ? props.PomiSeConfirmaLaDistribucion : '',
        PomiEnvioMail: props.PomiEnvioMail ? props.PomiEnvioMail : '',
        PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
        PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',

        Tabla: props.Tabla ? props.Tabla : '',
        Title: props.Title ? props.Title : '',
        Tip: props.Tip ? props.Tip : '',

    });

    const {
        PomiId,
        PomiNumeroDeDist,
        PomiObservacionesAbastecimiento,
        PomiSeConfirmaLaDistribucion,
        PomiEnvioMail,
        PomiSucDeSolicitanteDeMercaderia,
        PomiSucDondeSeEncuentraDisponibleLaMer,

        Tabla,
        Title,
        Tip
    } = params;


    const isAnyCheckboxChecked = Array.isArray(props.selectedRows) && props.selectedRows.length > 0;
    console.log(isAnyCheckboxChecked)

    const saveparam = (data) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            request.Post(props.ipserv + 'savepomidist/', data, response => {
                setLoading(false);

                if (response.success) {
                    console.log("Datos guardados exitosamente:", response);
                    Notify("Datos guardados exitosamente");
                    resolve(response);
                } else {
                    console.error("Error al guardar los datos:", response);
                    reject("Error al guardar los datos");
                }
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const response = await saveparam(params);
        setLoading(false);

        if (response.success) {
            Notify("Correo enviado!");
            handleClose();
            setShow(false);
            props.clearSelectedRows()
            await props.getParam();
        } else {
            Notify("Se Produjo un error al enviar el correo");
        }
    };

    const saveMultipleParams = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const dataToSend = { params, selectedRows: props.selectedRows };
            await saveparam(dataToSend);
            handleClose();
            props.clearSelectedRows()
            await props.getParam();
            setLoading(false);

        } catch (error) {
            console.error('Error al guardar los multiples parámetros:', error);
            setLoading(false);
            Notify(error);
        }
    };

      const onChangeparams = e => {
        e.preventDefault();
        const selectedValue = e.target.value;

        setSelectedSucursal(prevState => ({
            ...prevState,
            PomiMailEncargadoDeDeposito: '',
        }));

        setParams({
            ...params,
            [e.target.name]: selectedValue,
        });

        if (e.target.name === 'PomiSucDondeSeEncuentraDisponibleLaMer') {
            const selectedSucursalInfo = props.sucs.find(suc => suc.DES === selectedValue);

            if (selectedSucursalInfo) {
                setSelectedSucursal(prevState => ({
                    ...prevState,
                    PomiMailEncargadoDeDeposito: selectedSucursalInfo.MAIL || '',
                    PomiSucDondeSeEncuentraDisponibleLaMer: selectedSucursalInfo.DES || '',
                }));

                setParams({
                    ...params,
                    PomiMailEncargadoDeDeposito: selectedSucursalInfo.MAIL || '',
                    PomiSucDondeSeEncuentraDisponibleLaMer: selectedSucursalInfo.DES || '',
                });
            } else {

                setSelectedSucursal(prevState => ({
                    ...prevState,
                    PomiMailEncargadoDeDeposito: '',
                    PomiSucDondeSeEncuentraDisponibleLaMer: '',
                }));

                setParams({
                    ...params,
                    PomiMailEncargadoDeDeposito: '',
                    PomiSucDondeSeEncuentraDisponibleLaMer: '',
                });
            }
        }
    };


    useEffect(() => {
        setParams({
            ...params,
            selectedRows: selectedRows,
            handleCheckboxChange: handleCheckboxChange
        });
    }, [selectedRows]);


    useEffect(() => {

    }, [show]);


    useEffect(() => {

    }, [params]);

    useEffect(() => {

        setParams({
            PomiId: props.PomiId ? props.PomiId : '',
            PomiNumeroDeDist: props.PomiNumeroDeDist ? props.PomiNumeroDeDist : '',
            PomiObservacionesAbastecimiento: props.PomiObservacionesAbastecimiento ? props.PomiObservacionesAbastecimiento : '',
            PomiSeConfirmaLaDistribucion: props.PomiSeConfirmaLaDistribucion ? props.PomiSeConfirmaLaDistribucion : '',
            PomiEnvioMail: props.PomiEnvioMail ? props.PomiEnvioMail : '',
            PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
            PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',

            Tabla: props.Tabla ? props.Tabla : '',
            Title: props.Title ? props.Title : '',
            Tip: props.Tip ? props.Tip : '',
        })
    }, [props]);


    return (
        <React.Fragment>
            <Button className='m-3'
                variant="primary"
                onClick={handleShow}
                disabled={!isAnyCheckboxChecked}
                style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>

                <FontAwesomeIcon
                    icon={faMailBulk}
                    className="m-1"
                    size="lg"
                />
                <span>Enviar Mail</span>
            </Button>


            <Modal show={show} onHide={handleClose} backdrop="static" centered size="lg" ref={modalRef}>

                <Modal.Dialog style={{ maxWidth: '1000px', overflowY: 'auto', className: 'flex' }}>

                    <Form onSubmit={handleSubmit}>

                        <Modal.Header closeButton>
                            <Modal.Title> {Title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>NUMERO DE DISTRIBUCION</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiNumeroDeDist"
                                            value={PomiNumeroDeDist}
                                            required type="number"
                                            placeholder="Ingrese Numero de Distribucion"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>OBSERVACIONES ABASTECIMIENTO</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiObservacionesAbastecimiento"
                                            value={PomiObservacionesAbastecimiento}
                                            required type="text"
                                            placeholder="Observaciones de Abastecimiento"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>SUCURSAL DONDE SE ENCUENTRA DISPONIBLE LA MERCADERIA</Form.Label>
                                        <Form.Control
                                            as="select"
                                            required
                                            defaultValue={PomiSucDondeSeEncuentraDisponibleLaMer}
                                            name="PomiSucDondeSeEncuentraDisponibleLaMer"
                                            onChange={onChangeparams}
                                        >
                                            <option value=''>SELECCIONAR UNA SUCURSAL</option>
                                            {props.sucs.map(c => <option key={c.DES} >{c.DES}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group controlId="PomiSeConfirmaLaDistribucion">
                                        <Form.Label>CONFIRMAR DISTRIBUCION</Form.Label>
                                        <Form.Control
                                            as="select"
                                            required
                                            onChange={onChangeparams}
                                            name="PomiSeConfirmaLaDistribucion"
                                            value={PomiSeConfirmaLaDistribucion}
                                        >
                                            <option value=''>Seleccione una opción</option>
                                            <option value='SI'>SI</option>
                                            <option value='NO'>NO</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>

                            <Button variant="primary" type="submit" onClick={saveMultipleParams} style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Form>

                    <Loader loading={loading} />


                </Modal.Dialog >
            </Modal >

        </React.Fragment>
    );
}