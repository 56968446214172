import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMailBulk, faPlus } from '@fortawesome/free-solid-svg-icons';

import Notify from '../../../components/compartidos/Notify';
import Loader from '../../../components/compartidos/Loader';
import request from '../../../request';
import moment from 'moment'

export default function PomiEditAdmin({ props }) {

    const [show, setShow] = useState(false);
    const [showMail, setShowMail] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);


    const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');

    const [params, setParams] = useState({

        PomiId: props.PomiId ? props.PomiId : '',
        PomiMarcaTemporal: currentTimestamp,
        PomiNumDePedido: props.PomiNumDePedido ? props.PomiNumDePedido : '',

        PomiMailDelVendedor: props.PomiMailDelVendedor ? props.PomiMailDelVendedor : '',
        PomiMailEncargadoDeDeposito: props.PomiMailEncargadoDeDeposito ? props.PomiMailEncargadoDeDeposito : '',
        PomiFechaFactura: props.PomiFechaFactura ? moment(props.PomiFechaFactura).format('YYYY-MM-DD') : '',
        PomiNumDeFactura: props.PomiNumDeFactura ? props.PomiNumDeFactura : '',
        PomiNombreYApellidoDeCliente: props.PomiNombreYApellidoDeCliente ? props.PomiNombreYApellidoDeCliente : '',

        PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
        PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',
        PomiObservaciones: props.PomiObservaciones ? props.PomiObservaciones : '',
        PomiObservacionesAbastecimiento: props.PomiObservacionesAbastecimiento ? props.PomiObservacionesAbastecimiento : '',
        PomiSeConfirmaLaDistribucion: props.PomiSeConfirmaLaDistribucion ? props.PomiSeConfirmaLaDistribucion : '',

        PomiDescripcion: props.PomiDescripcion ? props.PomiDescripcion : '',
        PomiCantidad: props.PomiCantidad ? props.PomiCantidad : '',
        PomiUnidadDeMedida: props.PomiUnidadDeMedida ? props.PomiUnidadDeMedida : '',

        PomiNumeroDeDist: props.PomiNumeroDeDist ? props.PomiNumeroDeDist : '',
        PomiCodigoDeEntrega: props.PomiCodigoDeEntrega ? props.PomiCodigoDeEntrega : '',
        PomiIdRemito: props.PomiIdRemito ? props.PomiIdRemito : '',


        Tabla: props.Tabla ? props.Tabla : '',
        Title: props.Title ? props.Title : '',
        Tip: props.Tip ? props.Tip : '',
    });



    const {
        PomiId,
        PomiMailDelVendedor,

        PomiFechaFactura,
        PomiNumDeFactura,
        PomiNombreYApellidoDeCliente,
        PomiSucDondeSeEncuentraDisponibleLaMer,
        PomiSucDeSolicitanteDeMercaderia,
        PomiObservaciones,
        PomiObservacionesAbastecimiento,

        PomiSeConfirmaLaDistribucion,
        PomiCodigoDeEntrega,
        PomiNumeroDeDist,
        PomiNumDePedido,
        PomiCodigoDeProducto,

        PomiCantidad,
        PomiUnidadDeMedida,

        PomiIdRemito,
        PomiEnvioMail,
        Title,
    } = params;

    const saveparam = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            request.Post(props.ipserv + 'editpomiadmin/', { params }, response => {
                setLoading(false);

                if (response.success) {
                    console.log("Datos guardados exitosamente:", response);
                    Notify("Datos guardados exitosamente");
                    handleClose();
                    resolve(response);
                } else {
                    console.error("Error al guardar los datos:", response);
                    handleClose();
                    reject("Error al guardar los datos");
                }
            });
        });
    };

    const cleanparams = () => {
        setParams({
            PomiId: '',
            PomiMarcaTemporal: '',
            PomiNumDePedido: '',
            PomiMailDelVendedor: '',
            PomiMailEncargadoDeDeposito: '',
            PomiFechaFactura: '',
            PomiNumDeFactura: '',
            PomiNombreYApellidoDeCliente: '',
            PomiSucDeSolicitanteDeMercaderia: '',
            PomiSucDeSolicitant: '',
            PomiSucDondeSeEncuentraDisponibleLaMer: '',
            PomiObservaciones: '',

            PomiDescripcion: '',
            PomiUnidadDeMedida: '',
            PomiCantidad: '',

            PomiNumeroDeDist: '',
            PomiCodigoDeEntrega: '',
            PomiIdRemito: '',
            PomiEnvioMail: '',


        });
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const response = await saveparam(params);
        setLoading(false);

        if (response.success) {
            handleClose();
            setShow(false);
            await props.getParam();
        } else {
            handleClose();
            Notify('Error en el handle!')
        }
    };

    const onChangeparams = e => {
        e.preventDefault();
        setParams({
            ...params,
            [e.target.name]: e.target.value
        });
    };


    useEffect(() => {

    }, [show]);

    useEffect(() => {

    }, [params]);

    useEffect(() => {

        setParams({
            PomiId: props.PomiId ? props.PomiId : '',
            PomiMarcaTemporal: props.PomiMarcaTemporal ? props.PomiMarcaTemporal : '',
            PomiNumDePedido: props.PomiNumDePedido ? props.PomiNumDePedido : '',

            PomiMailDelVendedor: props.PomiMailDelVendedor ? props.PomiMailDelVendedor : '',
            PomiMailEncargadoDeDeposito: props.PomiMailEncargadoDeDeposito ? props.PomiMailEncargadoDeDeposito : '',

            PomiMarcaTemporal: currentTimestamp,
            PomiFechaFactura: props.PomiFechaFactura ? moment(props.PomiFechaFactura).format('YYYY-MM-DD') : '',

            PomiNumDeFactura: props.PomiNumDeFactura ? props.PomiNumDeFactura : '',
            PomiNombreYApellidoDeCliente: props.PomiNombreYApellidoDeCliente ? props.PomiNombreYApellidoDeCliente : '',
            PomiCodigoDeProducto: props.PomiCodigoDeProducto ? props.PomiCodigoDeProducto : '',
            PomiDescripcion: props.PomiDescripcion ? props.PomiDescripcion : '',
            PomiCantidad: props.PomiCantidad ? props.PomiCantidad : '',
            PomiUnidadDeMedida: props.PomiUnidadDeMedida ? props.PomiUnidadDeMedida : '',
            PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
            PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',
            PomiObservaciones: props.PomiObservaciones ? props.PomiObservaciones : '',
            PomiNumeroDeDist: props.PomiNumeroDeDist ? props.PomiNumeroDeDist : '',

            PomiObservacionesAbastecimiento: props.PomiObservacionesAbastecimiento ? props.PomiObservacionesAbastecimiento : '',
            PomiSeConfirmaLaDistribucion: props.PomiSeConfirmaLaDistribucion ? props.PomiSeConfirmaLaDistribucion : '',
            PomiIdRemito: props.PomiIdRemito ? props.PomiIdRemito : '',
            PomiCodigoDeEntrega: props.PomiCodigoDeEntrega ? props.PomiCodigoDeEntrega : '',
            PomiEnvioMail: props.PomiEnvioMail ? props.PomiEnvioMail : '',

            //  Tabla: props.Tabla ? props.Tabla : '',
            Title: props.Title ? props.Title : '',
            //  Tip: props.Tip ? props.Tip : '',

        })
    }, [props]);

    return (
        <React.Fragment>
            <Button
                className='mr-3'
                variant="primary"
                onClick={handleShow}
                style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                {Title == "Editar Registro" ?
                    <FontAwesomeIcon
                        icon={faEdit} className="mr-2" />
                    : <>
                        <FontAwesomeIcon
                            icon={faPlus}
                            className="mr-2" />
                        <span>Nuevo Registro</span></>}
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" centered size="lg">

                <Modal.Dialog style={{ maxWidth: '1000px', overflowY: 'auto', className: 'flex' }}>

                    <Form onSubmit={handlesubmit}>

                        <Modal.Header closeButton>
                            <Modal.Title> {Title}</Modal.Title>
                        </Modal.Header>               

                        <Modal.Body>
                            <Row>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>NOMBRE Y APELLIDO DEL CLIENTE</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiNombreYApellidoDeCliente"
                                            value={PomiNombreYApellidoDeCliente.toUpperCase()}
                                            required type="text"
                                            placeholder="INGRESAR NOMBRE Y APELLIDO"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>MAIL VENDEDOR</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiMailDelVendedor"
                                            value={PomiMailDelVendedor.toLowerCase()}
                                            required type="email"
                                            placeholder="mail_vendedor@uniber.com.ar" />
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>NUMERO FACTURA</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiNumDeFactura"
                                            value={PomiNumDeFactura.toUpperCase()}
                                            required type="text"
                                            placeholder="A 001-0002"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>FECHA FACTURA</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiFechaFactura"
                                            value={PomiFechaFactura}
                                            required type="date"
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>SUCURSAL DESTINO</Form.Label>

                                        <Form.Control
                                            as="select"
                                            defaultValue={PomiSucDeSolicitanteDeMercaderia}
                                            name="PomiSucDeSolicitanteDeMercaderia"
                                            onChange={onChangeparams}
                                        >
                                            <option value=''>SELECCIONE UNA SUCURSAL</option>
                                            {props.sucs.map(c => <option key={c.DES} >{c.DES}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label className="mt-2">NUMERO DE PEDIDO</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiNumDePedido"
                                            value={PomiNumDePedido}
                                            required type="number"
                                            placeholder="INGRESE UN N° DE PEDIDO"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>CODIGO</Form.Label>
                                        <Form.Control
                                            value={PomiCodigoDeProducto}
                                            onChange={onChangeparams}
                                            name="PomiCodigoDeProducto"
                                            required
                                            type="text"
                                            placeholder="CEM.LOM.013" />
                                    </Form.Group>

                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>CANTIDAD</Form.Label>
                                        <Form.Control
                                            value={PomiCantidad}
                                            onChange={onChangeparams}
                                            name="PomiCantidad"
                                            required
                                            type="text"
                                            placeholder="CANTIDADES" />
                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>UNIDAD DE MEDIDA</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={PomiUnidadDeMedida}
                                            onChange={onChangeparams}
                                            name="PomiUnidadDeMedida"
                                            required
                                            type="text"
                                        >
                                            <option value=''>SELECCIONE UN TIPO DE UNIDAD</option>
                                            {props.unidad.map(c => <option key={c.DES}>{c.DES}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label className="mt-2">OBSERVACIONES</Form.Label>
                                        <Form.Control
                                            onChange={onChangeparams}
                                            name="PomiObservaciones"
                                            value={PomiObservaciones.toUpperCase()}
                                            required type="text"
                                            placeholder="INGRESAR UNA OBSERVACION"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>NUMERO DE DISTRIBUCION</Form.Label>
                                        <Form.Control
                                            value={PomiNumeroDeDist}
                                            onChange={onChangeparams}
                                            name="PomiNumeroDeDist"
                                            required
                                            type="number"
                                        />
                                    </Form.Group>

                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>SUCURSAL ORIGEN</Form.Label>

                                        <Form.Control
                                            as="select"
                                            defaultValue={PomiSucDondeSeEncuentraDisponibleLaMer}
                                            name="PomiSucDondeSeEncuentraDisponibleLaMer"
                                            onChange={onChangeparams}
                                        >
                                            <option value=''>SELECCIONE UNA SUCURSAL</option>
                                            {props.sucs.map(c => <option key={c.DES} >{c.DES}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>OBSERVACION DE ABASTECIMIENTO</Form.Label>
                                        <Form.Control
                                            value={PomiObservacionesAbastecimiento}
                                            onChange={onChangeparams}
                                            name="PomiObservacionesAbastecimiento"
                                            required
                                            type="text"
                                        />
                                    </Form.Group>

                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group controlId="PomiSeConfirmaLaDistribucion">
                                        <Form.Label>CONFIRMAR DISTRIBUCION</Form.Label>
                                        <Form.Control
                                            as="select"
                                            required
                                            onChange={onChangeparams}
                                            name="PomiSeConfirmaLaDistribucion"
                                            value={PomiSeConfirmaLaDistribucion}
                                        >
                                            <option value=''>Seleccione una opción</option>
                                            <option value='SI'>SI</option>
                                            <option value='NO'>NO</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>VERIFICACION DEL ESTADO DE LA DISTRIBUCION CON EL SISTEMA</Form.Label>
                                        <Form.Control
                                            value={PomiEnvioMail}
                                            onChange={onChangeparams}
                                            name="PomiEnvioMail"
                                            type="text"
                                        />
                                    </Form.Group>

                                </Col>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>REMITO</Form.Label>
                                        <Form.Control
                                            value={PomiIdRemito}
                                            onChange={onChangeparams}
                                            name="PomiIdRemito"
                                            type="text"
                                        />
                                    </Form.Group>

                                </Col>

                            </Row>

                            <Row>

                                <Col xs={6} md={6}>
                                    <Form.Group className="mb-3" controlId="formDmnVal">
                                        <Form.Label>POMI</Form.Label>
                                        <Form.Control
                                            value={PomiCodigoDeEntrega}
                                            onChange={onChangeparams}
                                            name="PomiCodigoDeEntrega"
                                            type="text"
                                        />
                                    </Form.Group>

                                </Col>
                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit" style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Form>

                    <Loader loading={loading} />

                </Modal.Dialog >
            </Modal >

        </React.Fragment >
    );
}