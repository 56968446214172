import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"

export default function MiInput (props) {
    const [opciones, setOpciones] = useState([])

    const info = <OverlayTrigger
        placement="bottom"
        overlay={
            <Tooltip>
                {props.info}
            </Tooltip>
        }
    >
        <FontAwesomeIcon
            icon={faQuestionCircle}
            size='2x'
            className='cursor-pointer'
            color="blue"
        />
    </OverlayTrigger>

    useEffect(() => {
        if (props.opciones) setOpciones(props.opciones)
    }, [JSON.stringify(props.opciones)])

    return <div
        className={"full-width " + props.classContent}
        style={props.styleContent}
    >
        <span
            className={"h6 " + props.classLabel}
            style={props.styleLabel}
        >
            {props.label} 
        </span>
        <span> {props.info && info}</span>

        <div
            className={"text-caption " + props.classDes}
            style={props.styleDes}
        >
            {props.descripcion}
        </div>

        {props.child ||
            (props.as === 'select'
                ? <Form.Control
                    {...props}
                    className={props.classInput}
                >
                    {opciones.map(o => <option value={o.value}>{o.label}</option>)}
                </Form.Control>
                : <Form.Control
                    {...props}
                    className={props.classInput}
                />
            )
        }
    </div>
}