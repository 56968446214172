import React, { useState } from 'react'
import HeaderVista from "../../../compartidos/HeaderVista";
import Button from 'react-bootstrap/Button'
import ModalComponent from '../../../webcomponents/modal/modal';

export default function RepCIU2_ELS_EMP(props) {

    //--titulo del modal--
    const [titulo, setTitulo] = useState('')

    //--Modal
    const [show, setShow] = useState(false);

    //--Cerrar Modal
    const handleClose = () => {
        setShow(false)
    }

    //Abrir Modal
    const handleShow = (props1) => {
        setTitulo(props1)
        setShow(true);
    }

    var title = ''

    return (
        <>
            {
                show ?
                    <ModalComponent
                        ipserv={props.ipserv}
                        show={show}
                        titulo={titulo}
                        handleClose={handleClose}
                        suc=" 'SAMI','LAST' "
                        equipo=" 'CORRALON' "
                    >
                    </ModalComponent>
                    :
                    null
            }
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'EL SOL MATERIALES - EQUIPO EMPRESAS'} />

                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="Centro de Información Unificado - EL SOL MATERIALES"
                            width="1280"
                            height="720"
                            src="https://app.powerbi.com/view?r=eyJrIjoiOGM3YTZlMDYtYTQyZC00ODY0LThmZDktMDEwZTYwNGI5MzAwIiwidCI6ImE5YmM2NDViLThlNjMtNDZmNy1iM2YyLWM5ODE5OWJiMjRjNSJ9"
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div>
                <Button onClick={() => handleShow(title = 'Información de ventas')} variant='light' style={{ color: 'red' }}>descargar informacion de Ventas</Button>
                <Button onClick={() => handleShow(title = 'sabana de ventas')} variant='light' style={{ color: 'red', float: 'right' }}>descargar sabana de ventas</Button>
            </div>
        </>
    );
}
