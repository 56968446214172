export default [
    {
        label: 'FenixH',
        value: 'locH',
        prf: 1
    },
    {
        label: 'FenixU',
        value: 'locU',
        prf: 1
    },
    {
        label: 'FenixBI',
        value: 'locBI',
        prf: 1
    },
    {
        label: 'Supermat Casa Central',
        value: 'consupmts'
    },
    {
        label: 'Supermat Salta',
        value: 'supermatsl'
    },
    {
        label: 'Supermat Jujuy',
        value: 'supermatjy'
    },
    {
        label: 'Supermat Centro de Dist. Jujuy',
        value: 'supermatcjy'
    },
    {
        label: 'Supermat Central (SIS)',
        value: 'consolsupmt'
    },
    {
        label: 'Supermat Salta (SIS)',
        value: 'supermatSIS'
    },
    {
        label: 'Supermat Jujuy (SIS)',
        value: 'ssaySIS'
    },
    {
        label: 'Supermat Centro Dist Jujuy (SIS)',
        value: 'cdySIS'
    },
    {
        label: 'El Amigo Salta',
        value: 'eaa'
    },
    {
        label: 'El Amigo Sujuy',
        value: 'eaajuys'
    },
    {
        label: 'El Amigo Oran',
        value: 'ssaoea'
    },
    {
        label: 'El Amigo Tartagal',
        value: 'ssaeat'
    },
    {
        label: 'El Amigo Perico',
        value: 'ssaeap'
    },
    {
        label: 'El Amigo Salta (SIS)',
        value: 'eaaSIS'
    },
    {
        label: 'El Amigo Oran (SIS)',
        value: 'ssaoeaSIS'
    },
    {
        label: 'El Amigo Jujuy (SIS)',
        value: 'yeaSIS'
    },
    {
        label: 'El Amigo Tartagal (SIS)',
        value: 'eatSIS'
    },
    {
        label: 'El Amigo Perico (SIS)',
        value: 'eapSIS'
    },
    {
        label: 'Geo Casa Central',
        value: 'connection'
    },
    {
        label: 'Geo San Juan (AMP)',
        value: 'geosanjuan'
    },
    {
        label: 'Geo San Juan a modelos (AMP) 2',
        value: 'consuc'
    },
    {
        label: 'Geo Solano Vera',
        value: 'geomacosolanovera'
    },
    {
        label: 'Geo Banda del Rio Sali',
        value: 'geomacobandab'
    },
    {
        label: 'Geo Concepcion',
        value: 'geomacoconcepc'
    },
    {
        label: 'Geo Jujuy',
        value: 'geomacojuy'
    },
    {
        label: 'Geo Jujuy para los modelos (GCY) 2',
        value: 'geojuy'
    },
    {
        label: 'Geo Catamarca',
        value: 'geomacocatc'
    },
    {
        label: 'Geo Casa Central (SIS)',
        value: 'sisg'
    },
    {
        label: 'Geo San Juan (AMP) (SIS)',
        value: 'sisgc'
    },
    {
        label: 'Geo Solano Vera (SIS)',
        value: 'geomacosolanoveraSIS'
    },
    {
        label: 'Geomaco Banda del Rio Sali (SIS)',
        value: 'geomacobanda'
    },
    {
        label: 'Geomaco Concepcion (SIS)',
        value: 'geomacoconcep'
    },
    {
        label: 'Geomaco Jujuy Sistemas (SIS)',
        value: 'geomacojuySIS'
    },
    {
        label: 'Geomaco Catamarca (SIS)',
        value: 'geomacocat'
    },
    {
        label: 'Geo Turnero Sistemas (SIS)',
        value: 'geoturSys'
    },
    {
        label: 'El Sol Central (ELS)',
        value: 'solcentrals'
    },
    {
        label: 'Conexion a Sol Sucursal (SAMI)',
        value: 'consolsucsol'
    },
    {
        label: 'Sol Lastenia',
        value: 'solLastenia'
    },
    {
        label: 'El Sol Central (SIS)',
        value: 'consolcen'
    },
    {
        label: 'Sol Sami (SIS)',
        value: 'consolsuc'
    },
    {
        label: 'Sol Lastenia  (SIS)',
        value: 'lasteniaSIS'
    },
    {
        label: 'El Sol Central DES',
        value: 'soldescentral'
    },
    {
        label: 'Sol DES Sami',
        value: 'soldessucursal'
    },
    {
        label: 'Sol DES Lastenia (SIS)',
        value: 'soldesLastenia'
    },
    {
        label: 'Hipercat Central',
        value: 'hip'
    },
    {
        label: 'Hipercat Valle Viejo',
        value: 'hipvj'
    },
    {
        label: 'Hipercat Central (SIS)',
        value: 'hipSIS'
    },
    {
        label: 'Hipercat Valle Viejo  (SIS)',
        value: 'hipvjSIS'
    }
]