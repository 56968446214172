import React from "react";
import HeaderVista from "../../../compartidos/HeaderVista";

export default function RepCIU_CTRL() {
  return (
    <>
      <div className="bg-white col container">
        <div className="full-with border shadow rounded mt-4 px-3">
          <HeaderVista
            titulo={"CENTRO DE INFORMACION UNIFICADO - TABLERO DE CONTROL"}
          />

          <div className="d-flex full-width mb-3">
            <iframe
              title="Centro de Información Unificado - TABLERO DE CONTROL"
              width="1280"
              height="760"
              src="https://app.powerbi.com/view?r=eyJrIjoiNDA1MThmMTQtNDI0My00YTIxLWJiYzAtM2U2OWYyYjhiOGVjIiwidCI6ImE5YmM2NDViLThlNjMtNDZmNy1iM2YyLWM5ODE5OWJiMjRjNSJ9"
              frameborder="0"
              allowFullScreen="true"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
