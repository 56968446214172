import React, { useState, useEffect } from 'react'
import ReactExport from "react-export-excel";
import { Container, Col, Row, Button, FormControl, InputGroup, Spinner, OverlayTrigger, Tooltip, Tabs, Tab, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import request from '../../request';
import Notify from './Notify';
import sort from './sort'
import keyEnterConfirm from './keyEnterConfirm';
import resetForm from './resetForm';
import setValueForm from './setValueForm'
import copiarArray from './copiarArrayObjetos';
import nameExcel from './nameExcel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function PanelMenusSis(props) {
    const [emp, setEmp] = useState('')
    const [dataMenus, setDataMenus] = useState([])
    const [nivel_0, setNivel_0] = useState([])
    const [nivel_1, setNivel_1] = useState([])
    const [nivel_2, setNivel_2] = useState([])
    const [users, setUsers] = useState([])
    const [user, setUser] = useState('')
    const [sists, setSists] = useState([])
    const [sist, setSist] = useState('')
    const [id, setId] = useState('')
    const [loading, setLoading] = useState(false)
    const [actualizar, setActualizar] = useState(false)
    const [keySist, setKeySist] = useState('')
    const [dataExcel, setDataExcel] = useState([])
    const [all, setAll] = useState(false)

    const titulos = {
        geo: 'Geomaco',
        ssa: 'Supermat',
        els: 'El Sol',
        elsSUC: 'El Sol Sucursal',
        geoasv: 'Ampere Solano Vera',
        geoasj: 'Ampere San Juan',
        geob: 'Ampere Banda del R. S.',
        geoc: 'Ampere Concepcion',
        geojuy: 'EIE Jujuy',
        geocat: 'Ampere Catamarca',
        ssaeaa: 'El Amigo Salta',
        ssaeaaj: 'El Amigo Jujuy',
        hipvj: 'Valle Viejo',
        hip: 'Hipercat',
        
        //Yrigoyen
        ssadsc: 'Supermat Atahualpa des Central',
        ssaasa: 'Supermat Atahualpa',
        ssaada: 'Supermat Ataualpa Des',
        asaSIS: 'Supermat ASA Sistemas',
        adaSIS: 'Supermat ADA Sistemas',

        //SSA HITORICO
        Hssa: 'Supermat Historico',
        Haz: 'Supermat Salta',
        Hy: 'Supermat Jujuy',
        Hasa: 'Supermat Atahualpa',
        Heaa: 'El amigo Salta',
        Hyea: 'El amigo Jujuy',
        Hoea: 'El amigo Oran',
        Heap: 'El amigo Perico',
        Heat: 'El amigo Tartagal',
        Hcdy: 'Centro de distribución Jujuy',

        //SSA SIS HISTORICO
        HssaSIS: 'Supermat Historico',
        HeaaSIS: 'Supermat Salta Historico',
        HyeaSIS: 'HyeaSIS',
        HySIS: 'HySIS',
        HoeaSIS: 'HoeaSIS',
        HeapSIS: 'HeapSIS',
        HeatSIS: 'HeatSIS',
        HasaSIS: 'HasaSIS',
        HazSIS: 'HazSIS',
        HcdySIS: 'HcdySIS', 



    }

    const iconMenu = <FontAwesomeIcon icon={faSortDown} color="black" />

    const getUrl = url => props.ipserv + url + '/'

    const getUsers = () => {
        const url = getUrl('getusuariossis')

        request.Get(url, { emp }, r => {
            const u = r.rows.map(item => item.COD)

            setUsers(u)
        })
    }

    const getSist = () => {
        const url = getUrl('getsis')

        request.Get(url, { emp }, r => {
            const s = r.rows.sort((a, b) => sort.numAsc(a, b, 'ID'))

            setSists(s)
        })
    }

    const getData = (all = false) => {
        if ((!user || !sist) && !all) {
            Notify({ type: 'completar' })
            return
        }

        if (all && !user) {
            Notify('Seleccione un usuario')
            return
        }

        setLoading(true)
        setAll(all)

        if (all) {
            setSist('')
            resetForm('#form-sis-sist')
        }

        const url = getUrl('getmenussis')

        request.Get(url, { user, sist, all, emp }, r => {
            const rows = copiarArray(r.rows)

            setLoading(false)
            setActualizar(!all)
            setDataMenus(rows)

            generarDataMenus(r.rows.filter(item => item.NOM0))
        })
    }

    const generarDataMenus = m => {
        if (m.length === 0) {
            Notify({ type: 'busqueda_fail' })
            setNivel_0([])
            setNivel_1([])
            setNivel_2([])
            return
        }

        let n2 = []
        let n1 = []
        let n0 = []

        let idsNivel_2 = []
        let idsNivel_1 = []
        let idsNivel_0 = []

        m.forEach(item => {
            if (item.ID2 && !idsNivel_2.includes(item.ID2)) {
                idsNivel_2.push(item.ID2)

                n2.push({
                    id: item.ID2,
                    idPadre: item.ID1,
                    idSis: item.SIS_ID,
                    nombre: item.NOM2,
                    orden: item.ORDEN2
                })
            }

            if (!idsNivel_1.includes(item.ID1)) {
                idsNivel_1.push(item.ID1)

                n1.push({
                    id: item.ID1,
                    idPadre: item.ID0,
                    idSis: item.SIS_ID,
                    nombre: item.NOM1,
                    check: true,
                    checkCH: item.ID2 ? true : false,
                    orden: item.ORDEN1
                })
            }

            if (!idsNivel_0.includes(item.ID0)) {
                idsNivel_0.push(item.ID0)

                n0.push({
                    id: item.ID0,
                    nombre: item.NOM0,
                    idSis: item.SIS_ID,
                    check: true,
                    checkCH: true,
                    orden: item.ORDEN0
                })
            }
        })

        n0.sort((a, b) => sort.numAsc(a, b, 'orden'))
        n1.sort((a, b) => sort.numAsc(a, b, 'orden'))
        n2.sort((a, b) => sort.numAsc(a, b, 'orden'))

        setNivel_0(n0)
        setNivel_1(n1)
        setNivel_2(n2)

        generarDataExcel(n0, n1, n2, m)
    }

    const updateKeySist = () => {
        const k = sistActivos().length > 0 ? sistActivos()[0].ID : ''

        setKeySist(k)
    }

    const filtrarId = () => {
        const Ids = id.split(',')
            .filter(item => item.trim() && parseInt(item))
            .map(item => parseInt(item))

        const m = dataMenus
            .filter(item =>
                !id ||
                Ids.includes(item.ID2) ||
                Ids.includes(item.ID1) ||
                Ids.includes(item.ID0)
            )

        m.length === 0
            ? Notify({ type: 'busqueda_fail' })
            : generarDataMenus(m)

        setValueForm('#form-sis-id', id)
    }

    const hideHijos = (nivel, item) => {
        const p = {
            nivel_0,
            nivel_1
        }

        const s = {
            setNivel_0,
            setNivel_1
        }

        if (!item.checkCH) return

        let array = p[nivel].slice(0)
        const i = array.findIndex(a => a.id === item.id)
        array[i] = {
            ...item,
            check: !item.check
        }

        const set = 'set' + nivel[0].toUpperCase() + nivel.slice(1)

        s[set](array)
    }

    const getMenuPadre = (padre, idPadre) => {
        const p = {
            nivel_0,
            nivel_1
        }

        const i = p[padre].findIndex(item => item.id === idPadre)

        return i < 0
            ? ''
            : p[padre][i].id + ' - ' + p[padre][i].nombre
    }

    const changeValue = e => {
        const s = {
            setUser,
            setSist,
            setId
        }

        if (e.target.name !== 'Id') setActualizar(false)

        const set = 'set' + e.target.name
        const value = e.target.value

        s[set](value)
    }

    const restablecerMenus = () => {
        resetForm('#form-sis-id')
        setId('')

        const data = copiarArray(dataMenus)
        generarDataMenus(data)

        setTimeout(() => {
            const r = document.getElementById('restablecer-menu')
            r.classList.toggle('rotar')

            if (!r.className.baseVal.includes('rotar')) {
                setTimeout(() => {
                    r.classList.toggle('rotar')
                })
            }
        })
    }

    const listItems = (nivel, idSist, padre) => {
        return filtrarPorSist(nivel, idSist)
            .slice(0)
            .sort((a, b) => sort.numAsc(a, b, 'id'))
            .map(n =>
                <div key={n.id} className='full-width border py-2 pl-1'>
                    <span className='text-primary fw-bold'>{n.id}</span> - {n.nombre} {n.idPadre && <span className='text-negative'>[{getMenuPadre(padre, n.idPadre)}]</span>}
                </div>
            )
    }

    const sistActivos = () => sists.filter(s => nivel_0.findIndex(n => n.idSis === s.ID) >= 0)

    const filtrarPorSist = (items, idSist) => items.filter(item => item.idSis === idSist)

    const generarDataExcel = (n0, n1, n2, data) => {
        let ids0 = n0.map(n => n.id)
        let ids1 = n1.map(n => n.id)
        let ids2 = n2.map(n => n.id)

        const ids = ids0
            .concat(ids1)
            .concat(ids2)

        let m = []

        data.filter(item =>
            ids.includes(item.ID2) ||
            ids.includes(item.ID1) ||
            ids.includes(item.ID0)
        ).slice(0)
            .forEach(item => m.push({ ...item }))

        m.sort((a, b) => sort.numAsc(a, b, 'SIS_ID'))
        m.sort((a, b) => a.SIS_ID === b.SIS_ID ? sort.numAsc(a, b, 'ORDEN0') : 0)
        m.sort((a, b) => a.ID0 === b.ID0 ? sort.numAsc(a, b, 'ORDEN1') : 0)
        m.sort((a, b) => a.ID1 === b.ID1 ? sort.numAsc(a, b, 'ORDEN2') : 0)

        ids0 = []
        ids1 = []

        m.forEach(item => {
            ids0.includes(item.ID0)
                ? item.NOM0 = ''
                : ids0.push(item.ID0)

            ids1.includes(item.ID1)
                ? item.NOM1 = ''
                : ids1.push(item.ID1)
        })

        setDataExcel(m)
    }

    useEffect(() => {
        if (emp && props.emp !== emp) {
            setDataMenus([])
            setUsers([])
            setUser('')
            setSists([])
            setSist('')
            setId('')
            setNivel_0([])
            setNivel_1([])
            setNivel_2([])
            setLoading(false)
            setActualizar(false)

            resetForm('#form-sis-id')
            resetForm('#form-sis-sist')
            resetForm('#form-sis-iser')
        }
    }, [props.emp])

    useEffect(() => {
        if (props.emp && props.ipserv.length > 0) setEmp(props.emp)
    }, [props.emp, props.ipserv])

    useEffect(() => {
        if (emp) {
            getUsers()
            getSist()
        }
    }, [emp])

    useEffect(() => {
        updateKeySist()
    }, [JSON.stringify(sistActivos())])

    return (
        <Container fluid className="col" style={{ backgroundColor: '#FFFFFF' }}>
            <Col className="bg-white mt-3 border shadow rounded ">
                <h5 className="border rounded mt-3 text-white header-vistas">Consulta: Menu Sistema por Usuario - {emp && titulos[emp]}</h5>

                <div className='d-flex mb-2'>
                    <InputGroup className="small-width mr-3 ml-3">
                        <InputGroup.Text>
                            {sists.length === 0
                                ? <Spinner animation="border" size="sm" />
                                : 'Sistema'
                            }
                        </InputGroup.Text>

                        <FormControl
                            id="form-sis-sist"
                            defaultValue=''
                            as="select"
                            variant="warning"
                            name="Sist"
                            onChange={changeValue}
                            disabled={sists.length === 0}
                        >
                            <option value="">Seleccione un Sistema</option>
                            {sists.map(s =>
                                <option key={s.ID} value={s.ID}>
                                    {s.ID} - {s.DES}
                                </option>
                            )}
                        </FormControl>
                    </InputGroup>

                    <InputGroup className="small-width mr-3">
                        <InputGroup.Text>
                            {users.length === 0
                                ? <Spinner animation="border" size="sm" />
                                : 'Usuario'
                            }
                        </InputGroup.Text>

                        <FormControl
                            id="form-sis-user"
                            defaultValue=''
                            as="select"
                            variant="warning"
                            name="User"
                            onChange={changeValue}
                            disabled={users.length === 0}
                        >
                            <option value="">Seleccione un Usuario</option>
                            {users.map(u =>
                                <option key={u} value={u}>
                                    {u}
                                </option>
                            )}
                        </FormControl>
                    </InputGroup>

                    <Button className='mr-3' onClick={() => getData()}>{actualizar ? 'Actualizar' : 'Consultar'}</Button>

                    <Button className='mr-3 text-white' variant="warning" onClick={() => getData(true)}>Arbol Completo de Usuario</Button>

                    <ExcelFile
                        filename={nameExcel("Informe de Menus Habilitados por Usuario " + user + (all ? '' : ' y Sistema ' + sist))}
                        element={<Button disabled={nivel_0.length === 0} variant="success">Descargar Excel</Button>}
                    >
                        {sistActivos().map(s =>
                            <ExcelSheet data={dataExcel.filter(data => data.SIS_ID === s.ID)} name={s.DES}>
                                <ExcelColumn label="Id Nivel 0" value="ID0" />
                                <ExcelColumn label="Nivel 0" value="NOM0" />
                                <ExcelColumn label="Id Nivel 1" value="ID1" />
                                <ExcelColumn label="Nivel 1" value="NOM1" />
                                <ExcelColumn label="Id Nivel 2" value="ID2" />
                                <ExcelColumn label="Nivel 2" value="NOM2" />
                            </ExcelSheet>
                        )}
                    </ExcelFile>
                </div>

                <div className='mb-3 ml-3'>
                    <Form.Text className="text-muted">
                        * Para Consultar por Sistema y Usuario seleccione ambas opciones y haga click en el Botón "Consultar"
                    </Form.Text>
                    <Form.Text className="text-muted">
                        * Para Consultar por Usuario seleccione el usuario y haga click en el Botón "Arbol Completo de Usuario"
                    </Form.Text>
                    <Form.Text className="text-muted">
                        * Una vez Filtrada la Información, podrá Descargar la misma haciendo Clic en el Botón "Descargar Excel", el mismo contendra la informacion vista en pantalla con cada sistema en una hoja diferente
                    </Form.Text>
                </div>

                {loading
                    ? <div className='full-width d-flex justify-content-center my-5'>
                        <Spinner animation="border" />
                    </div>
                    : <Tabs
                        activeKey={keySist}
                        onSelect={setKeySist}
                        transition={false}
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        {sistActivos().map(s =>
                            <Tab key={s.ID} eventKey={s.ID} title={s.DES}>
                                <div className='d-flex mt-3'>
                                    <div className='col-4'>
                                        {dataMenus.length === 0 ||
                                            <>
                                                <div className='d-flex full-width align-items-center'>
                                                    <FormControl
                                                        id='form-sis-id'
                                                        className='mr-3 small-width'
                                                        type="text"
                                                        name="Id"
                                                        placeholder="Filtrar por Id"
                                                        onChange={changeValue}
                                                        onKeyUp={e => keyEnterConfirm(e, filtrarId)}
                                                    />

                                                    <Button onClick={filtrarId}>Filtrar</Button>

                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip>
                                                                Restablecer
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            id="restablecer-menu"
                                                            icon={faRedoAlt}
                                                            size="2x"
                                                            style={{ zIndex: 500 }}
                                                            className='ml-auto opacity-8 text-negative cursor-pointer'
                                                            onClick={restablecerMenus}
                                                        />
                                                    </OverlayTrigger>
                                                </div>
                                                <div className='text-muted'>
                                                    * Es posible buscar por más de un id si se escriben separados por comas.
                                                </div>
                                            </>
                                        }

                                        <ul className="wtree mt-4">
                                            {filtrarPorSist(nivel_0, s.ID).map(n0 =>
                                                <li key={n0.id}>
                                                    <span
                                                        className={'cursor-pointer ' + (!n0.check && 'close-menu')}
                                                        onClick={() => hideHijos('nivel_0', n0)}
                                                    >
                                                        {iconMenu} {n0.id} - {n0.nombre}
                                                    </span>
                                                    <ul>
                                                        {n0.check && filtrarPorSist(nivel_1, s.ID)
                                                            .filter(n1 => n1.idPadre === n0.id)
                                                            .map(n1 =>
                                                                <li key={n1.id}>
                                                                    <span
                                                                        className={(n1.checkCH && 'cursor-pointer ') + (!n1.check && 'close-menu')}
                                                                        onClick={() => hideHijos('nivel_1', n1)}
                                                                    >
                                                                        {n1.checkCH && iconMenu} {n1.id} - {n1.nombre}
                                                                    </span>
                                                                    <ul>
                                                                        {n1.checkCH && n1.check && filtrarPorSist(nivel_2, s.ID)
                                                                            .filter(n2 => n2.idPadre === n1.id)
                                                                            .map(n2 =>
                                                                                <li key={n2.id}>
                                                                                    <span>
                                                                                        {n2.id} - {n2.nombre}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                    </ul>
                                                                </li>
                                                            )}
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </div>

                                    <div className='col-8'>
                                        <Row hidden={nivel_0.length === 0}>
                                            <Col className='mx-1 d-flex flex-column'>
                                                <div className='full-width text-center bg-amber fw-bold border'>
                                                    Nivel 0
                                                </div>

                                                {listItems(nivel_0, s.ID)}
                                            </Col>
                                            <Col className='mx-1 d-flex flex-column'>
                                                <div className='full-width text-center bg-amber fw-bold border'>
                                                    Nivel 1
                                                </div>

                                                {listItems(nivel_1, s.ID, 'nivel_0')}
                                            </Col>
                                            <Col className='mx-1 d-flex flex-column'>
                                                <div className='full-width text-center bg-amber fw-bold border'>
                                                    Nivel 2
                                                </div>

                                                {listItems(nivel_2, s.ID, 'nivel_1')}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Tab>
                        )}
                    </Tabs>
                }
            </Col>
        </Container>
    )
}