import React, { useState, useEffect } from 'react'
import { InputGroup, FormControl, Row, Col, Spinner, Button, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import request from '../../../request'
import Notify from '../../compartidos/Notify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faTimes, faCheck, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import sort from '../../compartidos/sort'

export default function PanelPerfiles (props) {
    const [dataMenus, setDataMenus] = useState(null)
    const [perfiles, setPerfiles] = useState([])
    const [perfil, setPerfil] = useState({})

    const [items, setItems] = useState([])
    const [subMenus, setSubMenus] = useState([])
    const [menus, setMenus] = useState([])
    const [empresas, setEmpresas] = useState([])
    const [negocios, setNegocios] = useState([])
    const [forceMapa, setForceMapa] = useState(1)

    const [loadingPerfiles, setLoadingPerfiles] = useState(true)
    const [loadingMenus, setLoadingMenus] = useState(true)
    const [loadingCrearEditar, setLoadingCrearEditar] = useState(false)
    const [loadingUsuarios, setLoadingUsuarios] = useState(true)
    const [loadingAsignar, setLoadingAsignar] = useState(false)
    const [loadingCopiar, setLoadingCopiar] = useState(false)

    const [crear, setCrear] = useState(false)
    const [newPerfil, setNewPerfil] = useState('')

    const [editar, setEditar] = useState(false)

    const [usuarios, setUsuarios] = useState([])
    const [user, setUser] = useState('')

    const [userOrigen, setUserOrigen] = useState('')
    const [userDestino, setUserDestino] = useState('')

    const [modalAsignar, setModalAsignar] = useState(false)
    const [modalCopiar, setModalCopiar] = useState(false)

    const getDataMenus = () => {
        request.Get(props.ipserv + 'getMenus/', {}, r => {
            if (!r.success) {
                Notify({ type: 'error' })
            } else {
                setDataMenus(r)
                setLoadingMenus(false)
            }
        })
    }

    const getPerfiles = () =>{
        request.Get(props.ipserv + 'getPerfiles/', {}, r => {
            if (!r.success) {
                Notify({ type: 'error' })
            } else {
                setPerfiles(r.data.sort((a, b) => sort.alfAsc(a, b, 'PrfNom')))
                setLoadingPerfiles(false)
            }
        })
    }

    const getUsuarios = () => {
        request.Get(props.ipserv + 'getusers/', {}, r => {
            r.users.sort((a, b) => {
                const A = a.UsrApel + ' ' + a.UsrNom
                const B = b.UsrApel + ' ' + b.UsrNom

                return sort.alfAsc(A, B)
            })

            setUsuarios(r.users.map(u => {
                return {
                    label: u.UsrApel + ' ' + u.UsrNom,
                    value: u.UsrId
                }
            }))
            setLoadingUsuarios(false)
        })
    }

    const generarDatosMapa = (id, check = true, vaciar = false, edit = false) => {
        if (vaciar) {
            setItems([])
            setSubMenus([])
            setMenus([])
            setEmpresas([])
            setNegocios([])

            return
        }

        let items = []
        let itemsIds = []
        let itemsIdsOriginales = []

        if (edit) {
            items = dataMenus.itemmenu.filter(item => {
                if (item.PrfId === id && !itemsIds.includes(item.IMenuId)) {
                    itemsIds.push(item.IMenuId)
                    itemsIdsOriginales.push(item.IMenuId)
                    return true
                } else {
                    return false
                }
            })
        }

        dataMenus.itemmenu.forEach(item => {
            if ((item.PrfId === id || !id) && !itemsIds.includes(item.IMenuId)) {
                items.push(item)
                itemsIds.push(item.IMenuId)
            } else if (edit && !itemsIds.includes(item.IMenuId)) {
                items.push(item)
                itemsIds.push(item.IMenuId)
            }
        })

        let subMenusIds = []
        let subMenusIdsOriginales = []

        items.forEach(item => {
            item.check = edit
                ? item.PrfId === id
                : check

            item.checkOriginal = edit && item.PrfId === id

            if (!subMenusIds.includes(item.SubMenuId)) subMenusIds.push(item.SubMenuId)

            if (item.checkOriginal) subMenusIdsOriginales.push(item.SubMenuId)
        })

        const subMenus = dataMenus.submenu.filter(s => subMenusIds.includes(s.SubMenuId))
        let menusIds = []
        let menusIdsOriginales = []

        subMenus.forEach(s => {
            if (!edit) {
                s.check = check
                s.checkOriginal = false
            } else {
                const i = items.findIndex(item => item.check && item.SubMenuId === s.SubMenuId)
                s.check = i >= 0
                s.checkOriginal = subMenusIdsOriginales.includes(s.SubMenuId)
            }

            if (!menusIds.includes(s.MenuId)) menusIds.push(s.MenuId)

            if (s.checkOriginal) menusIdsOriginales.push(s.MenuId)
        })

        const menus = dataMenus.datamenu.filter(m => menusIds.includes(m.MenuId))
        let emps = []
        let empsOriginales = []

        menus.forEach(m => {
            if (!edit) {
                m.check = check
                m.checkOriginal = false
            } else {
                const i = subMenus.findIndex(s => s.check && s.MenuId === m.MenuId)
                m.check = i >= 0
                m.checkOriginal = menusIdsOriginales.includes(m.MenuId)
            }

            if (!emps.includes(m.DmnAbr)) emps.push(m.DmnAbr)

            if (m.checkOriginal) empsOriginales.push(m.DmnAbr)
        })

        const empresas = dataMenus.menuemp.filter(m => emps.includes(m.DmnAbr))
        let negs = []
        let negsOriginales = []

        empresas.forEach(e => {
            if (!edit) {
                e.check = check
                e.checkOriginal = false
            } else {
                const i = menus.findIndex(m => m.check && m.DmnAbr === e.DmnAbr)
                e.check = i >= 0
                e.checkOriginal = empsOriginales.includes(e.DmnAbr)
            }

            if (!negs.includes(e.MenuNeg)) negs.push(e.MenuNeg)

            if (e.checkOriginal) negsOriginales.push(e.MenuNeg)
        })

        const negocios = dataMenus.menuneg.filter(m => negs.includes(m.MenuNeg))

        negocios.forEach(n => {
            if (!edit) {
                n.check = check
                n.checkOriginal = false
            } else {
                const i = empresas.findIndex(m => m.check && m.MenuNeg === n.MenuNeg)
                n.check = i >= 0
                n.checkOriginal = negsOriginales.includes(n.MenuNeg)
            }
        })

        setItems(items)
        setSubMenus(subMenus)
        setMenus(menus)
        setEmpresas(empresas)
        setNegocios(negocios)
    }

    const changePerfil = e => {
        if (!e.target.value) {
            setPerfil({
                label: '',
                value: ''
            })
            generarDatosMapa(undefined, undefined, true)
        } else {
            const id = parseInt(e.target.value)
            const p = perfiles.find(item => item.PrfId === id)

            generarDatosMapa(id)

            setPerfil({
                label: p.PrfNom,
                value: p.PrfId
            })
        }
    }

    const getMenuPadre = (padre, key, keyNom, id) => {
        if (!id) return ''

        const p = {
            subMenus,
            menus,
            empresas,
            negocios
        }

        const i = p[padre].findIndex(f => f[key] === id)

        if (i < 0) return ''

        return p[padre][i][keyNom]
    }

    const hideHijos = (padre, item) => {
        const p = {
            subMenus,
            menus,
            empresas,
            negocios,
            items
        }

        const s = {
            setSubMenus,
            setMenus,
            setEmpresas,
            setNegocios,
            setItems
        }

        let aux = p[padre].slice(0)
        const keys = Object.keys(item)
        let i

        aux.forEach((a, index) => {
            let iguales = true

            keys.forEach(k => {
                if (a[k] !== item[k]) iguales = false
            })

            if (iguales) i = index
        })

        if (aux[i].checkOriginal) {
            Notify('No es posible eliminar items')
            return
        }

        aux[i].check = !aux[i].check
        
        const set = 'set' + padre[0].toUpperCase() + padre.slice(1)

        s[set](aux)

        if (crear || editar) checkItems(padre, item)
    }

    const checkItems = (padre, item) => {
        let aux
        let auxS
        let auxM
        let auxE

        let idsM = []
        let idsS = []

        switch (padre) {
            case 'negocios':
                const emp = empresas.filter(e => item.MenuNeg === e.MenuNeg).map(e => e.DmnAbr)
                
                aux = items.slice(0)
                aux.forEach(a => {
                    const e = a.IMenuEmp === 'ELA' ? 'SUP' : a.IMenuEmp

                    if (emp.includes(e)) {
                        a.check = a.checkOriginal
                        idsM.push(a.MenuId)
                        idsS.push(a.SubMenuId)
                    }
                })

                auxM = menus.slice(0)
                auxM.forEach(am => {
                    if (idsM.includes(am.MenuId)) am.check = am.checkOriginal
                })

                auxS = subMenus.slice(0)
                auxS.forEach(as => {
                    if (idsS.includes(as.SubMenuId)) as.check = as.checkOriginal
                })

                auxE = empresas.slice(0)
                auxE.forEach(ae => {
                    if (emp.includes(ae.DmnAbr)) ae.check = ae.checkOriginal
                })

                setItems(aux)
                setEmpresas(auxE)
                setMenus(auxM)
                setSubMenus(auxS)
                break;

            case 'empresas':
                aux = items.slice(0)
                aux.forEach(a => {
                    const e = a.IMenuEmp === 'ELA' ? 'SUP' : a.IMenuEmp

                    if (e === item.DmnAbr) {
                        a.check = a.checkOriginal
                        idsM.push(a.MenuId)
                        idsS.push(a.SubMenuId)
                    }
                })

                auxM = menus.slice(0)
                auxM.forEach(am => {
                    if (idsM.includes(am.MenuId)) am.check = am.checkOriginal
                })

                auxS = subMenus.slice(0)
                auxS.forEach(as => {
                    if (idsS.includes(as.SubMenuId)) as.check = as.checkOriginal
                })

                setItems(aux)
                setMenus(auxM)
                setSubMenus(auxS)
                break;

            case 'menus':
                aux = items.slice(0)
                aux.forEach(a => {
                    if (a.MenuId === item.MenuId) {
                        a.check = a.checkOriginal
                        idsS.push(a.SubMenuId)
                    }
                })

                auxS = subMenus.slice(0)
                auxS.forEach(as => {
                    if (idsS.includes(as.SubMenuId)) as.check = as.checkOriginal
                })

                setItems(aux)
                setSubMenus(auxS)
                break;

            case 'subMenus':
                aux = items.slice(0)
                aux.forEach(a => {
                    if (a.SubMenuId === item.SubMenuId) a.check = a.checkOriginal
                })

                setItems(aux)
                break;
        
            default:
                break;
        }
    }

    const habilitarCrearPerfil = () => {
        setCrear(true)

        generarDatosMapa('', false)
    }

    const cancelarCrearPerfil = () => {
        setCrear(false)
        setNewPerfil('')

        generarDatosMapa(perfil.value)
    }

    const crearPerfil = () => {
        const idsItems = items.filter(item => item.check).map(item => item.IMenuId)

        if (!newPerfil) {
            Notify('Debe ingresar un nombre para el perfil')
            return
        }

        if (idsItems.length === 0) {
            Notify('La tabla "items" debe contener al menos un elemento')
            return
        }

        const coincidencias = perfiles.filter(p => p.PrfNom.toLowerCase() === newPerfil.toLowerCase())

        if (coincidencias.length > 0) {
            Notify('El nombre del perfil ya se encuentra en uso')
            return
        }

        setLoadingCrearEditar(true)

        request.Post(props.ipserv + 'newperfil/', { nombre: newPerfil, items: idsItems }, r => {
            if (!r.success) {
                Notify({ type: 'error' })
                setLoadingCrearEditar(false)
            } else {
                const p = {
                    PrfId: r.id,
                    PrfNom: newPerfil
                }

                let aux = perfiles.slice(0)
                aux.push(p)
                aux.sort((a, b) => sort.alfAsc(a, b, 'PrfNom'))

                let itemmenu = dataMenus.itemmenu.slice(0)

                items
                    .filter(item => item.check)
                    .forEach(item => {
                        let a = item

                        delete a.check

                        itemmenu.push({
                            ...a,
                            PrfId: p.PrfId
                        })
                    })

                setDataMenus({
                    ...dataMenus,
                    itemmenu: itemmenu
                })

                Notify({ type: 'exito' })

                document.getElementById("form-select-perfil").value = p.PrfId

                setPerfil({
                    label: p.PrfNom,
                    value: p.PrfId
                })
                setPerfiles(aux)
                setCrear(false)
                setNewPerfil('')
                setLoadingCrearEditar(false)
                setForceMapa(r => r + 1)
            }
        })
    }

    const showModalAsignar = () => {
        if (!perfil.label) {
            Notify('Debe seleccionar un perfil antes de asignarlo a un usuario')
            return
        }

        setModalAsignar(true)
    }

    const cancelarAsignar = () => {
        setModalAsignar(false)
        setUser('')
    }

    const asignarPerfil = () => {
        if (!user) {
            Notify('Debe elegir un usuario para continuar')
            return
        }

        setLoadingAsignar(true)

        request.Post(props.ipserv + 'asignarperfil/', { user, perfil: perfil.value }, r => {
            if (!r.success) {
                Notify({ type: 'error' })
                setLoadingAsignar(false)
            } else {
                setLoadingAsignar(false)
                setUser('')
                setModalAsignar(false)
                Notify({ type: 'exito' })
            }
        })
    }

    const showModalCopiar = () => {
        setModalCopiar(true)
    }

    const usuarioTienePerfil = UsrId => {
        if (dataMenus === null || !UsrId) {
            return false
        }

        const i = dataMenus.itemmenu.findIndex(item => parseInt(item.UsrId) === parseInt(UsrId))

        return i >= 0
    }

    const cancelarCopiar = () => {
        setUserOrigen('')
        setUserDestino('')
        setModalCopiar(false)
    }

    const copiarUsuario = () => {
        if (userOrigen && userDestino && userOrigen === userDestino) {
            Notify('No es posible copiar un usuario hacia si mismo')
            return
        }

        setLoadingCopiar(true)

        request.Post(props.ipserv + 'copiarusuario/', { userOrigen, userDestino }, r => {
            if (!r.success) {
                Notify({ type: 'error' })
                setLoadingCopiar(false)
            } else {
                Notify({ type: 'exito' })

                setLoadingCopiar(false)
                setModalCopiar(false)
                setUserOrigen('')
                setUserDestino('')
            }
        })
    }

    const restablecerMenus = e => {
        e.stopPropagation()

        negocios.forEach(item => {
            checkItems('negocios', item)
            item.check = item.checkOriginal
        })
    }

    const habilitarEditarPerfil = () => {
        const id = parseInt(perfil.value)

        setEditar(true)

        generarDatosMapa(id, true, false, true)
    }

    const filtroTabla = m => {
        return m.filter(item => (crear && item.check || !crear) && (editar && item.check || !editar))
    }

    const condRestablecer = () => {
        return !editar
            ? negocios.filter(item => crear && item.check).length !== 0
            : negocios.filter(item => !item.checkOriginal && item.check).length !== 0 ||
            empresas.filter(item => !item.checkOriginal && item.check).length !== 0 ||
            menus.filter(item => !item.checkOriginal && item.check).length !== 0 ||
            subMenus.filter(item => !item.checkOriginal && item.check).length !== 0 ||
            items.filter(item => !item.checkOriginal && item.check).length !== 0
    }

    const cancelarModificarPerfil = () => {
        generarDatosMapa(perfil.value)
        setEditar(false)
    }

    const modificarPerfil = () => {
        const newItems = items.filter(item => item.check && !item.checkOriginal)
        const idsItems = newItems.map(item => item.IMenuId)
        
        if (idsItems.length === 0) {
            Notify('No se han creado items nuevos')
            return
        }

        setLoadingCrearEditar(true)

        request.Post(props.ipserv + 'additemprf/', { PrfId: perfil.value, idsItems }, r => {
            if (!r.success) {
                Notify({ type: 'error' })
                setEditar(false)
                setLoadingCrearEditar(false)
            } else {
                let itemmenu = dataMenus.itemmenu.slice(0)

                newItems.forEach(item => {
                        let a = item

                        delete a.check
                        delete a.checkOriginal

                        itemmenu.push({
                            ...a,
                            PrfId: perfil.value
                        })
                    })

                Notify({ type: 'exito' })

                setDataMenus({
                    ...dataMenus,
                    itemmenu
                })
                setEditar(false)
                setLoadingCrearEditar(false)
                setForceMapa(r => r + 1)
            }
        })
    }

    const iconCheckItem = item => {
        return (crear || editar) &&
            <FontAwesomeIcon
                className={
                    'select-menu ' +
                    newItemColor(item)
                }
                icon={
                    item.check
                        ? faCheck
                        : faTimes
                }
                color={
                    item.check
                        ? 'green'
                        : 'red'
                }
            />
    }

    const iconMenu = <FontAwesomeIcon icon={faSortDown} color="black" />

    const newItemColor = item => {
        return editar &&
            item.check &&
            !item.checkOriginal &&
            'text-info'
    }

    useEffect(() => {
        if (!loadingPerfiles && !loadingMenus) {
            generarDatosMapa(perfil.value)
        }
    }, [forceMapa])

    useEffect(() => {
        if (props.ipserv.length > 0) {
            getDataMenus()
            getPerfiles()
            getUsuarios()
        }
    }, [props.ipserv])

    useEffect(() => {
        if (!loadingPerfiles && !loadingMenus) {
            const id = props.perfil ? props.perfil.id : perfiles[0].PrfId
            const nom = props.perfil ? props.perfil.nom : perfiles[0].PrfNom

            document.getElementById("form-select-perfil").value = id

            generarDatosMapa(id)

            setPerfil({
                label: nom,
                value: id
            })
        }
    }, [loadingPerfiles, loadingMenus])

    return <>
        <div className='full-width p-3 d-flex'>
            <InputGroup hidden={crear} className="small-width">
                <InputGroup.Text>Perfil</InputGroup.Text>
                <FormControl
                    id="form-select-perfil"
                    defaultValue=''
                    as="select"
                    variant="warning"
                    onChange={changePerfil}
                    disabled={loadingPerfiles || loadingMenus || editar}
                >

                    <option value="">Seleccione un tipo de Perfil</option>
                    {perfiles.map(p => {
                        return (
                            <option key={p.PrfId} value={p.PrfId}>
                                {p.PrfNom}
                            </option>
                        );
                    })}
                </FormControl>
            </InputGroup>

            {crear || editar
                ? <>
                    <InputGroup hidden={editar} className="small-width">
                        <FormControl
                            type="text"
                            placeholder='Nombre del Perfil'
                            disabled={loadingCrearEditar}
                            onChange={e => setNewPerfil(e.target.value)}
                        />
                    </InputGroup>

                    <Button
                        disabled={loadingCrearEditar}
                        variant="primary"
                        className="ml-3"
                        onClick={crear ? crearPerfil : modificarPerfil}
                    >
                        {crear ? 'Crear' : 'Modificar'}
                    </Button>
                    <Button
                        disabled={loadingCrearEditar}
                        variant="danger"
                        className="ml-3"
                        onClick={crear ? cancelarCrearPerfil : cancelarModificarPerfil}
                    >
                        Cancelar
                    </Button>
                </>
                : <> 
                    <Button
                        disabled={loadingPerfiles || loadingMenus}
                        variant="primary"
                        className="ml-3"
                        onClick={habilitarCrearPerfil}
                    >
                        Crear Nuevo Perfil
                    </Button>
                    <Button
                        disabled={loadingPerfiles || loadingMenus || loadingUsuarios || !perfil.label}
                        variant="info"
                        className="ml-3"
                        onClick={showModalAsignar}
                    >
                        Asignar Perfil Actual
                    </Button>
                    <Button
                        disabled={loadingPerfiles || loadingMenus || loadingUsuarios || !perfil.label}
                        variant="danger"
                        className="ml-3"
                        onClick={habilitarEditarPerfil}
                    >
                        Modificar Perfil Actual
                    </Button>
                    <Button
                        disabled={loadingPerfiles || loadingMenus || loadingUsuarios}
                        variant="warning"
                        className="ml-3 text-white"
                        onClick={showModalCopiar}
                    >
                        Copiar Usuario
                    </Button>
                </>
            }
        </div>

        <div className={"text-muted ml-4 " + (!crear && 'visibility-hidden')}>
            * Seleccione los menus e items a los que podra acceder el perfil.
        </div>

        {loadingMenus || loadingPerfiles
            ? <div className='full-width full-height d-flex justify-content-center align-items-center'>
                <Spinner animation="border" />
            </div>
            : <div
                className='row d-flex mx-0 pt-2 relative-position'
                style={{
                    overflow: 'overlay',
                    height: '76vh'
                }}
            >
                {loadingCrearEditar &&
                    <div
                        className='full-width absolute-top d-flex justify-content-center align-items-center'
                        style={{
                            height: '76vh',
                            pointerEvents: 'all',
                            opacity: 0.5,
                            background: 'white',
                            zIndex: 3000
                        }}
                    >
                        <Spinner animation="border" />
                    </div>
                }

                <div className='col-12 col-md-4 relative-position'>
                    {condRestablecer() &&
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>
                                    Restablecer
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon
                                icon={faRedoAlt}
                                size="2x"
                                style={{ zIndex: 500 }}
                                className='absolute-top-right mt-4 mr-5 opacity-5 text-info cursor-pointer'
                                onClick={restablecerMenus}
                            />
                        </OverlayTrigger>
                    }

                    <ul className="wtree">
                        {negocios.map(n =>
                            <li key={n.MenuNeg}>
                                <span
                                    className={'cursor-pointer ' + (!n.check && 'close-menu')}
                                    onClick={() => hideHijos('negocios', n)}
                                >
                                    { iconMenu } { n.DmnNom } { iconCheckItem(n) }
                                </span>
                                <ul>
                                    {n.check && empresas
                                    .filter(e => n.MenuNeg === e.MenuNeg)
                                    .map(e =>
                                        <li key={e.DmnAbr}>
                                            <span
                                                className={'cursor-pointer ' + (!e.check && 'close-menu')}
                                                onClick={() => hideHijos('empresas', e)}
                                            >
                                                { iconMenu } { e.DmnNom } { iconCheckItem(e) }
                                            </span>
                                            <ul>
                                                {e.check && menus
                                                .filter(m => m.DmnAbr === e.DmnAbr)
                                                .map(m =>
                                                    <li key={m.MenuId}>
                                                        <span
                                                            className={'cursor-pointer ' + (!m.check && 'close-menu')}
                                                            onClick={() => hideHijos('menus', m)}
                                                        >
                                                            { iconMenu } { m.MenuNom } { iconCheckItem(m) }
                                                        </span>
                                                        <ul>
                                                            {m.check && subMenus
                                                            .filter(s => s.MenuId === m.MenuId)
                                                            .map(s =>
                                                                <li key={s.SubMenuId}>
                                                                    <span
                                                                        className={'cursor-pointer ' + (!s.check && 'close-menu')}
                                                                        onClick={() => hideHijos('subMenus', s)}
                                                                    >
                                                                        { iconMenu } { s.SubMenuNom }  { iconCheckItem(s) }
                                                                    </span>
                                                                    <ul>
                                                                        {s.check && items
                                                                        .filter(item => s.SubMenuId === item.SubMenuId)
                                                                        .map(item =>
                                                                            <li key={item.IMenuId}>
                                                                                <span
                                                                                    className={(crear || editar) && 'cursor-pointer'}
                                                                                    onClick={() => hideHijos('items', item)}
                                                                                >
                                                                                    { item.IMenuId } - { item.IMenuNom }  { iconCheckItem(item) }
                                                                                </span>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>

                <div className='col-12 col-md-8'>
                    <Row>
                        <Col className='mx-1 d-flex flex-column'>
                            <div className='full-width text-center bg-amber fw-bold border'>
                                Negocios
                            </div>

                            {filtroTabla(negocios).map(n =>
                                <div key={n.MenuNeg} className={'full-width border py-2 pl-1 ' + newItemColor(n)}>
                                    { n.DmnNom }
                                </div>
                            )}
                        </Col>
                        <Col className='mx-1 d-flex flex-column'>
                            <div className='full-width text-center bg-amber fw-bold border'>
                                Empresas
                            </div>

                            {filtroTabla(empresas).map(e =>
                                <div key={e.DmnAbr} className={'full-width border py-2 pl-1 ' + newItemColor(e)}>
                                    { e.DmnNom }  ({getMenuPadre('negocios', 'MenuNeg', 'DmnNom', e.MenuNeg)})
                                </div>
                            )}
                        </Col>
                        <Col className='mx-1 d-flex flex-column'>
                            <div className='full-width text-center bg-amber fw-bold border'>
                                Menus
                            </div>

                            {filtroTabla(menus).map(m =>
                                <div key={m.MenuId} className={'full-width border py-2 pl-1 ' + newItemColor(m)}>
                                    { m.MenuNom } ({getMenuPadre('empresas', 'DmnAbr', 'DmnNom', m.DmnAbr)})
                                </div>
                            )}
                        </Col>
                        <Col className='mx-1 d-flex flex-column'>
                            <div className='full-width text-center bg-amber fw-bold border'>
                                SubMenus
                            </div>

                            {filtroTabla(subMenus).map(s =>
                                <div key={s.SubMenuId} className={'full-width border py-2 pl-1 ' + newItemColor(s)}>
                                    { s.SubMenuNom } ({getMenuPadre('menus', 'MenuId', 'MenuNom', s.MenuId)})
                                </div>
                            )}
                        </Col>
                        <Col className='mx-1 d-flex flex-column'>
                            <div className='full-width text-center bg-amber fw-bold border'>
                                Items
                            </div>

                            {filtroTabla(items).map(item =>
                                <div key={item.IMenuId} className={'full-width border py-2 pl-1 ' + newItemColor(item)}>
                                    { item.IMenuId } - { item.IMenuNom } ({getMenuPadre('subMenus', 'SubMenuId', 'SubMenuNom', item.SubMenuId)})
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        }
        
        <Modal show={modalAsignar} onHide={cancelarAsignar} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'><h5>Asignar Perfil</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body className="text-no-desborde">
                Se asignara el perfil <span className='fw-bold'>{perfil.label}</span>

                <InputGroup className="full-width mt-3">
                    <Form.Control
                        defaultValue=''
                        as="select"
                        onChange={e => setUser(e.target.value)}
                    >
                        <option value="">Seleccione un Usuario</option>
                        {usuarios.map(u =>
                            <option key={u.value} value={u.value}>{u.label}</option>
                        )}
                    </Form.Control>
                </InputGroup>

                <div className='full-width d-flex justify-content-center mt-3'>
                    {loadingAsignar
                        ? <Spinner animation="border" />
                        : <>
                            <Button
                                disabled={loadingCrearEditar}
                                variant="info"
                                onClick={asignarPerfil}
                            >
                                Asignar
                            </Button>
                            <Button
                                disabled={loadingCrearEditar}
                                variant="danger"
                                className="ml-3"
                                onClick={cancelarAsignar}
                            >
                                Cancelar
                            </Button>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
        
        <Modal show={modalCopiar} onHide={cancelarCopiar} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'><h5>Copiar Usuario</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body className="text-no-desborde">
                Seleccione el usuario de <span className='fw-bold'>Origen</span> para copiar sus perfiles al usuario de <span className='fw-bold'>Destino</span> seleccionado.
                
                <div className='full-width d-flex mt-4 mb-4 relative-position'>
                    <div className='col-6 pl-0 pr-3'>
                        <h6 className='fw-bold'>Usuario Origen</h6>

                        <InputGroup className="full-width mt-2">
                            <Form.Control
                                defaultValue=''
                                as="select"
                                onChange={e => setUserOrigen(e.target.value)}
                            >
                                <option value="">Seleccione un Usuario</option>
                                {usuarios.map(u =>
                                    <option key={u.value} value={u.value}>{u.label}</option>
                                )}
                            </Form.Control>
                        </InputGroup>

                        {userOrigen && !usuarioTienePerfil(userOrigen) &&
                            <div className='absolute-bottom full-width text-mini fw-bold' style={{marginBottom: -17}}>
                                <span className='text-negative'>El usuario no tiene un perfil asociado.</span>
                            </div>
                        }
                    </div>

                    <div className='col-6 pl-2 pr-1'>
                        <h6 className='fw-bold'>Usuario Destino</h6>

                        <InputGroup className="full-width mt-2">
                            <Form.Control
                                defaultValue=''
                                as="select"
                                onChange={e => setUserDestino(e.target.value)}
                            >
                                <option value="">Seleccione un Usuario</option>
                                {usuarios.map(u =>
                                    <option key={u.value} value={u.value}>{u.label}</option>
                                )}
                            </Form.Control>
                        </InputGroup>

                        {usuarioTienePerfil(userDestino) &&
                            <div className='absolute-bottom full-width text-mini fw-bold' style={{ marginBottom: -17 }}>
                                <span className='text-grey'>El usuario ya tiene un perfil asociado.</span>
                            </div>
                        }
                    </div>
                </div>

                <div className='full-width d-flex justify-content-center mt-3'>
                    {loadingCopiar
                        ? <Spinner animation="border" />
                        : <>
                            <Button
                                disabled={!userOrigen || !userDestino || !usuarioTienePerfil(userOrigen)}
                                variant="warning text-white"
                                onClick={copiarUsuario}
                            >
                                Copiar
                            </Button>
                            <Button
                                variant="danger"
                                className="ml-3"
                                onClick={cancelarCopiar}
                            >
                                Cancelar
                            </Button>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    </>
}