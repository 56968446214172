import React from 'react'
import HeaderVista from "../../compartidos/HeaderVista";

export default function RepFi_SSA() {
    return (
        <>
            <div className="bg-white col container">
                <div className="full-with border shadow rounded mt-4 px-3">
                    <HeaderVista titulo={'Tablero SUPERMAT - El Amigo, Supermat e Yrigoyen'} />

                    <div className="d-flex full-width mb-3">
                        <iframe
                            title="Tablero SUPERMAT"
                            width="1400"
                            height="760"
                            src="https://app.powerbi.com/view?r=eyJrIjoiY2UzNWVmMWEtNmYyYy00MDZjLTg5ZDYtMmJkNWUyMjljZjg2IiwidCI6ImE5YmM2NDViLThlNjMtNDZmNy1iM2YyLWM5ODE5OWJiMjRjNSJ9&pageName=ReportSection"
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
