import React, { useState, useEffect } from 'react';
import {
    Container,
    Col,
    Form,
    Table,
    Button,
    Row,
} from 'react-bootstrap';
import request from '../../../request';
import Notify from '../../../components/compartidos/Notify';
import Loader from '../../../components/compartidos/Loader';
import EditarParam from './EditPomiDist';
import HeaderVista from '../../compartidos/HeaderVista';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Paginacion from '../../../components/compartidos/Paginacion';
import DescargarExcel from '../../../components/compartidos/DescargarExcel';
import nameExcel from '../../../components/compartidos/nameExcel';


export default function PomiDist(props) {

    const fixedButtonsStyle = {
        position: 'fixed',
        top: '5%',
        right: '50%',
        transform: 'translate(50%, -50%)',
        width: '100%',
        zIndex: 999,

    };

    const [isExpanded, setIsExpanded] = useState(false);

    const [visibleColumns, setVisibleColumns] = useState({
        unidad: true,
        cantidad: true,
        item: true,
        mailVendedor: true,
        observaciones: true,
        observacionesAbastecimiento: true
    });


    const [loading, setLoading] = useState(false);
    const [datasat, setDataSat] = useState([]);

    const [filteredData, setFilteredData] = useState([]);

    const [distribucion, setDistribucion] = useState([])
    const [estNumDist, setEstNumDist] = useState([])

    const [selectedRows, setSelectedRows] = useState([]);
    const [buttonsFixed, setButtonsFixed] = useState(false);

    const [sucs, setSucs] = useState([]);
    const [unidad, setUnidad] = useState([]);

    const [tokenExcel, setTokenExcel] = useState('');

    const [page, setPage] = useState(1)
    const [datasatPage, setDataSatPage] = useState([]);
    const rows = 50

    const [params, setParams] = useState({

        PomiId: props.PomiId ? props.PomiId : '',
        PomiMarcaTemporal: props.PomiMarcaTemporal ? props.PomiMarcaTemporal : '',
        PomiNumDePedido: props.PomiNumDePedido ? props.PomiNumDePedido : '',

        PomiMailDelVendedor: props.PomiMailDelVendedor ? props.PomiMailDelVendedor : '',
        PomiFechaFactura: rows.PomiFechaFactura ? moment(rows.PomiFechaFactura).utc().format('YYYY-MM-DD HH:MM:SS') : '',
        PomiNumDeFactura: props.PomiNumDeFactura ? props.PomiNumDeFactura : '',
        PomiNombreYApellidoDeCliente: props.PomiNombreYApellidoDeCliente ? props.PomiNombreYApellidoDeCliente : '',
        PomiCodigoDeProducto: props.PomiCodigoDeProducto ? props.PomiCodigoDeProducto : '',
        PomiCantidad: props.PomiCantidad ? props.PomiCantidad : '',
        PomiUnidadDeMedida: props.PomiUnidadDeMedida ? props.PomiUnidadDeMedida : '',
        PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
        PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',
        PomiObservaciones: props.PomiObservaciones ? props.PomiObservaciones : '',
        PomiNumeroDeDist: props.PomiNumeroDeDist ? props.PomiNumeroDeDist : '',
        PomiObservacionesAbastecimiento: props.PomiObservacionesAbastecimiento ? props.PomiObservacionesAbastecimiento : '',
        PomiSeConfirmaLaDistribucion: props.PomiSeConfirmaLaDistribucion ? props.PomiSeConfirmaLaDistribucion : '',
        PomiEnvioMail: props.PomiEnvioMail ? props.PomiEnvioMail : '',
        PomiIdRemito: props.PomiIdRemito ? props.PomiIdRemito : '',
        PomiCodigoDeEntrega: props.PomiCodigoDeEntrega ? props.PomiCodigoDeEntrega : '',

        Tabla: props.Tabla ? props.Tabla : '',
        Title: props.Title ? props.Title : '',
        Tip: props.Tip ? props.Tip : '',
    });


    const toggleTable = () => {
        const areAllColumnsVisible = Object.values(visibleColumns).every(column => column);
        setVisibleColumns({
            unidad: !areAllColumnsVisible,
            cantidad: !areAllColumnsVisible,
            mailVendedor: !areAllColumnsVisible,
            observaciones: !areAllColumnsVisible,
            observacionesAbastecimiento: !areAllColumnsVisible
        });

        setIsExpanded(!isExpanded);
    };

    const getParam = async () => {
        try {
            setLoading(true);
            setDataSat([]);
            setDataSatPage([]);
            request.Get(props.ipserv + 'getpomidist/', { tokenExcel }, r => {
                setLoading(false);
                if (r.success) {
                    setDataSat(r.rows);
                    setTokenExcel(r.tokenExcel)
                    setPage(1);
                } else {
                    Notify('No se encontraron datos');
                }
            });
        } catch (error) {
            setLoading(false);
            throw new Error('Error al procesar la solicitud: ' + error.message);
        }
    };

    const getsucursales = async () => {
        request.Get(props.ipserv + 'getsucursales/', {}, r => {
            if (r.success) {
                setSucs(r.rows)
            } else {
                Notify('No Se encontraron Datos de Sucursales')
            }
        })
    }


    const getdistribucion = async () => {
        request.Get(props.ipserv + 'getdistribucion/', {}, r => {
            if (r.success) {
                setDistribucion(r.rows)
            } else {
                Notify('No Se encontraron Datos de Sucursales')
            }
        })
    }
    const handleSelecDist = (event) => {
        const selectDistribucion = event.target.value;
        let filteredData = [];
        if (selectDistribucion === '') {
            filteredData = datasat.slice(0, rows);
        } else {
            filteredData = datasat.filter(item => item.PomiNumeroDeDist && item.PomiNumeroDeDist.toString() === selectDistribucion.toString());
            console.log('filteredData', filteredData);
        }
        setFilteredData(filteredData);
        setDataSatPage(filteredData.slice(0, rows));
    };


    const getestadodist = async () => {
        request.Get(props.ipserv + 'getestadodist/', {}, r => {
            if (r.success) {
                setEstNumDist(r.rows)
            } else {
                Notify('No Se encontraron Datos de Sucursales')
            }
        })
    }


    const handleSelecEstadoDist = (event) => {
        const selectEstadoDist = event.target.value;
        let filteredData = [];
        if (selectEstadoDist === '') {
            filteredData = datasat.slice(0, rows);
        } else {
            filteredData = datasat.filter(item => item.PomiEnvioMail && item.PomiEnvioMail === selectEstadoDist);
        }
        setDataSatPage(filteredData.slice(0, rows));
    };

    const handleScroll = () => {
        if (window.pageYOffset > 200) {
            setButtonsFixed(true);
        } else {
            setButtonsFixed(false);
        }
    };

    const onChangePage = (list, p) => {
        setDataSatPage(list)
        setPage(p)
    }

    const clearSelectedRows = () => {
        setSelectedRows("");
    };

    const handleCheckboxChange = (e, PomiId) => {
        if (e.target.checked) {
            setSelectedRows(prevSelectedRows => [...prevSelectedRows, PomiId]);
        } else {
            setSelectedRows(prevSelectedRows => prevSelectedRows.filter(id => id !== PomiId));
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if (props.ipserv.length > 0) {
            getParam();
        }
    }, [props.ipserv])

    useEffect(() => {
        getsucursales();
        // getdistribucion();
        getestadodist();
    }, [datasat])

    useEffect(() => {
        getParam();
        getdistribucion();
    }, []);


    useEffect(() => {

    }, [filteredData, datasatPage]);


    useEffect(() => {

    }, [datasatPage])


    return (
        <Container fluid className="col" style={{ backgroundColor: '#FFFFFF' }}>
            <Col className="bg-white mt-4 border shadow rounded ">

                <HeaderVista titulo={'Distribución de mercadería'} />

                <Form>
                    <div className="border p-3" style={{ display: 'flex' }}>


                        <div style={{

                            ...buttonsFixed ? { ...fixedButtonsStyle, width: '100vw', maxWidth: '550px', background: ' linear-gradient(rgb(151,151,209) 20%, rgba(255, 255, 255, 0.8))' } : { width: 'auto', maxWidth: 'auto' }
                        }}>



                            <EditarParam
                                handleCheckboxChange={handleCheckboxChange}
                                props={{
                                    ipserv: props.ipserv,
                                    Tip: 'Edit',
                                    Title: 'ENVIAR EMAIL',
                                    Tabla: 'ri_pomi',
                                    PomiId: rows.PomiId,
                                    PomiMarcaTemporal: rows.PomiMarcaTemporal,
                                    PomiNumDePedido: rows.PomiNumDePedido,
                                    PomiMailDelVendedor: rows.PomiMailDelVendedor,
                                    PomiFechaFactura: rows.PomiFechaFactura ? moment(rows.PomiFechaFactura).utc().format('YYYY-MM-DD HH:MM:SS') : '',
                                    PomiNumDeFactura: rows.PomiNumDeFactura,
                                    PomiNombreYApellidoDeCliente: rows.PomiNombreYApellidoDeCliente,
                                    PomiCodigoDeProducto: rows.PomiCodigoDeProducto,
                                    PomiCantidad: rows.PomiCantidad,
                                    PomiUnidadDeMedida: rows.PomiUnidadDeMedida,
                                    PomiSucDeSolicitanteDeMercaderia: rows.PomiSucDeSolicitanteDeMercaderia,
                                    PomiSucDondeSeEncuentraDisponibleLaMer: rows.PomiSucDondeSeEncuentraDisponibleLaMer,
                                    PomiObservaciones: rows.PomiObservaciones,
                                    PomiNumeroDeDist: rows.PomiNumeroDeDist,
                                    PomiObservacionesAbastecimiento: rows.PomiObservacionesAbastecimiento,
                                    PomiSeConfirmaLaDistribucion: rows.PomiSeConfirmaLaDistribucion,
                                    PomiEnvioMail: rows.PomiEnvioMail,
                                    PomiIdRemito: rows.PomiIdRemito,
                                    PomiCodigoDeEntrega: rows.PomiCodigoDeEntrega,
                                    Rows: rows,
                                    sucs,
                                    unidad,
                                    estNumDist,
                                    distribucion,
                                    //numPedido,
                                    selectedRows: selectedRows,
                                    getParam: getParam,
                                    clearSelectedRows: clearSelectedRows
                                }}
                            />

                            <Button variant="primary" onClick={getParam} style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                                <FontAwesomeIcon icon={faArrowAltCircleRight} className="m-1" /> <span>Recargar Listado</span>
                            </Button>


                            <Button className='ml-3' onClick={toggleTable} style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                                {isExpanded ? (
                                    <>
                                        <FontAwesomeIcon icon={faPlusCircle} className="m-1" />
                                        <span>Expandir tabla</span>
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faMinusCircle} className="m-1" />
                                        <span>Contraer tabla</span>
                                    </>
                                )}
                            </Button>

                        </div>


                        <Row className="mb-3 justify-content-between align-items-center">

                            <Col md={4} className="d-flex align-items-center">
                                <DescargarExcel
                                    style={{ background: 'linear-gradient(250deg, background-image: radial-gradient(circle at 50% 50%, #3d9d17 0, #3d9d17 50%, #3d9d17 100%);', }}
                                    className='mt-3'
                                    disabled={datasat.length === 0}
                                    route="getpomi"
                                    ipserv={props.ipserv}
                                    tokenExcel={tokenExcel}
                                    nombreExcel={nameExcel("getpomi")}
                                />
                            </Col>

                            <Col md={4}>
                                <Form.Text className="text-muted ml-2">
                                    * FILTRAR POR EL ESTADO DE LA DISTRIBUCION
                                </Form.Text>
                                <Form.Control className='mt-3' as="select" onChange={handleSelecEstadoDist}>
                                    <option value=''>Seleccione un estado</option>
                                    {Array.from(new Set(datasat.map(item => item.PomiEnvioMail)))
                                        .filter(estNumDist => estNumDist !== null && estNumDist !== '')
                                        .map((estNumDist, index) => (
                                            <option key={`${estNumDist}-${index}`} value={estNumDist}>{estNumDist}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Col>

                            {/* Selector de Número de Distribución */}
                            <Col md={4}>
                                <Form.Text className="text-muted ml-2">
                                    * FILTRAR POR NUMERO DE DISTRIBUCION
                                </Form.Text>
                                <Form.Control className='mt-3' as="select" onChange={handleSelecDist}>
                                    <option value=''>Seleccione un numero de distribucion</option>
                                    {Array.from(new Set(datasat.map(item => item.PomiNumeroDeDist)))
                                        .filter(distribucion => distribucion !== null && distribucion !== '')
                                        .map((distribucion, index) => (
                                            <option key={`${distribucion}-${index}`} value={distribucion}>{distribucion}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Col>

                        </Row>


                    </div>

                    <Loader loading={loading} />
                    {datasatPage && datasatPage.length > 0 ?
                        <Table className='mt-3 ' id='DataBI' striped bordered hover>

                            <thead  >
                                <tr>
                                    <th></th>
                                    <th>MARCA_TEMPORAL</th>
                                    <th>NUMERO DE PEDIDO</th>
                                    {visibleColumns.mailVendedor && <th>MAIL DEL VENDEDOR</th>}
                                    <th>FECHA FACTURA</th>
                                    <th>NUMERO DE FACTURA</th>
                                    <th>NOMBRE Y APELLIDO DEL CLIENTE</th>
                                    <th>CODIGO DE PRODUCTO</th>
                                    {visibleColumns.cantidad && <th>CANTIDAD</th>}
                                    {visibleColumns.unidad && <th>UNIDAD DE MEDIDA</th>}
                                    {visibleColumns.item && <th>N° Item</th>}
                                    <th>SUCURSAL DESTINO</th>
                                    <th>SUCURSAL ORIGEN</th>
                                    {visibleColumns.observaciones && <th>OBSERVACIONES</th>} {/* Utilizar la visibilidad de la columna de observaciones */}
                                    <th>NUMERO DE DISTRIBUCION</th>
                                    {visibleColumns.observacionesAbastecimiento && <th>OBSERVACIONES ABASTECIMIENTO</th>}
                                    <th>SE CONFIRMO LA DISTRIBUCION ?</th>
                                    <th>VERIFICACION DEL ESTADO DE LA DISTRIBUCION CON EL SISTEMA</th>
                                    <th>REMITO</th>
                                    <th>POMI</th>
                                </tr>
                            </thead>

                            <tbody >
                                {datasatPage && datasatPage.length > 0 ? datasatPage.map(rows =>
                                    <React.Fragment key={rows.PomiId}>

                                        <tr key={rows.PomiId}>
                                            <td>

                                                <Form.Check
                                                    onChange={(e) => handleCheckboxChange(e, rows.PomiId, rows.PomiNumDePedido)}
                                                    type="checkbox"
                                                    //     id={`checkbox-${rows.PomiId}`}
                                                    id={`default-radio`}
                                                //checked={selectedRows.some(row => row.PomiId === rows.PomiId)}
                                                />
                                            </td>

                                            <td>{rows.PomiMarcaTemporal}</td>
                                            <td>{rows.PomiNumDePedido}</td>
                                            {visibleColumns.mailVendedor && <td>{rows.PomiMailDelVendedor}</td>}
                                            <td>{rows.PomiFechaFactura}</td>
                                            <td>{rows.PomiNumDeFactura}</td>
                                            <td>{rows.PomiNombreYApellidoDeCliente}</td>
                                            <td>{rows.PomiCodigoDeProducto}</td>
                                            {visibleColumns.cantidad && <td>{rows.PomiCantidad}</td>}
                                            {visibleColumns.unidad && <td>{rows.PomiUnidadDeMedida}</td>}
                                            {visibleColumns.item && <td>{rows.PomiItem}</td>}
                                            <td>{rows.PomiSucDeSolicitanteDeMercaderia}</td>
                                            <td>{rows.PomiSucDondeSeEncuentraDisponibleLaMer}</td>
                                            {visibleColumns.observaciones && <td>{rows.PomiObservaciones}</td>}
                                            <td>{rows.PomiNumeroDeDist}</td>
                                            {visibleColumns.observacionesAbastecimiento && <td>{rows.PomiObservacionesAbastecimiento}</td>}
                                            <td>{rows.PomiSeConfirmaLaDistribucion}</td>
                                            <th style={{
                                                backgroundColor:
                                                    rows.PomiEnvioMail === 'CUMPLIDO' ? '#30e059' :
                                                        rows.PomiEnvioMail === 'CANCELADO' ? '#ff0000' :
                                                            rows.PomiEnvioMail === 'PENDIENTE' ? '#ffcc00' :
                                                                '',
                                                color:
                                                    rows.PomiEnvioMail === 'CANCELADO' ? 'white' :
                                                        ''
                                            }}>
                                                {rows.PomiEnvioMail}
                                            </th>



                                            <th>  {rows.PomiIdRemito}       </th>
                                            <th>  {rows.PomiCodigoDeEntrega}       </th>


                                        </tr >
                                    </React.Fragment>
                                ) : ""}
                            </tbody>
                        </Table>
                        : ""}
                    {datasat.length > 0 &&
                        <div className='full-width d-flex justify-content-center my-2'>
                            <Paginacion
                                page={page}
                                rows={rows}
                                data={datasat}
                                onChangePage={onChangePage}
                            />
                        </div>
                    }
                </Form>
            </Col>
        </Container >
    )
}