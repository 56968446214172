import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Calendar from 'react-calendar'
import { Container, Spinner } from "react-bootstrap";
import Notify from "../../compartidos/Notify";
import Modal from 'react-bootstrap/Modal'
import download from 'downloadjs';
import moment from "moment";
import request from '../../../request';
import 'react-calendar/dist/Calendar.css';

const ModalComponent = (props) => {

    const [downloading, setDownloading] = useState(false)

    const [fchdsd, onChangeFchdsd] = useState(new Date())
    const [fchhst, onChangeFchhst] = useState(new Date())

    let suc = props.suc
    let equipo = props.equipo

    let fileName = props.titulo === 'Información de ventas' ? `Valores de Ventas ${(moment(fchdsd).format('YYYY-MM-DD'))} a ${(moment(fchhst).format('YYYY-MM-DD'))}` : `Ventas detalladas ${(moment(fchdsd).format('YYYY-MM-DD'))} a ${(moment(fchhst).format('YYYY-MM-DD'))}`

    const handleClickExcel = (props2) => {

        setDownloading(true)

        request.Post(props.ipserv + `${props2}`, { fchdsd: (moment(fchdsd).format('YYYY-MM-DD')), fchhst: (moment(fchhst).format('YYYY-MM-DD')), suc: suc, equipo: equipo }, (req) => {

            download(req, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")

            Notify('Reporte generado!')

            setDownloading(false)

        }, 'blob')
    }

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid className="col mt-4">
                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: 'auto', marginTop: '10px', padding: '5px', justifyContent: 'spaceAround', width: '100%', height: 'auto' }}>
                            <div style={{ padding: '5px' }} >
                                <div style={{ display: 'flex', flexWrap: 'wrap', padding: '2px' }}>
                                    <h6>Período Desde:</h6>
                                </div>
                                <Calendar
                                    value={fchdsd}
                                    onChange={onChangeFchdsd}
                                    style={{ marginTop: '10px', borderRadius: '10px', padding: '5px', height: 'auto', minWidth: '70px' }} />
                            </div>
                            <div style={{ padding: '5px' }} >
                                <h6>Período Hasta:</h6>
                                <Calendar
                                    value={fchhst}
                                    onChange={onChangeFchhst}
                                    style={{ marginTop: '10px', borderRadius: '10px', padding: '5px', height: 'auto' }} />
                            </div>
                        </div>
                    </Container>
                    {
                        downloading ?
                            <>
                            <div style={{display:'flex'}}>
                                <h4>Descargando, aguarde unos minutos...</h4>
                                <Spinner animation="border" size="md" variant="primary" style={{ marginLeft:'4px'}} />
                                {/*<ProgressBar animated label={progress} now={progress}></ProgressBar>*/}
                            </div>
                            </>
                            :
                            null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleClickExcel(props.titulo === 'Información de ventas' ? 'DescargarCiu1' : 'DescargarSabana')} variant="success">Descargar</Button>
                    {/*<Button onClick={() => descargarArchivo()}>Descargar</Button>*/}
                    <Button onClick={() => props.handleClose()} variant="danger">Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>)
}

export default ModalComponent;