import React, { useEffect, useState } from "react";
import { Container, Col, Tabs, Tab, Fade, Button } from "react-bootstrap";
import request from "../../../request";
import '../../compartidos/clases_utiles.css'
import Notify from "../../compartidos/Notify";
import useData from "../../hooks/useData";
import Inputs from "./Panel/Inputs";
import Datos from "./Panel/Datos"
import PropsQuery from "./Panel/PropsQuery";
import Loader from "../../compartidos/Loader";

export default function PanelQuery (props) {
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState('query')
    const [data, setData, resetData] = useData({
        link: '',
        titulo: '',
        conexion: '',
        tipo: 'NORM',
        fields: '',
        tablas: '',
        where: '',
        orderby: '',
        groupby: '',
        limit: '',
        free: '',
    })

    const guardar = params => {
        setLoading(true)

        const dataSend = { ...data }
        dataSend.free = data.free.replace(new RegExp(/\n/, 'g'), " ")

        request.Post(props.ipserv + 'guardarQuery/', { ...dataSend, params }, r => {
            setLoading(true)
            resetData()
            setTab('query')
            Notify({ type: 'exito' })
        })
    }

    const irTabConexion = () => {
        const t = data.tipo

        if (t === 'NORM' && (!data.fields || !data.tablas) || t === 'DROP' && !data.free) {
            Notify({ type: 'requeridos' })
            return
        }

        setTab('datos')
    }

    const irTabProps = () => {
        if (!data.conexion || !data.link || !data.titulo) {
            Notify({ type: 'completar' })
            return
        }

        setTab('props')
    }

    useEffect(() => resetData({ tipo: data.tipo }), [data.tipo])

    return <Container>
        <Col className="bg-white pb-3 my-4 border shadow rounded full-width">
            <Loader loading={loading}>
                <Tabs
                    activeKey={tab}
                    transition={Fade}
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    <Tab eventKey="query" className='mx-auto relative-position' style={{ maxWidth: 1300 }}>
                        <Inputs
                            data={data}
                            setData={setData}
                        />

                        <div className="full-width flex justify-center">
                            <Button onClick={irTabConexion}>
                                Siguiente
                            </Button>
                        </div>
                    </Tab>
                    
                    <Tab eventKey="datos" className='mx-auto' style={{ maxWidth: 1300 }}>
                        <Datos
                            ipserv={props.ipserv}
                            data={data}
                            setData={setData}
                        />

                        <div className="full-width d-flex justify-content-center">
                            <Button className="mr-3" variant="danger" onClick={() => setTab('query')}>
                                Atras
                            </Button>

                            <Button onClick={irTabProps}>
                                Siguiente
                            </Button>
                        </div>
                    </Tab>
                    
                    <Tab eventKey="props" className='mx-auto' style={{ maxWidth: 1300 }}>
                        <PropsQuery
                            atras={() => setTab('datos')}
                            guardar={guardar}
                        />
                    </Tab>
                </Tabs>
            </Loader>
        </Col>
    </Container>
}
