import React from 'react'
import { Link } from 'react-router-dom'
import '../../compartidos/clases_utiles.css'

export default function ItemMenu (props) {
    const handlerClick = () => {
        props.onClose()
        props.logNav('/', ' HOME')
    }

    return <Link
    to={props.to}
    onClick={handlerClick}
    className="pr-3 pl-3 mb-1 pb-1 pt-1 text-left dropdown-principal text-caption align-items-center ml-4"
>
    <div className="mx-1 d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        </svg>
    </div>
    {props.label}
</Link>
}