import React, { useState, useEffect } from 'react';
import {
    Container,
    Col,
    Form,
    Table,
    Button,
    Row,
} from 'react-bootstrap';

import request from '../../../request';
import Notify from '../../../components/compartidos/Notify';
import Loader from '../../../components/compartidos/Loader';
import EditarParam from './EditPomi';
import HeaderVista from '../../compartidos/HeaderVista';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Paginacion from '../../../components/compartidos/Paginacion';

export default function Pomi(props) {

    const [loading, setLoading] = useState(false);
    const [datasat, setDataSat] = useState([]);

    const [acciones, setAcciones] = useState([]);

    const [sucs, setSucs] = useState([]);
    const [unidad, setUnidad] = useState([])
    const [codigo, setCodigo] = useState([])

    const [page, setPage] = useState(1)
    const [datasatPage, setDataSatPage] = useState([]);
    const rows = 50

    const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');

    const [params, setParams] = useState({


        PomiId: props.PomiId ? props.PomiId : '',
        PomiMarcaTemporal: props.PomiMarcaTemporal ? props.PomiMarcaTemporal : '',
        PomiNumDePedido: props.PomiNumDePedido ? props.PomiNumDePedido : '',

        PomiMailDelVendedor: props.PomiMailDelVendedor ? props.PomiMailDelVendedor : '',
        PomiMailEncargadoDeDeposito: props.PomiMailEncargadoDeDeposito ? props.PomiMailEncargadoDeDeposito : '',

        PomiMarcaTemporal: currentTimestamp,
        PomiFechaFactura: rows.PomiFechaFactura ? moment(rows.PomiFechaFactura).utc().format('YYYY-MM-DD HH:MM:SS') : '',

        PomiNumDeFactura: props.PomiNumDeFactura ? props.PomiNumDeFactura : '',
        PomiNombreYApellidoDeCliente: props.PomiNombreYApellidoDeCliente ? props.PomiNombreYApellidoDeCliente : '',
        PomiCodigoDeProducto: props.PomiCodigoDeProducto ? props.PomiCodigoDeProducto : '',

        PomiCantidad: props.PomiCantidad ? props.PomiCantidad : '',
        PomiUnidadDeMedida: props.PomiUnidadDeMedida ? props.PomiUnidadDeMedida : '',
        PomiSucDeSolicitanteDeMercaderia: props.PomiSucDeSolicitanteDeMercaderia ? props.PomiSucDeSolicitanteDeMercaderia : '',
        PomiSucDondeSeEncuentraDisponibleLaMer: props.PomiSucDondeSeEncuentraDisponibleLaMer ? props.PomiSucDondeSeEncuentraDisponibleLaMer : '',
        PomiObservaciones: props.PomiObservaciones ? props.PomiObservaciones : '',
        PomiNumeroDeDist: props.PomiNumeroDeDist ? props.PomiNumeroDeDist : '',

        Tabla: props.Tabla ? props.Tabla : '',
        Title: props.Title ? props.Title : '',
        Tip: props.Tip ? props.Tip : '',
    });

    const getParam = async () => {
        setDataSat([])
        setDataSatPage([])
        request.Get(props.ipserv + 'getpomidist/', {}, r => {
            if (r.success) {
                setDataSat(r.rows);
                setPage(1)
               // console.log(r.rows)
            } else {
                Notify('No Se encontraron Datos')
            }
        })
    }

    const getsucursales = async () => {
        request.Get(props.ipserv + 'getsucursales/', {}, r => {
            if (r.success) {
                setSucs(r.rows)
               // console.log("sucursales")
            } else {
                Notify('No Se encontraron Datos de Sucursales')
            }
        })
    }

    const getunidad = async () => {
        request.Get(props.ipserv + 'getunidad/', {}, r => {
            if (r.success) {
                setUnidad(r.rows);
            } else {
                Notify('No Se encontraron Unidades')
            }
        })
    }
/* 
         const getarticulos = async () => {
            setLoading(true); // Activate the loading indicator
            try {
                // Simulate a delay of 2 seconds (you can adjust this value as needed)
                await new Promise(resolve => setTimeout(resolve, 2000));
        
                // Proceed with the request after the delay
                request.Get(props.ipserv + 'getarticulos/', {}, r => {
                    if (r.success) {
                        setCodigo(r.rows);
                    } else {
                        Notify('No se encontraron Articulos');
                    }
                    setLoading(false); // Deactivate the loading indicator after the request completes
                });
            } catch (error) {
                console.error('Error fetching articulos:', error);
                Notify('Error al obtener los Articulos');
                setLoading(false); // Deactivate the loading indicator in case of error
            }
        };  
 */

    const onChangePage = (list, p) => {
        setDataSatPage(list)
        setPage(p)
    }

    useEffect(() => {
        if (props.ipserv.length > 0) {
            getParam();
        }
    }, [props.ipserv])

    useEffect(() => {
        getsucursales();
        getunidad();
        //getarticulos();

    }, [datasat])

    useEffect(() => {

    }, [datasatPage])

    return (

        <Container fluid className="col" style={{ backgroundColor: '#FFFFFF' }}>

            <Col className="bg-white mt-4 border shadow rounded ">
                <HeaderVista titulo={'Pedidos de mercadería para vendedores'} />

                <Form >

                    <Loader loading={loading} />

                    <Row>

                        <Col>
                            <EditarParam props={{ unidad, codigo, sucs, getParam: getParam, ipserv: props.ipserv, Tabla: 'ri_pomi', Title: 'Nuevo Registro', Tip: 'New' }} />

                            <Button variant="primary" onClick={getParam} style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                                {<><FontAwesomeIcon icon={faArrowAltCircleRight} className="ml-2" /> <span>Recargar Listado</span></>}
                            </Button>
                        </Col>

                    </Row>
                    <Loader loading={loading} />
                    {datasatPage && datasatPage.length > 0 ?
                        <Table className='mt-3 ' id='DataBI' striped bordered hover>

                            <thead  >
                                <tr>
                                    <th>MARCA_TEMPORAL</th>
                                    <th>NUMERO DE PEDIDO</th>
                                    <th>MAIL DEL VENDEDOR</th>
                                    <th>FECHA FACTURA</th>
                                    <th>NUMERO DE FACTURA</th>
                                    <th>NOMBRE Y APELLIDO DEL CLIENTE</th>
                                    <th>CODIGO DE PRODUCTO</th>
                                    <th>CANTIDAD</th>
                                    <th>UNIDAD DE MEDIDA</th>
                                    <th>SUCURSAL DE DESTINO</th>
                                    <th>OBSERVACIONES</th>
                                    {/* <th>EDITAR</th> */}
                                </tr>
                            </thead>

                            <tbody >
                                {datasatPage && datasatPage.length > 0 ? datasatPage.map(rows =>
                                    <tr>
                                        <td>  {rows.PomiMarcaTemporal}       </td>
                                        <td>  {rows.PomiNumDePedido}       </td>
                                        <th>  {rows.PomiMailDelVendedor}        </th>
                                        <th>  {rows.PomiFechaFactura && rows.PomiFechaFactura !== '0000-00-00' ? moment(rows.PomiFechaFactura).utc().format('YYYY-MM-DD') : ''}    </th>
                                        <th>  {rows.PomiNumDeFactura}       </th>
                                        <th>  {rows.PomiNombreYApellidoDeCliente}      </th>
                                        <th>  {rows.PomiCodigoDeProducto}       </th>
                                        <th>  {rows.PomiCantidad}       </th>
                                        <th>  {rows.PomiUnidadDeMedida}       </th>
                                        <th>  {rows.PomiSucDeSolicitanteDeMercaderia}       </th>
                                        <th>  {rows.PomiObservaciones}       </th>

                                    </tr>
                                ) : ""}
                            </tbody>
                        </Table>
                        : ""}
                    {datasat.length > 0 &&
                        <div className='full-width d-flex justify-content-center my-2'>
                            <Paginacion
                                page={page}
                                rows={rows}
                                data={datasat}
                                onChangePage={onChangePage}
                            />
                        </div>
                    }
                </Form>
            </Col>
        </Container >

    )
}