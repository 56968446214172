import React, { useState, useEffect } from 'react';
import {
    Container,
    Col,
    Form,
    Table,
    Button,
    Row,
} from 'react-bootstrap';

import request from '../../../request';
import Notify from '../../../components/compartidos/Notify';
import Loader from '../../../components/compartidos/Loader';
import EditarParam from './PomiEditAdmin';
import HeaderVista from '../../compartidos/HeaderVista';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import Paginacion from '../../../components/compartidos/Paginacion';

export default function PomiAdmin(props) {

    const [loading, setLoading] = useState(false);
    const [datasat, setDataSat] = useState([]);

    const [acciones, setAcciones] = useState([]);

    const [sucs, setSucs] = useState([]);
    const [unidad, setUnidad] = useState([])
    const [codigo, setCodigo] = useState([])

    const [page, setPage] = useState(1)
    const [datasatPage, setDataSatPage] = useState([]);
    const rows = 50

    const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');

    const getParam = async () => {
        setDataSat([])
        setDataSatPage([])
        request.Get(props.ipserv + 'getpomidist/', {}, r => {
            if (r.success) {
                setDataSat(r.rows);
                setDataSatPage(r.rows.slice(0, r.rows));
                setPage(1)
            } else {
                Notify('No Se encontraron Datos')
            }
        })
    }

    const deleteitem = async (data) => {
        const confirmation = window.confirm("¿Estás seguro de que deseas eliminar este elemento?");
        if (confirmation) {
            let params = {}
            params.PomiId = data
            params.Tip = 'Delete'
            params.Tabla = 'ri_pomi'
            request.Post(props.ipserv + 'deletpomiadmin/', { params }, r => {
                if (r.success) {
                    Notify("Parámetro Eliminado");
                    getParam()
                } else {
                    Notify("Se produjo un error eliminando el parámetro");
                }
            })
        }
    };


    const getsucursales = async () => {
        request.Get(props.ipserv + 'getsucursales/', {}, r => {
            if (r.success) {
                setSucs(r.rows)
            } else {
                Notify('No Se encontraron Datos de Sucursales')
            }
        })
    }

    const getunidad = async () => {
        request.Get(props.ipserv + 'getunidad/', {}, r => {
            if (r.success) {
                setUnidad(r.rows);
            } else {
                Notify('No Se encontraron Unidades')
            }
        })
    }


    const onChangePage = (list, p) => {
        setDataSatPage(list)
        setPage(p)
    }

    useEffect(() => {
        if (props.ipserv.length > 0) {
            getParam();
        }
    }, [props.ipserv])

    useEffect(() => {
        getsucursales();
        getunidad();

    }, [datasat])

    useEffect(() => {

    }, [datasatPage])

    return (

        <Container fluid className="col" style={{ backgroundColor: '#FFFFFF' }}>

            <Col className="bg-white mt-4 border shadow rounded ">
                <HeaderVista titulo={'Pedidos de mercadería para vendedores'} />

                <Form >

                    <Loader loading={loading} />

                    <Row>

                        <Col>
                            <EditarParam props={{ unidad, sucs, getParam: getParam, ipserv: props.ipserv, Tabla: 'ri_pomi', Title: 'Nuevo Registro', Tip: 'New' }} />

                            <Button variant="primary" onClick={getParam} style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                                {<><FontAwesomeIcon icon={faArrowAltCircleRight} className="ml-2" /> <span>Recargar Listado</span></>}
                            </Button>


                        </Col>

                    </Row>

                    <Loader loading={loading} />

                    {datasatPage && datasatPage.length > 0 ?
                        <Table className='mt-3 ' id='DataBI' striped bordered hover>

                            <thead  >
                                <tr>
                                    <th>MARCA_TEMPORAL</th>
                                    <th>NUMERO DE PEDIDO</th>
                                    <th>MAIL DEL VENDEDOR</th>
                                    <th>FECHA FACTURA</th>
                                    <th>NUMERO DE FACTURA</th>
                                    <th>NOMBRE Y APELLIDO DEL CLIENTE</th>
                                    <th>CODIGO DE PRODUCTO</th>
                                    <th>CANTIDAD</th>
                                    <th>UNIDAD DE MEDIDA</th>
                                    <th>SUCURSAL DE DESTINO</th>
                                    <th>SUCURSAL DE ORIGEN</th>
                                    <th>OBSERVACIONES</th>
                                    <th>N° DE DISTRIBUCION</th>
                                    <th>OBSERVACIONES DE ABASTECIMIENT</th>
                                    <th>SE CONFIRMA LA DISTRIBUCION?</th>
                                    <th>VERIFICACION DEL ESTADO DE LA DISTRIBUCION CON EL SISTEMA</th>
                                    <th>N° REMITO</th>
                                    <th>N° POMI</th>

                                    <th>EDITAR</th>
                                    <th>ELIMINAR</th>
                                </tr>
                            </thead>

                            <tbody >
                                {datasatPage && datasatPage.length > 0 ? datasatPage.map(rows =>
                                    <tr key={rows.PomiId}>
                                        <td>  {rows.PomiMarcaTemporal}       </td>
                                        <td>  {rows.PomiNumDePedido}       </td>
                                        <th>  {rows.PomiMailDelVendedor}        </th>
                                        <th>  {rows.PomiFechaFactura}</th>
                                        <th>  {rows.PomiNumDeFactura}       </th>
                                        <th>  {rows.PomiNombreYApellidoDeCliente}      </th>
                                        <th>  {rows.PomiCodigoDeProducto}       </th>
                                        <th>  {rows.PomiCantidad}       </th>
                                        <th>  {rows.PomiUnidadDeMedida}       </th>
                                        <th>  {rows.PomiSucDeSolicitanteDeMercaderia}       </th>
                                        <th>  {rows.PomiSucDondeSeEncuentraDisponibleLaMer}       </th>
                                        <th>  {rows.PomiObservaciones}       </th>
                                        <th>  {rows.PomiNumeroDeDist}       </th>
                                        <th>  {rows.PomiObservacionesAbastecimiento}       </th>
                                        <th>  {rows.PomiSeConfirmaLaDistribucion}       </th>

                                        <th>  {rows.PomiEnvioMail}       </th>
                                        <th>  {rows.PomiIdRemito}       </th>
                                        <th>  {rows.PomiCodigoDeEntrega}       </th>


                                        <td >
                                            <EditarParam
                                                props={{
                                                    ipserv: props.ipserv,
                                                    Tip: 'Edit',
                                                    Title: 'Editar Registro',
                                                    Tabla: 'ri_pomi',
                                                    PomiId: rows.PomiId,

                                                    PomiNumDePedido: rows.PomiNumDePedido,
                                                    PomiMailDelVendedor: rows.PomiMailDelVendedor,
                                                    PomiFechaFactura: rows.PomiFechaFactura && rows.PomiFechaFactura !== '0000-00-00' ? moment(rows.PomiFechaFactura).format('YYYY-MM-DD') : '',
                                                    PomiNumDeFactura: rows.PomiNumDeFactura,
                                                    PomiNombreYApellidoDeCliente: rows.PomiNombreYApellidoDeCliente,
                                                    PomiCodigoDeProducto: rows.PomiCodigoDeProducto,
                                                    PomiCantidad: rows.PomiCantidad,
                                                    PomiUnidadDeMedida: rows.PomiUnidadDeMedida,
                                                    PomiSucDeSolicitanteDeMercaderia: rows.PomiSucDeSolicitanteDeMercaderia,
                                                    PomiSucDondeSeEncuentraDisponibleLaMer: rows.PomiSucDondeSeEncuentraDisponibleLaMer,
                                                    PomiObservaciones: rows.PomiObservaciones,


                                                    PomiNumeroDeDist: rows.PomiNumeroDeDist,
                                                    PomiObservacionesAbastecimiento: rows.PomiObservacionesAbastecimiento,
                                                    PomiSeConfirmaLaDistribucion: rows.PomiSeConfirmaLaDistribucion,
                                                    PomiEnvioMail: rows.PomiEnvioMail,
                                                    PomiIdRemito: rows.PomiIdRemito,
                                                    PomiCodigoDeEntrega: rows.PomiCodigoDeEntrega,


                                                    getParam: getParam,

                                                    unidad,
                                                    codigo,
                                                    sucs
                                                }} /> </td>
                                        <td> <Button variant="danger" onClick={() => { deleteitem(rows.PomiId) }}><FontAwesomeIcon icon={faWindowClose} className="mr-2" /></Button></td>
                                    </tr>
                                ) : ""}
                            </tbody>
                        </Table>
                        : ""}
                    {datasat.length > 0 &&
                        <div className='full-width d-flex justify-content-center my-2'>
                            <Paginacion
                                page={page}
                                rows={rows}
                                data={datasat}
                                onChangePage={onChangePage}
                            />
                        </div>
                    }
                </Form>
            </Col>
        </Container >

    )
}