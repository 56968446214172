import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner, Form, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import request from '../../../request'
import Notify from '../../compartidos/Notify'
import Paginacion from '../../compartidos/Paginacion'
import focusElement from '../../compartidos/focusElement'
import sort from '../../compartidos/sort'

export default function PanelUsuarios(props) {
    const [usuarios, setUsuarios] = useState([])
    const [usuariosPage, setUsuariosPage] = useState([])
    const [page, setPage] = useState([])
    const [loading, setLoading] = useState(true)
    const [checkPass, setCheckPass] = useState(false)
    const [user, setUser] = useState({
        apellido: '',
        nombre: '',
        nick: '',
        lengthPass: 12,
        email: ''
    })
    const [show, setShow] = useState(false)
    const [forceRender, setForceRender] = useState(true)
    const [sending, setSending] = useState(false)

    const rows = 50

    const getData = () => {
        setLoading(true)

        request.Get(props.ipserv + 'getusers/', {}, r => {
            r.users.sort((a, b) => {
                const A = a.UsrApel + ' ' + a.UsrNom
                const B = b.UsrApel + ' ' + b.UsrNom

                return sort.alfAsc(A, B)
            })

            r.users.forEach(u => u.pass = false)

            setUsuarios(r.users)
            setUsuariosPage(r.users.slice(0, rows))
            setPage(1)
            setLoading(false)

            if (r.users.length === 0) Notify({ type: 'busqueda_fail' })
        })
    }

    const onChangePage = (list, p) => {
        setPage(p)
        setUsuariosPage(list)
    }

    const showPass = i => {
        const u = usuariosPage[i]
        const array = usuariosPage.slice(0)

        u.pass = !u.pass
        array[i] = u

        setUsuariosPage(array)
    }

    const changeDataUser = e => {
        const key = e.target.name
        let value = e.target.value

        if (key === "lengthPass" && value < 0) value = 0

        setUser({
            ...user,
            [key]: value
        })
    }

    const checkPassword = e => {
        const p = e.target.value

        if (user.contraseña !== p) {
            setCheckPass(false)
        } else {
            setCheckPass(true)
        }
    }

    const nickEnUso = nick => {
        for (let i = 0; i < usuarios.length; i++) {
            const n = usuarios[i].UsrNick

            if (n === nick.toUpperCase()) {
                return true
            }
        }

        return false
    }

    const emailEnUso = email => {
        for (let i = 0; i < usuarios.length; i++) {
            const n = usuarios[i].UsrMail

            if (n === email) {
                return true
            }
        }

        return false
    }

    const showModal = (value) => {
        setShow(true)

        setTimeout(() => {
            const modal = document.getElementById('modal-data-user')

            modal.innerHTML = value
        }, 0)
    }

    const validarEmail = v => {
        const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

        if (reg.test(v)) {
            return true
        } else {
            return false
        }
    }

    const crearUsuario = () => {
        let vacio = false

        Object.keys(user).forEach(key => {
            if (!user[key]) {
                vacio = true
            }
        })

        if (vacio) {
            Notify({ type: 'completar' })
            return
        }

        if (nickEnUso(user.nick)) {
            Notify('El nick ingresado se encuentra en uso')
            return
        }

        if (emailEnUso(user.email)) {
            Notify('El email ingresado se encuentra en uso')
            return
        }

        /*
        if (!checkPass) {
            Notify('Las contraseñas no coinciden')
            return
        }
        */

        if (!validarEmail(user.email)) {
            Notify('Ingrese un email válido')
            return
        }

        if (!user.lengthPass || user.lengthPass <= 0) {
            Notify('Ingrese un valor válido para la longitud de la contraseña')
            return
        }

        setSending(true)

        request.Post(props.ipserv + 'newuser/', user, r => {
            if (r.success) {
                Notify({ type: 'exito' })

                setCheckPass(false)
                setUser({
                    apellido: '',
                    nombre: '',
                    nick: '',
                    lengthPass: 6,
                    email: ''
                })
                setSending(false)
                getData()

                setForceRender(false)
                setTimeout(() => {
                    setSending(false)
                    setForceRender(true)
                }, 0);
            } else {
                Notify(r.Error)
                setSending(false)
            }
        })
    }

    const irPerfil = p => props.irPerfil(p)

    useEffect(() => {
        focusElement('#form-apellido-user')

        if (props.ipserv.length > 0) {
            getData()
        }
    }, [props.ipserv])

    return <div className='row d-flex mx-0 pt-2 relative-position'>
        <div className='col-12 col-md-5'>
            <div className='full-width'>
                <h5>Crear Nuevo Usuario</h5>
            </div>

            <div className='linea-horizontal small-width mx-0 mb-4' />

            <Form key={forceRender}>
                <Form.Group>
                    <Form.Label>Apellido</Form.Label>
                    <Form.Control
                        id="form-apellido-user"
                        type="text"
                        placeholder="Apellido"
                        name="apellido"
                        onChange={changeDataUser}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Nombre"
                        name="nombre"
                        onChange={changeDataUser}
                    />
                </Form.Group>

                <Form.Group className='relative-position mb-4'>
                    <Form.Label>Nick</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Nick"
                        name="nick"
                        onChange={changeDataUser}
                    />
                    {user.nick &&
                        <div className='absolute-bottom full-width text-mini fw-bold' style={{ marginBottom: -17 }}>
                            {nickEnUso(user.nick)
                                ? <span className='text-negative'>X El nick ya se encuentra en uso</span>
                                : <span className='text-positive'>Nick disponible</span>
                            }
                        </div>
                    }
                </Form.Group>

                <Form.Group className='relative-position mb-4'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={changeDataUser}
                    />
                    {user.email &&
                        <div className='absolute-bottom full-width text-mini fw-bold' style={{ marginBottom: -17 }}>
                            {!validarEmail(user.email)
                                ? <span className='text-negative'>X Ingrese un email válido</span>
                                : (
                                    emailEnUso(user.email)
                                        ? <span className='text-negative'>X El email ya se encuentra en uso</span>
                                        : <span className='text-positive'>Email disponible</span>
                                )
                            }
                        </div>
                    }
                </Form.Group>

                <Form.Group className="relative-position">
                    <Form.Label>Longitud de la Contraseña</Form.Label>
                    <Form.Control
                        type="number"
                        name="lengthPass"
                        value={user.lengthPass}
                        onChange={changeDataUser}
                    />
                    <div className='absolute-bottom full-width text-mini fw-bold' style={{ marginBottom: -17 }}>
                        <span className='text-grey'>* La contraseña se generará automáticamente con la longitud deseada y se enviará al correo del usuario.</span>
                    </div>
                </Form.Group>

                <Form.Group hidden>
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Contraseña"
                        name="contraseña"
                        onChange={changeDataUser}
                    />
                </Form.Group>

                <Form.Group className='relative-position' hidden>
                    <Form.Label>Repetir Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Contraseña"
                        onChange={checkPassword}
                    />
                    {user.contraseña &&
                        <div className='absolute-bottom full-width text-mini fw-bold' style={{ marginBottom: -17 }}>
                            {!checkPass &&
                                <span className='text-negative'>Las contraseñas no coinciden</span>
                            }
                        </div>
                    }
                </Form.Group>
            </Form>

            {sending
                ? <Spinner className='mt-4 ml-4' animation="border" />
                : <Button
                    className="mt-4"
                    variant="outline-primary"
                    onClick={crearUsuario}
                    disabled={loading}
                >
                    Guardar
                </Button>
            }
        </div>

        <div className='col-12 col-md-7'>
            <div className='full-width text-center'>
                <h5>Lista de Usuarios</h5>
            </div>

            <div className='linea-horizontal' />

            {loading
                ? <div className='full-width mt-2 d-flex justify-content-center'>
                    <Spinner animation="border" />
                </div>
                : <div>
                    {usuarios.length > 0 &&
                        <div className='full-width d-flex justify-content-center mt-2'>
                            <Paginacion
                                page={page}
                                rows={rows}
                                data={usuarios}
                                onChangePage={onChangePage}
                            />
                        </div>
                    }

                    <div
                        style={{
                            overflow: 'auto',
                            maxHeight: '67vh'
                        }}
                    >
                        <Col className="bg-white border rounded ">
                            <Row
                                className="text-white"
                                style={{ backgroundColor: "#380138" }}
                            >

                                <Col>Apellido</Col>
                                <Col>Nombre</Col>
                                <Col>Nick</Col>
                                <Col>Perfil</Col>
                                <Col>Contraseña</Col>
                                <Col>Fecha de Alta</Col>
                                <Col>Email</Col>
                            </Row>
                        </Col>

                        <Col>
                            {usuarios.length > 0
                                ? usuariosPage.map((u, i) => (
                                    <Row key={u.UsrId} className="border">
                                        <Col>{u.UsrApel}</Col>
                                        <Col>{u.UsrNom}</Col>
                                        <Col>{u.UsrNick}</Col>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    Ver Perfil
                                                </Tooltip>
                                            }
                                        >
                                            <Col
                                                className='cursor-pointer'
                                                onClick={() => irPerfil({ id: u.PrfId, nom: u.PrfNom })}
                                            >
                                                {u.PrfId} - {u.PrfNom}
                                            </Col>
                                        </OverlayTrigger>
                                        <Col className='relative-position'>
                                            {u.pass ? u.UsrPassword : '******'}

                                            <div
                                                className='full-height absolute-top-right d-flex align-items-center mr-1 cursor-pointer'
                                                onClick={() => showPass(i)}
                                            >
                                                <FontAwesomeIcon icon={u.pass ? faEye : faEyeSlash} />
                                            </div>
                                        </Col>
                                        <Col>{moment(u.UsrFch).format('DD/MM/YYYY HH:mm')}hs</Col>
                                        <Col className='ellipsis cursor-pointer' onClick={() => showModal(u.UsrMail)}>{u.UsrMail}</Col>
                                    </Row>
                                ))
                                : "SIN REGISTROS"}
                        </Col>
                    </div>

                    {usuarios.length > 0 &&
                        <div className='full-width d-flex justify-content-center my-2'>
                            <Paginacion
                                page={page}
                                rows={rows}
                                data={usuarios}
                                onChangePage={onChangePage}
                            />
                        </div>
                    }
                </div>
            }
        </div>

        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Body className="text-no-desborde" id="modal-data-user">
            </Modal.Body>
        </Modal>
    </div>
}