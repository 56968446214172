
import { faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import keyEnterConfirm from "../../../compartidos/keyEnterConfirm";
import Notify from "../../../compartidos/Notify";

export default function PropsQuery (props) {
    const [fch, setFch] = useState(props.params ? props.params.fch : true)
    const [soloFchDsd, setSoloFchDsd] = useState(props.params ? props.params.soloFchDsd : false)
    const [sucCheck, setSucCheck] = useState(props.params ? props.params.sucCheck : false)
    const [sucs, setSucs] = useState(props.params ? props.params.sucs : '')
    const [excel, setExcel] = useState(props.params ? props.params.excel : true)
    const [columnas, setColumnas] = useState(props.params ? props.params.columnas : [])
    const [col, setCol] = useState(props.params ? props.params.col : false)
    const [filtrosCheck, setFiltrosCheck] = useState(props.params ? props.params.filtrosCheck : false)
    const [filtros, setFiltros] = useState(props.params ? props.params.filtros : [])
    const [info, setInfo] = useState(props.params ? props.params.info : [])
    const [infoCheck, setInfoCheck] = useState(props.params ? props.params.infoCheck : false)

    const labelOpcion = useRef(null)
    const valueOpcion = useRef(null)

    const labelOpcionCol = useRef(null)
    const valueOpcionCol = useRef(null)
    const tipoOpcionCol = useRef(null)

    const labelOpcionFiltro = useRef(null)
    const keyOpcionFiltro = useRef(null)
    const valueOpcionFiltro = useRef(null)
    const requiredOpcionFiltro = useRef(null)

    const infoOpcion = useRef(null)

    const reset = () => {
        setFch(true)
        setSoloFchDsd(false)
        setSucCheck(false)
        setSucs('')
        setColumnas([])
        setCol(false)
        setFiltrosCheck(false)
        setFiltros([])
        setInfo([])
        setInfoCheck(false)
    }

    const changeSucCheck = () => {
        setSucCheck(!sucCheck)
        setSucs('')
    }

    const changeFiltrosCheck = () => {
        setFiltrosCheck(!filtrosCheck)
        setFiltros([])
    }

    const changeSucs = e => {
        const value = e.target.value

        if (value === 'otro') {
            setSucs([])
        } else {
            setSucs(value)
        }
    }

    const addItemSucs = () => {
        const label = labelOpcion.current.value
        const value = valueOpcion.current.value

        if (!label || !value) {
            Notify('No puede añadir elementos vacios')
            return
        }

        const array = sucs.slice(0)

        array.push({ label, value })

        setSucs(array)
        
        labelOpcion.current.value = ''
        
        valueOpcion.current.value = ''
    }

    const addItemCol = () => {
        const label = labelOpcionCol.current.value
        const key = valueOpcionCol.current.value
        const tipo = tipoOpcionCol.current.value

        if (!label || !key || !tipo) {
            Notify('No puede añadir elementos vacios')
            return
        }

        const array = columnas.slice(0)

        array.push({ label, key, tipo })

        setColumnas(array)
        
        labelOpcionCol.current.value = ''
        
        valueOpcionCol.current.value = ''
        
        tipoOpcionCol.current.value = ''
    }

    const addItemFiltros = () => {
        const label = labelOpcionFiltro.current.value
        const key = keyOpcionFiltro.current.value
        const value = valueOpcionFiltro.current.value
        const required = requiredOpcionFiltro.current.checked

        if (!label || !key) {
            Notify('No puede añadir elementos vacios')
            return
        }

        const array = filtros.slice(0)

        array.push({ label, key, required })

        setFiltros(array)
        
        labelOpcionFiltro.current.value = ''
        
        keyOpcionFiltro.current.value = ''

        valueOpcionFiltro.current.value = ''
        
        requiredOpcionFiltro.current.checked = false
    }

    const addItemInfo = () => {
        const value = infoOpcion.current.value

        if (!value) {
            Notify('No puede añadir elementos vacios')
            return
        }

        const array = info.slice(0)

        array.push(value)

        setInfo(array)
        
        infoOpcion.current.value = ''
    }

    const deleteItemSucs = i => {
        const array = sucs.slice(0)
        array.splice(i, 1)

        setSucs(array)
    }

    const deleteItemColumnas = i => {
        const array = columnas.slice(0)
        array.splice(i, 1)

        setColumnas(array)
    }

    const deleteItemFiltros = i => {
        const array = filtros.slice(0)
        array.splice(i, 1)

        setFiltros(array)
    }

    const deleteItemInfo = i => {
        const array = info.slice(0)
        array.splice(i, 1)

        setInfo(array)
    }

    const guardar = () => {
        if (sucCheck && !sucs) {
            Notify('Debe selecionar una unidad de negocio o crear sus propias opciones')
            return
        }

        if (Array.isArray(sucs) && sucs.length === 0) {
            Notify('El filtro debe tener al menos una opcion')
            return
        }

        if (col && columnas.length === 0) {
            Notify('Debe agregar al menos una columna para filtrar')
            return
        }

        if (filtrosCheck && filtros.length === 0) {
            Notify('Debe agregar al menos un elemento para filtrar')
            return
        }

        if (infoCheck && info.length === 0) {
            Notify('Debe agregar al menos un elemento de informacion')
            return
        }

        props.guardar({
            columnas: col ? columnas : false,
            sucs,
            fch,
            soloFchDsd,
            info,
            excel,
            filtros
        })

        reset()
    }

    const doc = i => <OverlayTrigger
        placement="bottom"
        overlay={
            <Tooltip>
                {i}
            </Tooltip>
        }
    >
        <FontAwesomeIcon
            icon={faQuestionCircle}
            className='cursor-pointer'
            color="blue"
        />
    </OverlayTrigger>

    useEffect(() => {
        if (!fch) setSoloFchDsd(false)
    }, [fch])

    useEffect(() => {
        if (!col) setColumnas([])
    }, [col])

    useEffect(() => {
        if (!infoCheck) setInfo([])
    }, [infoCheck])

    return <>
        <h6 className="text-white header-vista">
            Propiedades de la Vista
        </h6>

        <div className="full-width d-flex mt-3">
            <div className="col-6">
                <div>
                    <h6 className="text-bold">Fecha {doc('Su consulta debe contener las variables temporales @FCHDSD y @FCHHST')}</h6>

                    <div className="text-caption">Muestra Combo de Fecha Desde y Fecha Hasta</div>

                    <div>
                        <input
                            id="fch-check"
                            defaultChecked={fch}
                            onChange={e => setFch(!fch)}
                            type="checkbox"
                        />
                        <label className="ml-1" htmlFor="fch-check">Fechas</label>
                    </div>

                    {fch && <div className="pl-2">
                        <div>
                            <input
                                id="soloFchDsd-check"
                                defaultChecked={soloFchDsd}
                                onChange={e => setSoloFchDsd(!soloFchDsd)}
                                type="checkbox"
                            />
                            <label className="ml-1" htmlFor="soloFchDsd-check">Solo Fecha Desde (muestra una sola de las fechas)</label>
                        </div>
                    </div>}
                </div>
                
                <div className="mt-2">
                    <h6 className="text-bold">Filtro Select {doc('Desplegable con opciones de las sucursales de la unidad de negocios seleccionada. Si selecciona "Otro", puede enlistar sus propias opciones. Su consulta debe contener la variable temporal @SUC')}</h6>

                    <div className="text-caption">Habilita el filtro por sucursales</div>

                    <div>
                        <input
                            id="sucCheck-check"
                            defaultChecked={sucCheck}
                            onChange={changeSucCheck}
                            type="checkbox"
                        />
                        <label className="ml-1" htmlFor="sucCheck-check">Filtro</label>
                    </div>


                    {sucCheck && <Form.Control
                        as="select"
                        value={props.params ? props.params.sucs : ''}
                        onChange={changeSucs}
                    >
                        <option value="">Seleccione una sucursal</option>
                        <option value="geo">Geomaco</option>
                        <option value="sup">Supermat</option>
                        <option value="sol">El Sol</option>
                        <option value="hip">Hipercat</option>
                        <option value="otro">Otro</option>
                    </Form.Control>}

                    {Array.isArray(sucs) && <>
                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">Label</div>
                            <Form.Control
                                className="my-3 half-width"
                                ref={labelOpcion}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemSucs)}
                            />
                            <Button
                                className="ml-4"
                                onClick={addItemSucs}
                                variant="success"
                            >
                                Añadir Elemento
                            </Button>
                        </div>

                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">Value</div>
                            <Form.Control
                                className="my-3 half-width"
                                ref={valueOpcion}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemSucs)}
                            />
                        </div>

                        <div>
                            Elementos:
                            {sucs.map((s, i) => <div>
                                <FontAwesomeIcon className="cursor-pointer" onClick={() => deleteItemSucs(i)} icon={faTrash} color="red" /> { s.label }: { s.value }
                            </div>)}
                        </div>
                    </>}
                </div>
                
                <div className="mt-3">
                    <h6 className="text-bold">Filtro Query {doc('Habilita campos de busqueda con cada opcion que se agregue, el campo "key" debe contener el nombre de la variable temporal (la variable en mayusculas) usada en la consulta')}</h6>

                    <div className="text-caption">Habilita los filtros propios de la consulta</div>

                    <div>
                        <input
                            id="filtros-check"
                            defaultChecked={filtrosCheck}
                            onChange={changeFiltrosCheck}
                            type="checkbox"
                        />
                        <label className="ml-1" htmlFor="filtros-check">Filtro</label>
                    </div>

                    {filtrosCheck && <>
                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">Label</div>
                            <Form.Control
                                className="my-3 half-width"
                                ref={labelOpcionFiltro}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemFiltros)}
                            />
                            <Button
                                className="ml-4"
                                onClick={addItemFiltros}
                                variant="success"
                            >
                                Añadir Elemento
                            </Button>
                        </div>

                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">Key</div>
                            <Form.Control
                                className="my-3 half-width"
                                ref={keyOpcionFiltro}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemFiltros)}
                            />
                        </div>

                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">value</div>
                            <Form.Control
                                className="my-3 half-width"
                                ref={valueOpcionFiltro}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemFiltros)}
                            />
                        </div>

                        <div>
                        <input
                            id="required-check"
                            ref={requiredOpcionFiltro}
                            defaultChecked={false}
                            type="checkbox"
                        />
                            <label className="ml-1" htmlFor="required-check">Requerido</label>
                        </div>

                        <div>
                            Elementos:
                            {filtros.map((f, i) => <div>
                                <FontAwesomeIcon className="cursor-pointer" onClick={() => deleteItemFiltros(i)} icon={faTrash} color="red" /> { f.label }: { f.key }. { f.required && <span className="text-negative">Requerido</span> }
                            </div>)}
                        </div>
                    </>}
                </div>
            </div>

            <div className="col-6">
                <div>
                    <h6 className="text-bold">Excel</h6>

                    <div className="text-caption">Habilita la descarga del Excel de la consulta</div>

                    <div>
                        <input
                            id="excel-check"
                            defaultChecked={excel}
                            onChange={e => setExcel(!excel)}
                            type="checkbox"
                        />
                        <label className="ml-1" htmlFor="excel-check">Excel</label>
                    </div>
                </div>

                <div className="mt-2">
                    <h6 className="text-bold">Columnas {doc('Este filtro se realiza a nivel servidor luego de ejecutada la consulta. Habilita un desplegable de opciones para filtrar por columna. El campo "Columna" debe contener el nombre de la columna de la consulta. El tipo es el tipo de comparacion usada al filtrar. Id: comparacion de igualdad. Texto: verifica si el texto esta contenido. Fecha: busca la fecha ingreda. Valor: comparacion entre dos valores numericos')}</h6>

                    <div className="text-caption">Habilita el filtro por columna</div>

                    <div>
                        <input
                            id="col-check"
                            defaultChecked={col}
                            onChange={e => setCol(!col)}
                            type="checkbox"
                        />
                        <label className="ml-1" htmlFor="col-check">Columnas</label>
                    </div>

                    {col && <>
                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">Label</div>
                            <Form.Control
                                className="my-3 half-width"
                                ref={labelOpcionCol}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemCol)}
                            />
                            <Button
                                className="ml-4"
                                onClick={addItemCol}
                                variant="success"
                            >
                                Añadir Elemento
                            </Button>
                        </div>

                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">Columna</div>
                            <Form.Control
                                className="my-3 half-width"
                                ref={valueOpcionCol}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemCol)}
                            />
                        </div>

                        <div className="d-flex align-items-center text-bold">
                            <div className="text-bold mr-2">Tipo</div>
                            <Form.Control
                                className="mb-3 half-width"
                                as="select"
                                ref={tipoOpcionCol}
                                onKeyUp={e => keyEnterConfirm(e, addItemCol)}
                            >
                                <option value="">Seleccione un tipo</option>
                                <option value="id">Id</option>
                                <option value="texto">Texto</option>
                                <option value="fecha">Fecha</option>
                                <option value="valor">Valor</option>
                            </Form.Control>
                        </div>

                        <div>
                            Elementos:
                            {columnas.map((c, i) => <div>
                                <FontAwesomeIcon className="cursor-pointer" onClick={() => deleteItemColumnas(i)} icon={faTrash} color="red" /> { c.label }: { c.key }. Tipo: { c.tipo }
                            </div>)}
                        </div>
                    </>}
                </div>

                <div className="mt-2">
                    <h6 className="text-bold">Info</h6>

                    <div className="text-caption">Habilita informacion adicional de la vista</div>

                    <div>
                        <input
                            id="info-check"
                            defaultChecked={false}
                            onChange={e => setInfoCheck(!infoCheck)}
                            type="checkbox"
                        />
                        <label className="ml-1" htmlFor="info-check">Info</label>
                    </div>

                    {infoCheck && <>
                        <div className="d-flex align-items-center text-bold">
                            <Form.Control
                                className="my-3 half-width"
                                ref={infoOpcion}
                                type={"text"}
                                onKeyUp={e => keyEnterConfirm(e, addItemInfo)}
                            />
                            <Button
                                className="ml-4"
                                onClick={addItemInfo}
                                variant="success"
                            >
                                Añadir Elemento
                            </Button>
                        </div>

                        <div>
                            Elementos:
                            {info.map((inf, i) => <div>
                                <FontAwesomeIcon className="cursor-pointer" onClick={() => deleteItemInfo(i)} icon={faTrash} color="red" /> { inf }
                            </div>)}
                        </div>
                    </>}
                </div>
            </div>
        </div>

        <div className="full-width d-flex mt-3">
            {props.atras !== undefined &&
                <Button className="mr-3" variant="danger" onClick={props.atras}>
                    Atras
                </Button>
            }

            <Button variant="success" onClick={guardar}>
                Guardar
            </Button>
        </div>
    </>
}