
import React from "react";
import { Tabs, Tab, Fade } from "react-bootstrap";
import MiInput from "../../../compartidos/MiInput";

export default function Inputs (props) {
    const data = props.data
    const setData = props.setData

    return <>
        <h6 className="text-white header-vista">
            Query Automatico
        </h6>

        <div className="d-flex">
            <div>Tipo: </div>

            <div className='cursor-pointer'>
                <input
                    defaultChecked={data.tipo === 'NORM'}
                    type="radio"
                    id="norm"
                    name="tipo" 
                    value="NORM"
                    className='cursor-pointer ml-2'
                    onClick={setData}
                />
                <label
                    className='cursor-pointer ml-2'
                    htmlFor="norm"
                >
                    NORM
                </label>
            </div>

            <div className='cursor-pointer'>
                <input
                    defaultChecked={data.tipo === 'DROPs'}
                    type="radio"
                    id="drop"
                    name="tipo"
                    value="DROP"
                    className='cursor-pointer ml-2'
                    onClick={setData}
                />
                <label
                    className='cursor-pointer ml-2'
                    htmlFor="drop"
                >
                    DROP
                </label>
            </div>
        </div>
        
        <Tabs
            activeKey={data.tipo}
            transition={Fade}
            mountOnEnter={true}
            unmountOnExit={true}
        >
            <Tab eventKey="NORM" className='mx-auto relative-position' style={{ maxWidth: 1300 }}>
                <MiInput
                    classContent="mb-4 mt-3"
                    label={"SELECT (requerido)"}
                    descripcion={"Escriba los cambos de la consulta"}
                    value={ data.fields }
                    as={"textarea"}
                    rows={2}
                    name={"fields"}
                    onChange={setData}
                />

                <MiInput
                    classContent="mb-4"
                    label={"FROM (requerido)"}
                    descripcion={"Escriba las tablas junto a los JOIN que las cruzan"}
                    value={ data.tablas }
                    as={"textarea"}
                    rows={4}
                    name={"tablas"}
                    onChange={setData}
                />

                <MiInput
                    classContent="mb-4"
                    label={"WHERE"}
                    descripcion={"Escriba las condiciones de la consulta"}
                    value={ data.where }
                    as={"textarea"}
                    rows={2}
                    name={"where"}
                    onChange={setData}
                />

                <MiInput
                    classContent="mb-4 mt-4"
                    label={"ORDER BY"}
                    descripcion={"Ordenamiento de la consulta"}
                    value={ data.orderby }
                    type={"text"}
                    name={"orderby"}
                    onChange={setData}
                />

                <MiInput
                    classContent="mb-4 mt-4"
                    label={"GROUP BY"}
                    descripcion={"Agrupacion de la consulta"}
                    value={ data.groupby }
                    type={"text"}
                    name={"groupby"}
                    onChange={setData}
                />

                <MiInput
                    classContent="mb-4 mt-4"
                    label={"LIMIT"}
                    descripcion={"Limite de la consulta"}
                    value={ data.limit }
                    type={"text"}
                    name={"limit"}
                    onChange={setData} 
                />
            </Tab>
            
            <Tab eventKey="DROP" className='mx-auto' style={{ maxWidth: 1300 }}>
                <MiInput
                    classContent="mb-4 mt-3"
                    label={"FREE (requerido)"}
                    descripcion={"Escriba la consulta completa"}
                    value={ data.free }
                    as={"textarea"}
                    rows={20}
                    name={"free"}
                    onChange={setData}
                    info="La consulta debe finalizar con la creacion de una tabla temporaria llamada 'TFINAL' la cual debe ser poblada con los datos que se requieran"
                />
            </Tab>
        </Tabs>
    </>
}