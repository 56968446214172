
import React, { useEffect, useState } from "react";
import request from "../../../../request";
import MiInput from "../../../compartidos/MiInput";
import Notify from "../../../compartidos/Notify";
import conexiones from "./conexiones"

export default function Datos (props) {
    const [prfId, setPrfId] = useState(0)

    const data = props.data
    const setData = props.setData

    useEffect(() => {
        request.Get(props.ipserv + 'prfuser/', {}, r => {
            if (r.success) {
                setPrfId(r.PrfId)
            } else {
                Notify({ type: 'fail' })
            }
        })
    }, [])

    return <>
        <h6 className="text-white header-vista">
            Datos de la Vista
        </h6>
        <MiInput
            classContent="mb-4 mt-4"
            label={"Conexion"}
            descripcion={"Seleccione la base de datos a la que conectara la consulta"}
            value={ data.conexion }
            as={"select"}
            name={"conexion"}
            onChange={setData}
            opciones={[ { label: 'Conexiones...', value: '' }, ...conexiones.filter(c => c.prf === parseInt(prfId) || !c.prf)]}
        />

        <MiInput
            classContent="mb-4 mt-4"
            label={"Link"}
            descripcion={"Link de la Vista sin barra diagonal"}
            value={ data.link }
            type={"text"}
            name={"link"}
            onChange={setData}
        />

        <MiInput
            classContent="mb-4 mt-4"
            label={"Titulo"}
            descripcion={"Titulo de la vista"}
            value={ data.titulo }
            type={"text"}
            name={"titulo"}
            onChange={setData} 
        />
    </>
}