import React, { useEffect, useState } from 'react'
import { InputGroup, Tabs, Tab, Spinner, Button, Modal, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSortDown, faCheck, faTimes, faPen, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import request from '../../../request'
import Notify from '../../compartidos/Notify'
import keyEnterConfirm from '../../compartidos/keyEnterConfirm'
import focusElement from '../../compartidos/focusElement'

export default function PanelMenus (props) {
    const [dataMenus, setDataMenus] = useState(null)
    const [items, setItems] = useState([])
    const [subMenus, setSubMenus] = useState([])
    const [menus, setMenus] = useState([])
    const [empresas, setEmpresas] = useState([])
    const [negocios, setNegocios] = useState([])

    const [show, setShow] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const [editItem, setEditItem] = useState(false)
    const [editMenu, setEditMenu] = useState('')

    const [nuevo, setNuevo] = useState('')
    const [link, setLink] = useState('')
    const [rutas, setRutas] = useState('')
    const [auxItem, setAuxItem] = useState(null)

    const [newItem, setNewItem] = useState(null)

    const [idMenu, setIdMenu] = useState(1)
    const [idSubMenu, setIdSubMenu] = useState(1)
    const [idItem, setIdItem] = useState(1)

    const [loadingMenus, setLoadingMenus] = useState(true)
    const [loadingCrearMenus, setLoadingCrearMenus] = useState(false)

    const getDataMenus = () => {
        request.Get(props.ipserv + 'getMenus/', {}, r => {
            if (!r.success) {
                Notify({ type: 'error' })
            } else {
                setLoadingMenus(false)
                const dataMenus = generarDatosMapa(r)

                setDataMenus(dataMenus)
                setItems(dataMenus.items)
                setSubMenus(dataMenus.subMenus)
                setMenus(dataMenus.menus)
                setEmpresas(dataMenus.empresas)
                setNegocios(dataMenus.negocios)
            }
        })
    }

    const generarDatosMapa = (dataMenus, check = true) => {
        let items = []
        let itemsIds = []

        dataMenus.itemmenu.forEach(item => {
            if (!itemsIds.includes(item.IMenuId)) {
                items.push(item)
                itemsIds.push(item.IMenuId)
            }
        })

        let subMenusIds = []

        items.forEach(item => {
            item.check = check
            item.checkRes = check

            if (!subMenusIds.includes(item.SubMenuId)) subMenusIds.push(item.SubMenuId)
        })

        const subMenus = dataMenus.submenu.filter(s => subMenusIds.includes(s.SubMenuId))
        let menusIds = []

        subMenus.forEach(s => {
            s.check = check
            s.checkRes = check

            if (!menusIds.includes(s.MenuId)) menusIds.push(s.MenuId)
        })

        const menus = dataMenus.datamenu.filter(m => menusIds.includes(m.MenuId))
        let emps = []

        menus.forEach(m => {
            m.check = check
            m.checkRes = check
            m.addMenu = false

            if (!emps.includes(m.DmnAbr)) emps.push(m.DmnAbr)
        })

        const empresas = dataMenus.menuemp.filter(m => emps.includes(m.DmnAbr))
        let negs = []

        empresas.forEach(e => {
            e.check = check
            e.checkRes = check
            e.addMenu = false

            if (!negs.includes(e.MenuNeg)) negs.push(e.MenuNeg)
        })

        const negocios = dataMenus.menuneg.filter(m => negs.includes(m.MenuNeg))

        negocios.forEach(n => {
            n.check = check
            n.checkRes = check
        })

        return {
            items,
            subMenus,
            menus,
            empresas,
            negocios
        }
    }

    const generarDatosResumen = () => {
        if (!dataMenus) {
            return {
                items: [],
                subMenus: [],
                menus: [],
                empresas: [],
                negocios: []
            }
        }

        let idsSubMenu = []
        const it = items.filter(item => {
            if (item.new) {
                idsSubMenu.push(item.SubMenuId)
                return true
            }
        })

        let idsMenu = []
        const s = subMenus.filter(item => {
            if (item.new || idsSubMenu.includes(item.SubMenuId)) {
                idsMenu.push(item.MenuId)
                return true
            }
        })

        let idsEmpresas = []
        const m = menus.filter(item => {
            if (item.new || idsMenu.includes(item.MenuId)) {
                idsEmpresas.push(item.DmnAbr)
                return true
            }
        })

        let idsNegocios = []
        const e = empresas.filter(item => {
            if (idsEmpresas.includes(item.DmnAbr)) {
                idsNegocios.push(item.MenuNeg)
                return true
            }
        })

        const n = negocios.filter(item => {
            if (idsNegocios.includes(item.MenuNeg)) {
                return true
            }
        })

        return {
            items: it,
            subMenus: s,
            menus: m,
            empresas: e,
            negocios: n
        }
    }
    
    const resumenVacio = () =>{
        const d = generarDatosResumen()

        return  (
            d.items.length === 0 &&
            d.subMenus.length === 0 &&
            d.menus.length === 0 &&
            d.empresas.length === 0 &&
            d.negocios.length === 0
        )
    }

    const hideHijos = (padre, item, resumen = false) => {
        const p = {
            subMenus,
            menus,
            empresas,
            negocios,
            items
        }
        const s = {
            setSubMenus,
            setMenus,
            setEmpresas,
            setNegocios,
            setItems
        }

        let aux = p[padre].slice(0)
        const keys = Object.keys(item)
        let i

        aux.forEach((a, index) => {
            let iguales = true

            keys.forEach(k => {
                if (a[k] !== item[k]) iguales = false
            })

            if (iguales) i = index
        })

        resumen
            ? aux[i].checkRes = !aux[i].checkRes
            : aux[i].check = !aux[i].check
        
        const set = 'set' + padre[0].toUpperCase() + padre.slice(1)

        s[set](aux)
    }
    
    const newMenu = (e, padre, cond) => {
        cancelNuevo()

        const p = {
            empresas,
            menus
        }
        const s = {
            setEmpresas,
            setMenus
        }

        let a = p[padre].slice(0)
        a.forEach(item => item.addMenu = false)

        const i = a.findIndex(f => f[cond] === e[cond])
        a[i].addMenu = true

        s['set' + padre[0].toUpperCase() + padre.slice(1)](a)

        focusElement('.form-nuevo-menu')
    }

    const changeNuevo = e => {
        const value = e.target.value

        setNuevo(value)
    }

    const changeLink = e => {
        const value = e.target.value

        setLink(value)
    }

    const changeRutas = e => {
        const value = e.target.value

        setRutas(value)
    }
    
    const cancelNuevo = () => {
        setNuevo('')

        let e = empresas.slice(0)
        let m = menus.slice(0)

        e.forEach(item => item.addMenu = false)
        m.forEach(item => item.addMenu = false)

        setEmpresas(e)
        setMenus(m)
    }

    const editar = () => {
        if (!nuevo) {
            Notify({ type: 'completar' })
            return
        }

        if (editMenu === 'menu') {
            let a = menus.slice(0)
            const i = a.findIndex(item => item.MenuId === auxItem.MenuId)

            if (i >= 0) a[i].MenuNom = nuevo

            setMenus(a)
        }
        
        if (editMenu === 'subMenu') {
            let a = subMenus.slice(0)
            const i = a.findIndex(item => item.SubMenuId === auxItem.SubMenuId)

            if (i >= 0) a[i].SubMenuNom = nuevo

            setSubMenus(a)
        }

        setShowEdit(false)
        setNuevo('')
        setAuxItem(null)
        setEditMenu('')
    }

    const editarItem = () => {
        if (!nuevo || !link) {
            Notify({ type: 'completar' })
            return
        }

        let a = items.slice(0)
        const i = a.findIndex(item => item.IMenuId === auxItem.IMenuId)
        
        if (i >= 0) {
            a[i].IMenuNom = nuevo
            a[i].IMenuLinkTo = '/' + link
            a[i].Rutas = rutas
        }

        setItems(a)
        setNuevo('')
        setLink('')
        setRutas('')
        setAuxItem(null)
        setShow(false)
        setEditItem(false)
    }

    const showModalEdit = (e, item, m) => {
        e.stopPropagation()

        setEditMenu(m)

        setNuevo(item.MenuNom || item.SubMenuNom)
        setAuxItem(item)
        setShowEdit(true)

        focusElement('.form-nuevo-menu')
    }

    const showModalItem = (e, s) => {
        const item = {
            MenuId: s.MenuId,
            SubMenuId: s.SubMenuId,
            IMenuEmp: e.DmnAbr
        }

        cancelNuevo()

        setNewItem(item)
        setLink('')
        setShow(true)

        focusElement('.form-nuevo-menu')
    }

    const showModalEditItem = (e, item) => {
        e.stopPropagation()

        setEditItem(true)
        setAuxItem(item)
        setNuevo(item.IMenuNom)
        setLink(item.IMenuLinkTo.slice(1))
        setShow(true)

        focusElement('.form-nuevo-menu')
    }

    const addMenu = e => {
        if (!nuevo) {
            Notify('Debe ingresar un nombre para el menu')
            return
        }

        const menu = {
            MenuEmp: e.DmnAbr,
            MenuNeg: e.DmnAbr,
            DmnAbr: e.DmnAbr,
            MenuNom: nuevo,
            MenuId: 'new' + idMenu,
            new: true,
            check: true,
            checkRes: true
        }

        setIdMenu(i => i + 1)

        let a = menus.slice(0)
        a.unshift(menu)
        setMenus(a)

        let emp = empresas.slice(0)
        emp.forEach(item => item.addMenu = false)
        setEmpresas(emp)
    }

    const addSubMenu = m => {
        if (!nuevo) {
            Notify('Debe ingresar un nombre para el menu')
            return
        }

        const subMenu = {
            MenuId: m.MenuId,
            SubMenuNom: nuevo,
            SubMenuId: 'new' + idSubMenu,
            new: true,
            check: true,
            checkRes: true
        }

        setIdSubMenu(i => i + 1)

        let a = subMenus.slice(0)
        a.unshift(subMenu)
        setSubMenus(a)

        let ms = menus.slice(0)
        ms.forEach(item => item.addMenu = false)
        setMenus(ms)
    }

    const addItem = () => {
        if (!nuevo || !link) {
            Notify({ type: 'completar' })
            return
        }

        const it = {
            ...newItem,
            IMenuNom: nuevo,
            IMenuLinkTo: '/' + link,
            Rutas: rutas,
            new: true,
            IMenuId: 'new' + idItem
        }

        setIdItem(i => i + 1)

        let a = items.slice(0)
        a.unshift(it)
        setItems(a)

        let s = subMenus.slice(0)
        s.forEach(item => item.addMenu = false)
        setSubMenus(s)

        setShow(false)
        setLink('')
        setNuevo('')
        setRutas('')
    }

    const removeMenu = (e, m) => {
        e.stopPropagation()

        let ms = menus.slice(0)
        const i = ms.findIndex(item => item.MenuId === m.MenuId)

        if (i >= 0) ms.splice(i, 1)

        let s = subMenus.slice(0)
        for (let j = s.length - 1; j >= 0; j--) {
            if (s[j].MenuId === m.MenuId) s.splice(j, 1)
        }

        let it = items.slice(0)
        for (let k = s.length - 1; k >= 0; k--) {
            if (it[k].MenuId === m.MenuId) it.splice(k, 1)
        }

        setMenus(ms)
        setSubMenus(s)
        setItems(it)
    }

    const removeSubMenu = (e, m) => {
        e.stopPropagation()

        let s = subMenus.slice(0)
        const j = s.findIndex(item => item.SubMenuId === m.SubMenuId)

        if (j >= 0) s.splice(j, 1)

        let it = items.slice(0)
        for (let k = s.length - 1; k >= 0; k--) {
            if (it[k].MenuId === m.MenuId) it.splice(k, 1)
        }

        setSubMenus(s)
        setItems(it)
    }

    const removeItem = (e, m) => {
        e.stopPropagation()

        let it = items.slice(0)
        const k = it.findIndex(item => item.IMenuId === m.IMenuId)

        if (k >= 0) it.splice(k, 1)

        setItems(it)
    }

    const closeModalItem = () => {
        setShow(false)
        setEditItem(false)
    }

    const getMenuPadre = (padre, key, keyNom, id) => {
        if (!id) return ''

        const p = {
            subMenus,
            menus,
            empresas,
            negocios
        }

        const i = p[padre].findIndex(f => f[key] === id)

        if (i < 0) return ''

        return p[padre][i][keyNom]
    }

    const restablecerMenus = e => {
        e.stopPropagation()

        setItems(dataMenus.items)
        setSubMenus(dataMenus.subMenus)
        setMenus(dataMenus.menus)
        setEmpresas(dataMenus.empresas)
        setNegocios(dataMenus.negocios)
    }

    const crearMenus = () => {
        setLoadingCrearMenus(true)

        const data = {
            items: items.filter(item => item.new),
            subMenus: subMenus.filter(item => item.new),
            menus: menus.filter(item => item.new)
        }
        let vacio = false

        data.menus.forEach(m => {
            const i = data.subMenus.findIndex(s => s.MenuId === m.MenuId)

            if (i < 0) vacio = true
        })

        data.subMenus.forEach(s => {
            const i = data.items.findIndex(item => item.SubMenuId === s.SubMenuId)

            if (i < 0) vacio = true
        })

        if (vacio) {
            Notify('No es posible crear un menu vacio')
            setLoadingCrearMenus(false)
            return
        }

        request.Post(props.ipserv + 'postmenus/', data, r => {
            setItems([])
            setSubMenus([])
            setMenus([])
            setEmpresas([])
            setNegocios([])

            getDataMenus()

            setLoadingCrearMenus(false)

            if (!r.success) {
                Notify({ type: 'error' })
            } else {
                r.errores.length > 0
                    ? Notify('Algunos elementos no pudieron ser cargados correctamente')
                    : Notify({ type: 'exito' })
            }
        })
    }

    useEffect(() => {
        if (props.ipserv.length > 0) {
            getDataMenus()
        }
    }, [props.ipserv])

    return <>
        <div className='full-width p-3'>
            <Button
                disabled={resumenVacio() || loadingCrearMenus}
                variant="primary"
                onClick={crearMenus}
            >
                Grabar Menus
            </Button>

            <div className="text-muted mt-3">
                * Para crear un nuevo menu haga click en los botones "Agregar" en el mapa a la izquierda en el nivel que desee colocarlo, a la derecha vera un resumen. Cuando finalice haga click en "Crear Menus".
            </div>
        </div>

        <div className='d-flex full-width relative-position'>
            {loadingCrearMenus &&
                <div
                    className='full-width full-height absolute-top d-flex justify-content-center align-items-center'
                    style={{
                        pointerEvents: 'all',
                        opacity: 0.5,
                        background: 'white',
                        zIndex: 3000
                    }}
                >
                    <Spinner animation="border" />
                </div>
            }

            <div className='col-4'>
                <div className='text-center fw-bold'>
                    <h5>Menus</h5>
                </div>

                <div className='linea-horizontal' />

                <div
                    className='mt-2 relative-position'
                    style={{
                        overflow: 'overlay',
                        height: '70vh'
                    }}
                >
                    {!resumenVacio() &&
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>
                                    Restablecer
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon
                                icon={faRedoAlt}
                                size="2x"
                                style={{ zIndex: 500 }}
                                className='absolute-top-right mt-4 mr-5 opacity-5 text-info cursor-pointer'
                                onClick={restablecerMenus}
                            />
                        </OverlayTrigger>
                    }

                    {loadingMenus
                        ? <div className='full-width pt-5 d-flex justify-content-center'>
                            <Spinner animation="border" />
                        </div>
                        : <ul className="wtree menu-panel" style={{ marginLeft: 0 }}>
                            <div className='d-none absolute-top text-positive agregar-menu'>
                                <FontAwesomeIcon icon={faPlusCircle} /> Agregar Menus
                            </div>
                            {negocios.map(n =>
                                <li>
                                    <span
                                        className={'cursor-pointer ' + (!n.check && 'close-menu')}
                                        onClick={() => hideHijos('negocios', n)}
                                    >
                                        <FontAwesomeIcon icon={faSortDown} color="black" /> { n.DmnNom }
                                    </span>
                                    <ul>
                                        {n.check &&
                                            <div className='d-none absolute-top text-positive agregar-menu'>
                                                <FontAwesomeIcon icon={faPlusCircle} /> Agregar Menu
                                            </div>
                                        }
                                        {n.check && empresas
                                        .filter(e => n.MenuNeg === e.MenuNeg)
                                        .map(e =>
                                            <li>
                                                <span
                                                    className={'cursor-pointer ' + (!e.check && 'close-menu')}
                                                    onClick={() => hideHijos('empresas', e)}
                                                >
                                                    <FontAwesomeIcon icon={faSortDown} color="black" /> { e.DmnNom }
                                                </span>
                                                <ul>
                                                    {e.check &&
                                                        <div className='absolute-top text-positive agregar-menu'>
                                                            {e.addMenu
                                                                ? <div className='d-flex align-items-center'>
                                                                    <FontAwesomeIcon
                                                                        className='cursor-pointer'
                                                                        size="lg"
                                                                        icon={faCheck}
                                                                        color='green'
                                                                        onClick={() => addMenu(e)}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        className='mx-2 cursor-pointer'
                                                                        size="lg"
                                                                        icon={faTimes}
                                                                        color='red'
                                                                        onClick={cancelNuevo}
                                                                    />
                                                                    <Form.Control
                                                                        className='form-nuevo-menu'
                                                                        type="text"
                                                                        placeholder="Nuevo Menu"
                                                                        style={{
                                                                            width: 'auto',
                                                                            height: 'auto',
                                                                            padding: 0
                                                                        }}
                                                                        onChange={changeNuevo}
                                                                        onKeyUp={event => keyEnterConfirm(event, () => addMenu(e))}
                                                                    />
                                                                </div>
                                                                : <div
                                                                    className='cursor-pointer'
                                                                    onClick={() => newMenu(e, 'empresas', 'DmnAbr')}
                                                                >
                                                                    <FontAwesomeIcon icon={faPlusCircle} /> Agregar Menu
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {e.check && menus
                                                    .filter(m => m.DmnAbr === e.DmnAbr)
                                                    .map(m =>
                                                        <li>
                                                            <span
                                                                className={
                                                                    'cursor-pointer d-flex align-items-center ' +
                                                                    (!m.check && ' close-menu ') +
                                                                    (m.new && ' text-info ')
                                                                }
                                                                onClick={() => hideHijos('menus', m)}
                                                            >
                                                                <FontAwesomeIcon icon={faSortDown} color="black" /> { m.MenuNom }
                                                                { m.new &&
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            className='no-giro mx-2 text-positive'
                                                                            icon={faPen}
                                                                            size="sm"
                                                                            onClick={e => showModalEdit(e, m, 'menu')}
                                                                        />
                                                                        <FontAwesomeIcon
                                                                            className='no-giro'
                                                                            icon={faTimes}
                                                                            size="sm"
                                                                            color="red"
                                                                            onClick={e => removeMenu(e, m)}
                                                                        />
                                                                    </>
                                                                }
                                                            </span>
                                                            <ul>
                                                                {m.check &&
                                                                    <div
                                                                        className='absolute-top text-positive agregar-menu'
                                                                        style={
                                                                            subMenus.filter(s => s.MenuId === m.MenuId).length === 0
                                                                                ? {
                                                                                    top: 0
                                                                                }
                                                                                : {}
                                                                        }
                                                                    >
                                                                        {m.addMenu
                                                                            ? <div className='d-flex align-items-center'>
                                                                                <FontAwesomeIcon
                                                                                    className='cursor-pointer'
                                                                                    size="lg"
                                                                                    icon={faCheck}
                                                                                    color='green'
                                                                                    onClick={() => addSubMenu(m)}
                                                                                />
                                                                                <FontAwesomeIcon
                                                                                    className='mx-2 cursor-pointer'
                                                                                    size="lg"
                                                                                    icon={faTimes}
                                                                                    color='red'
                                                                                    onClick={cancelNuevo}
                                                                                />
                                                                                <Form.Control
                                                                                    className='form-nuevo-menu'
                                                                                    type="text"
                                                                                    placeholder="Nuevo Menu"
                                                                                    style={{
                                                                                        width: 'auto',
                                                                                        height: 'auto',
                                                                                        padding: 0
                                                                                    }}
                                                                                    onChange={changeNuevo}
                                                                                    onKeyUp={event => keyEnterConfirm(event, () => addSubMenu(m))}
                                                                                />
                                                                            </div>
                                                                            : <div
                                                                                className='cursor-pointer'
                                                                                onClick={() => newMenu(m, 'menus', 'MenuId')}
                                                                            >
                                                                                <FontAwesomeIcon icon={faPlusCircle} /> Agregar Menu
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {m.check && subMenus
                                                                .filter(s => s.MenuId === m.MenuId)
                                                                .map(s =>
                                                                    <li>
                                                                        <span
                                                                            className={
                                                                                'cursor-pointer d-flex align-items-center ' +
                                                                                (!s.check && ' close-menu ') +
                                                                                (s.new && ' text-info ')
                                                                            }
                                                                            onClick={() => hideHijos('subMenus', s)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faSortDown}  color="black" /> { s.SubMenuNom }
                                                                            { s.new &&
                                                                                <>
                                                                                    <FontAwesomeIcon
                                                                                        className='no-giro mx-2 text-positive'
                                                                                        icon={faPen}
                                                                                        size="sm"
                                                                                        onClick={e => showModalEdit(e, s, 'subMenu')}
                                                                                    />
                                                                                    <FontAwesomeIcon
                                                                                        className='no-giro'
                                                                                        icon={faTimes}
                                                                                        size="sm"
                                                                                        color="red"
                                                                                        onClick={e => removeSubMenu(e, s)}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        </span>
                                                                        <ul>
                                                                            {s.check &&
                                                                                <div
                                                                                    className='absolute-top text-primary agregar-menu cursor-pointer'
                                                                                    onClick={() => showModalItem(e, s)}
                                                                                    style={
                                                                                        items.filter(item => s.SubMenuId === item.SubMenuId).length === 0
                                                                                            ? {
                                                                                                top: 0
                                                                                            }
                                                                                            : {}
                                                                                    }
                                                                                >
                                                                                    <FontAwesomeIcon icon={faPlusCircle} /> Agregar Item
                                                                                </div>
                                                                            }
                                                                            {s.check && items
                                                                            .filter(item => s.SubMenuId === item.SubMenuId)
                                                                            .map(item =>
                                                                                <li>
                                                                                    <span
                                                                                        className={(item.new && 'text-info') + ' d-flex align-items-center'}
                                                                                        onClick={() => hideHijos('items', item)}
                                                                                    >
                                                                                        { item.new &&
                                                                                            <>
                                                                                                <FontAwesomeIcon
                                                                                                    className='cursor-pointer text-positive'
                                                                                                    icon={faPen}
                                                                                                    size="sm"
                                                                                                    onClick={e => showModalEditItem(e, item)}
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    className='cursor-pointer'
                                                                                                    icon={faTimes}
                                                                                                    size="sm"
                                                                                                    color="red"
                                                                                                    onClick={e => removeItem(e, item)}
                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                        { item.new ? 'New' : item.IMenuId } - { item.IMenuNom } {item.new ? `[${item.IMenuLinkTo}]` : ''}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                        </ul>
                    }
                </div>
            </div>

            <div className='col-8'>
                <div className='text-center fw-bold'>
                    <h5>Resumen</h5>
                </div>

                <div className='linea-horizontal' />

                <Tabs 
                    defaultActiveKey="mapa"
                    transition={false}
                    className="justify-content-center mt-2 fw-bold"
                >
                    <Tab
                        eventKey="mapa"
                        title="Mapa"
                        tabClassName="half-width text-center"
                        className='pt-4'
                        style={{
                            overflow: 'overlay',
                            height: '66vh'
                        }}
                    >
                        {resumenVacio()
                            ? <div className='full-width text-center fw-medium'>
                                <h6>Aun no agregó ningun menu o item nuevo</h6>
                            </div>
                            : <ul className="wtree mx-auto half-width">
                                {generarDatosResumen().negocios.map(n =>
                                    <li>
                                        <span
                                            className={'cursor-pointer ' + (!n.checkRes && 'close-menu')}
                                            onClick={() => hideHijos('negocios', n, true)}
                                        >
                                            <FontAwesomeIcon icon={faSortDown} color="black" /> { n.DmnNom }
                                        </span>
                                        <ul>
                                            {n.checkRes && generarDatosResumen().empresas
                                            .filter(e => n.MenuNeg === e.MenuNeg)
                                            .map(e =>
                                                <li>
                                                    <span
                                                        className={'cursor-pointer ' + (!e.checkRes && 'close-menu')}
                                                        onClick={() => hideHijos('empresas', e, true)}
                                                    >
                                                        <FontAwesomeIcon icon={faSortDown} color="black" /> { e.DmnNom }
                                                    </span>
                                                    <ul>
                                                        {e.checkRes && generarDatosResumen().menus
                                                        .filter(m => m.DmnAbr === e.DmnAbr)
                                                        .map(m =>
                                                            <li>
                                                                <span
                                                                    className={
                                                                        'cursor-pointer d-flex align-items-center ' +
                                                                        (!m.checkRes && ' close-menu ') +
                                                                        (m.new && ' text-info ')
                                                                    }
                                                                    onClick={() => hideHijos('menus', m, true)}
                                                                >
                                                                    <FontAwesomeIcon icon={faSortDown} color="black" /> { m.MenuNom }
                                                                </span>
                                                                <ul>
                                                                    {m.checkRes && generarDatosResumen().subMenus
                                                                    .filter(s => s.MenuId === m.MenuId)
                                                                    .map(s =>
                                                                        <li>
                                                                            <span
                                                                                className={
                                                                                    'cursor-pointer d-flex align-items-center ' +
                                                                                    (!s.checkRes && ' close-menu ') +
                                                                                    (s.new && ' text-info ')
                                                                                }
                                                                                onClick={() => hideHijos('subMenus', s, true)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faSortDown}  color="black" /> { s.SubMenuNom }
                                                                            </span>
                                                                            <ul>
                                                                                {s.checkRes && generarDatosResumen().items
                                                                                .filter(item => s.SubMenuId === item.SubMenuId)
                                                                                .map(item =>
                                                                                    <li>
                                                                                        <span
                                                                                            className={(item.new && 'text-info') + ' d-flex align-items-center'}
                                                                                            onClick={() => hideHijos('items', item, true)}
                                                                                        >
                                                                                            { item.new ? 'New' : item.IMenuId } - { item.IMenuNom } {item.new ? `[${item.IMenuLinkTo}]` : ''}
                                                                                        </span>
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                            </ul>
                        }
                    </Tab>

                    <Tab
                        eventKey="tablas"
                        title="Tablas"
                        tabClassName="half-width text-center"
                    >
                        {resumenVacio()
                            ? <div className='full-width pt-4 text-center fw-medium'>
                                <h6>Aun no agregó ningun menu o item nuevo</h6>
                            </div>
                            : <Row
                                className='pt-2'
                                style={{
                                    overflow: 'overlay',
                                    height: '66vh'
                                }}
                            >
                                <Col className='mx-1 d-flex flex-column'>
                                    <div className='full-width text-center bg-amber fw-bold border'>
                                        Negocios
                                    </div>

                                    {generarDatosResumen().negocios.map(n =>
                                        <div className='full-width border py-2 pl-1'>
                                            { n.DmnNom }
                                        </div>
                                    )}
                                </Col>
                                <Col className='mx-1 d-flex flex-column'>
                                    <div className='full-width text-center bg-amber fw-bold border'>
                                        Empresas
                                    </div>

                                    {generarDatosResumen().empresas.map(e =>
                                        <div className='full-width border py-2 pl-1'>
                                            { e.DmnNom }  ({getMenuPadre('negocios', 'MenuNeg', 'DmnNom', e.MenuNeg)})
                                        </div>
                                    )}
                                </Col>
                                <Col className='mx-1 d-flex flex-column'>
                                    <div className='full-width text-center bg-amber fw-bold border'>
                                        Menus
                                    </div>

                                    {generarDatosResumen().menus.map(m =>
                                        <div className={'full-width border py-2 pl-1 ' + (m.new && 'text-info')}>
                                            { m.MenuNom } ({getMenuPadre('empresas', 'DmnAbr', 'DmnNom', m.DmnAbr)})
                                        </div>
                                    )}
                                </Col>
                                <Col className='mx-1 d-flex flex-column'>
                                    <div className='full-width text-center bg-amber fw-bold border'>
                                        SubMenus
                                    </div>

                                    {generarDatosResumen().subMenus.map(s =>
                                        <div className={'full-width border py-2 pl-1 ' + (s.new && 'text-info')}>
                                            { s.SubMenuNom } ({getMenuPadre('menus', 'MenuId', 'MenuNom', s.MenuId)})
                                        </div>
                                    )}
                                </Col>
                                <Col className='mx-1 d-flex flex-column'>
                                    <div className='full-width text-center bg-amber fw-bold border'>
                                        Items
                                    </div>

                                    {generarDatosResumen().items.map(item =>
                                        <div className={'full-width border py-2 pl-1 ' + (item.new && 'text-info')}>
                                            { item.IMenuNom } [{ item.IMenuLinkTo }] ({getMenuPadre('subMenus', 'SubMenuId', 'SubMenuNom', item.SubMenuId)})
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        }
                    </Tab>
                </Tabs>
            </div>
        </div>
        
        <Modal show={show} onHide={closeModalItem} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'><h5>{editItem ? 'Editar Item' : 'Nuevo Item'}</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <InputGroup className="full-width">
                    <Form.Control
                        className='form-nuevo-menu'
                        type="text"
                        value={nuevo}
                        placeholder={editItem ? "Editar Item" : "Nuevo Item"}
                        onChange={changeNuevo}
                        onKeyUp={e => keyEnterConfirm(e, editItem ? editarItem : addItem)}
                    />
                </InputGroup>
                
                <InputGroup className="full-width mt-3">
                    <Form.Control
                        type="text"
                        value={link}
                        placeholder="Link"
                        onChange={changeLink}
                        onKeyUp={e => keyEnterConfirm(e, editItem ? editarItem : addItem)}
                    />
                </InputGroup>
                
                <InputGroup className="full-width mt-3 d-flex flex-column">
                    <Form.Control
                        className='full-width'
                        as="textarea"
                        value={rutas}
                        placeholder="Rutas"
                        onChange={changeRutas}
                        onKeyUp={e => keyEnterConfirm(e, editItem ? editarItem : addItem)}
                    />
                    <div className='text-caption ml-1'>Si la vista usa rutas escriba todas separadas por coma para asignarles los permisos correspondientes</div>
                </InputGroup>

                <div className='full-width d-flex justify-content-center mt-3'>
                    <Button
                        variant="info"
                        onClick={ editItem ? editarItem : addItem }
                    >
                        { editItem ? 'Editar' : 'Agregar' } Item
                    </Button>
                    <Button
                        variant="danger"
                        className="ml-3"
                        onClick={closeModalItem}
                    >
                        Cancelar
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
        
        <Modal show={showEdit} onHide={() => setShowEdit(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'><h5>Editar Nombre de Menu</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <InputGroup className="full-width">
                    <Form.Control
                        className='form-nuevo-menu'
                        type="text"
                        value={nuevo}
                        placeholder="Nombre Menu"
                        onChange={changeNuevo}
                        onKeyUp={e => keyEnterConfirm(e, editar)}
                    />
                </InputGroup>

                <div className='full-width d-flex justify-content-center mt-3'>
                    <Button
                        variant="info"
                        onClick={editar}
                    >
                        Aceptar
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    </>
}